import { Customer } from "../api/customer";
import { AppState } from "../components/contexts/app/reducer";
import { isApiKeyGenerated } from "./api-key";
import { isClientKeyGenerated } from "./client-key";

/**
 * Indicates whether a customer has an active medical subscription.
 * If the user is in sandbox mode, this will always return true.
 * If the user has an API key, this will always return true.
 * Otherwise, it will return true if the customer has a medical subscription.
 */
export function isMedicalSubscriptionActive(state: AppState, isSandbox?: boolean): boolean;
export function isMedicalSubscriptionActive(customer?: Customer, isSandbox?: boolean): boolean;
export function isMedicalSubscriptionActive(
  param?: Customer | AppState,
  isSandbox?: boolean
): boolean {
  if (!param) return false;
  if (isCustomerParam(param)) return isMedicalActiveFromCustomer(param, isSandbox);
  const state: AppState = param;
  if (isApiKeyGenerated(state.keyIds)) return true;
  return isMedicalActiveFromCustomer(state.customer, isSandbox);
}

function isCustomerParam(param: unknown): param is Customer {
  return (param as Customer).id !== undefined;
}

function isMedicalActiveFromCustomer(customer?: Customer, isSandbox = false): boolean {
  if (isSandbox) return true;
  if (!customer) return false;
  return Boolean(customer.medicalStripeSubscriptionItemId) && isSubscriptionActive(customer);
}

export function isSubscriptionActive(state: AppState): boolean;
export function isSubscriptionActive(customer?: Customer): boolean;
export function isSubscriptionActive(param?: Customer | AppState): boolean {
  if (!param) return false;
  if (isCustomerParam(param)) return isActiveFromCustomer(param);
  return isActiveFromCustomer(param.customer);
}

function isActiveFromCustomer(customer?: Customer): boolean {
  if (!customer) return false;
  return customer.subscriptionStatus === "active";
}

export function isDevicesSubscriptionActive(state: AppState): boolean {
  if (!state) return false;
  if (isClientKeyGenerated(state.keyIds)) return true;
  return isDevicesActiveFromCustomer(state.customer);
}

function isDevicesActiveFromCustomer(customer?: Customer): boolean {
  if (!customer) return false;
  return Boolean(customer.devicesStripeSubscriptionItemId);
}
