import {
  Box,
  Flex,
  IconButton,
  Button,
  Icon,
  Grid,
  Text,
  Skeleton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useColorModeValue,
  useBreakpointValue,
  Divider,
} from "@chakra-ui/react";
import { PatientDTO } from "@metriport/api-sdk";
import { useCopyToClipboard } from "usehooks-ts";
import { FiCopy } from "react-icons/fi";
import { capitalize } from "lodash";
import { EditIcon } from "@chakra-ui/icons";
import { BsThreeDotsVertical } from "react-icons/bs";
import useMetriportToast from "../../shared/toast";
import { METRIPORT_PRIMARY } from "../../../shared/style";
import { Button as MetriportButton } from "../../shared/Button";
import { isMrDownloading } from "../shared-logic/useGetMedicalRecord";
import { limitStringLength } from "../../../shared/util";

export function PatientsHeader({
  patient,
  isQueryingDocuments,
  isFetchingPatient,
  isDownloadingMr,
  selectedTab,
  lastDocQuery,
  limitedHeader = false,
  tabsInHeader = false,
  actions,
}: {
  patient: PatientDTO | undefined;
  isFetchingPatient: boolean;
  isQueryingDocuments: boolean;
  isDownloadingMr: isMrDownloading;
  selectedTab: "fhir" | "documents";
  lastDocQuery: string | undefined;
  limitedHeader?: boolean;
  tabsInHeader?: boolean;
  actions: {
    onQueryPatientDocuments: ({ override }: { override?: boolean }) => Promise<void>;
    onGenerateMedicalRecord: () => void;
    onEditPatient: () => void;
    onViewPatientMatches: () => void;
    onSetPatientTab: (tab: "fhir" | "documents") => void;
  };
}) {
  const bg = useColorModeValue("gray.50", "gray.800");

  const isDownloadingMR = Object.values(isDownloadingMr).some(({ downloading }) => downloading);

  const menuState = limitedHeader
    ? "full"
    : useBreakpointValue({
        base: "full",
        lg: "partial",
        "2xl": "basic",
      }) ?? "full";

  return (
    <Box
      bg={useColorModeValue("gray.100", "gray.900")}
      top={0}
      position={tabsInHeader ? "sticky" : "relative"}
      zIndex={tabsInHeader ? 2 : undefined}
    >
      <LastQueried lastDocQuery={lastDocQuery} />
      <Box
        bg={useColorModeValue("white", "gray.800")}
        border={"solid"}
        borderRadius={10}
        borderColor={useColorModeValue("gray.200", "gray.700")}
        px={7}
        py={5}
      >
        <Flex flexDirection={{ base: "column", xl: "row" }} justifyContent="space-between">
          <Flex justifyContent={"space-between"}>
            <PatientInfo
              patient={patient}
              isLoading={isFetchingPatient}
              actions={{ onEditPatient: actions.onEditPatient }}
            />
            {menuState === "full" && (
              <Menu placement="bottom-end">
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<BsThreeDotsVertical />}
                  variant="outline"
                />
                <MenuList bg={bg}>
                  <MenuItem onClick={actions.onViewPatientMatches} bg={bg}>
                    View Patient Matches
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
          </Flex>
          <Flex
            flexDirection={"column"}
            justifyContent={"space-between"}
            alignItems={{ base: "flex-start", xl: "flex-end" }}
          >
            <Flex>
              <MetriportButton
                mr={5}
                isLoading={isDownloadingMR}
                onClick={actions.onGenerateMedicalRecord}
              >
                Generate Medical Record
              </MetriportButton>
              <Button
                mr={5}
                onClick={() => actions.onQueryPatientDocuments({ override: true })}
                isLoading={isQueryingDocuments}
                variant={"outline"}
                borderColor={METRIPORT_PRIMARY}
                color={METRIPORT_PRIMARY}
              >
                Request Patient Data
              </Button>
              {(menuState === "basic" || menuState === "partial") && (
                <Menu placement="bottom-end">
                  <MenuButton
                    as={IconButton}
                    aria-label="Options"
                    icon={<BsThreeDotsVertical />}
                    variant="outline"
                  />
                  <MenuList bg={bg}>
                    <MenuItem onClick={actions.onViewPatientMatches} bg={bg}>
                      View Patient Matches
                    </MenuItem>
                  </MenuList>
                </Menu>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Divider borderColor={useColorModeValue("gray.300", "gray.600")} my={5} />
        <PatientDemo patient={patient} />
        <Divider borderColor={useColorModeValue("gray.300", "gray.600")} my={5} />
        <Flex justifyContent={"center"}>
          <Box>
            <MetriportButton
              mr={5}
              variant={selectedTab === "fhir" ? "solid" : "outline"}
              onClick={() => actions.onSetPatientTab("fhir")}
            >
              View Summary
            </MetriportButton>
            <MetriportButton
              variant={selectedTab === "documents" ? "solid" : "outline"}
              mr={5}
              onClick={() => actions.onSetPatientTab("documents")}
            >
              View Raw Documents
            </MetriportButton>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
}

function LastQueried({ lastDocQuery }: { lastDocQuery: string | undefined }) {
  return (
    <Box
      display={{ base: "none", "2xl": "block" }}
      position={"absolute"}
      top={0}
      marginLeft={"auto"}
      marginRight={"auto"}
      left={0}
      right={0}
      border={"solid"}
      borderTop={0}
      borderBottomRadius={10}
      borderColor={METRIPORT_PRIMARY}
      width={"fit-content"}
      paddingX={4}
      paddingY={2}
    >
      <Text color={METRIPORT_PRIMARY}>Last Queried: {lastDocQuery ?? "-"}</Text>
    </Box>
  );
}

function PatientInfo({
  patient,
  isLoading,
  actions,
}: {
  patient: PatientDTO | undefined;
  isLoading: boolean;
  actions: {
    onEditPatient: () => void;
  };
}) {
  const patientName = `${patient?.firstName} ${patient?.lastName}`;

  return (
    <Flex mb={{ base: 2, xl: 0 }} alignItems={"center"}>
      <Flex w={"100%"} flexDirection={"column"}>
        <Skeleton display={"flex"} flexDirection={"row"} alignItems="center" isLoaded={!isLoading}>
          <Text fontSize="2xl" fontWeight={"bold"}>
            {patientName}
          </Text>
          <IconButton
            onClick={actions.onEditPatient}
            bg={"transparent"}
            _hover={{ color: "#879ced" }}
            aria-label="Edit Patient"
            icon={<EditIcon />}
          />
        </Skeleton>
      </Flex>
    </Flex>
  );
}

function PatientDemo({ patient }: { patient: PatientDTO | undefined }) {
  const [copiedText, copy] = useCopyToClipboard();
  const toast = useMetriportToast();
  const address = Array.isArray(patient?.address) ? patient?.address[0] : patient?.address;
  const contact = Array.isArray(patient?.contact) ? patient?.contact[0] : patient?.contact;

  const demoFields: Array<{ label: string; value: string | undefined }> = [
    { label: "id", value: patient?.id },
    { label: "dob", value: patient?.dob },
    { label: "gender", value: patient?.genderAtBirth },
    { label: "city", value: address?.city },
    { label: "state", value: address?.state },
    { label: "email", value: contact?.email },
    { label: "phone", value: contact?.phone },
  ];

  return (
    <Grid
      templateColumns={{ base: "repeat(2, 1fr)", lg: "repeat(3, 1fr)", "2xl": "repeat(7, 1fr)" }}
    >
      {demoFields.map(({ label, value }, i) => (
        <>
          <Flex
            mb={{ base: 5, "2xl": 0 }}
            px={2}
            justifyContent={"center"}
            alignItems={"center"}
            borderRight={"solid"}
            borderColor={useColorModeValue("gray.300", "gray.600")}
            key={i}
          >
            <Box>
              <Text color={useColorModeValue("gray.500", "gray.400")}>{capitalize(label)}</Text>
              <Flex alignItems={"center"}>
                <Text>{label === "id" ? limitStringLength(value, 15) : value ? value : "-"}</Text>
                {label === "id" && (
                  <Icon
                    cursor={"pointer"}
                    ml={2}
                    onClick={() => {
                      copy(value ?? "");
                      toast.success({
                        title: "Copied Patient ID to clipboard",
                        description: copiedText,
                      });
                    }}
                    as={FiCopy}
                  />
                )}
              </Flex>
            </Box>
          </Flex>
        </>
      ))}
    </Grid>
  );
}
