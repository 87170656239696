import { OrgMemberInfoClass, UserClass } from "@propelauth/react";

export enum Roles {
  owner = "Owner",
  admin = "Admin",
  billing = "Billing",
  developer = "Developer",
  clinical = "Clinical",
}

export function getRole(userClass: UserClass): string {
  // Gotta make sure each user can have at most one org on PropelAuth
  const org: OrgMemberInfoClass | undefined = userClass.getOrgs()[0];
  if (!org) throw new Error(`Missing org for user ${userClass.username}`);
  const userInOrg = userClass.orgIdToUserOrgInfo
    ? userClass.orgIdToUserOrgInfo[org.orgId]
    : undefined;
  if (!userInOrg) throw new Error(`Missing org info for user ${userClass.username}`);
  return userInOrg.userAssignedRole;
}

export function isRoleIncluded(roles: string[], userRole: string | undefined): boolean {
  if (!roles || roles.length <= 0) return false;
  if (!userRole) return false;
  return roles.includes(userRole);
}

export function isAdmin(role: string | undefined): boolean {
  return Roles.admin === role;
}
export function isOwner(role: string | undefined): boolean {
  return Roles.owner === role;
}
export function isClinical(role: string | undefined): boolean {
  return Roles.clinical === role;
}
export function isDeveloper(role: string | undefined): boolean {
  return Roles.developer === role;
}

// Permissions
export function isManageOrgAllowed(role: string | undefined): boolean {
  return isAdmin(role) || isOwner(role) || isClinical(role) || isDeveloper(role);
}
