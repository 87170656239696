import {
  Box,
  Flex,
  InputGroup,
  Input,
  InputLeftElement,
  Heading,
  Divider,
  useDisclosure,
} from "@chakra-ui/react";
import { Facility } from "@metriport/api-sdk";
import { useEffect, useState } from "react";
import { SearchIcon } from "@chakra-ui/icons";
import { Actions, Analytics, Features } from "../../shared/analytics";
import { Button as MetriportButton } from "../../shared/Button";
import FacilityForm from "./form";
import { useAppContext } from "../../contexts/app";
import { useMetriportApi } from "../../shared/useMetriportApi";
import FacilityGrid from "./grid";
import { METRIPORT_PRIMARY } from "../../../shared/style";
import useMetriportToast from "../../shared/toast";
import { capture } from "../../../shared/capture";

export default function Facilities() {
  const { state } = useAppContext();
  const toast = useMetriportToast();
  const [facilities, setFacilities] = useState<Facility[] | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [search, setSearch] = useState("");
  const [facilityToEdit, setFacilityToEdit] = useState<Facility | undefined>(undefined);
  const [tableFromTop, setTableFromTopHeight] = useState(0);
  const metriportApi = useMetriportApi();

  useEffect(() => {
    async function getFacilities() {
      try {
        const facilities = await metriportApi.listFacilities();
        setFacilities(facilities);
      } catch (err) {
        const msg = "Error retrieving facilities";
        toast.error({ title: msg });
        capture.error(msg, {
          extra: { context: `patients.get`, err },
        });
      } finally {
        setIsLoading(false);
      }
    }
    getFacilities();

    const element = document.getElementById("table-container");

    if (element) {
      setTableFromTopHeight(element.getBoundingClientRect().top);
    }
  }, []);

  function onEditFacility(facility: Facility): void {
    Analytics.emit(Actions.open, Features.form, { type: Features.facility });
    setFacilityToEdit(facility);
    onOpen();
  }

  const closeForm = () => {
    Analytics.emit(Actions.close, Features.form, { type: Features.facility });
    setFacilityToEdit(undefined);
    onClose();
  };

  function onFacilityFormAction(facility: Facility) {
    if (facilityToEdit) {
      const updatedFacilities = facilities
        ? facilities?.map(f => (f.id === facility.id ? facility : f))
        : [];
      setFacilities(updatedFacilities);
    } else {
      setFacilities([facility, ...(facilities || [])]);
    }

    Analytics.emit(Actions.submit, Features.form, { type: Features.facility });
  }

  if (state.isLoaded) {
    return (
      <Box p={10}>
        <Box px={5}>
          <Flex alignItems={"center"} justifyContent={"space-between"}>
            <Heading size="xl">Facilities</Heading>
            <Box>
              <MetriportButton mr={4} onClick={onOpen}>
                Create Facility
              </MetriportButton>
            </Box>
          </Flex>
          <Divider my={6} />
          <Flex justifyContent={"space-between"} mb={5}>
            <InputGroup>
              <InputLeftElement pointerEvents="none" color="gray.300" fontSize="1.2em">
                <SearchIcon />
              </InputLeftElement>
              <Input
                minW={"300px"}
                w={"25%"}
                value={search}
                placeholder="Search for facilities"
                onChange={e => setSearch(e.target.value)}
                onClick={() => Analytics.emit(Actions.search, Features.facility)}
                _hover={{ borderColor: METRIPORT_PRIMARY }}
                _active={{ borderColor: METRIPORT_PRIMARY }}
                _focus={{ borderColor: METRIPORT_PRIMARY }}
              />
            </InputGroup>
          </Flex>
          <Box
            id="table-container"
            style={{ width: "100%", height: `calc(95vh - ${tableFromTop}px)` }}
          >
            <FacilityGrid
              facilities={facilities}
              search={search}
              onEditFacility={onEditFacility}
              isLoading={isLoading}
            />
          </Box>
        </Box>
        <FacilityForm
          onFacilityAction={onFacilityFormAction}
          selectedFacility={facilityToEdit}
          isOpen={isOpen}
          api={metriportApi}
          onClose={closeForm}
        />
      </Box>
    );
  }

  return null;
}
