import { ColDef } from "ag-grid-community";
import { RelatedPerson } from "@medplum/fhirtypes";
import { FhirGrid } from "../shared/grid";
import { MrFilterSetting } from "../../../../../api/settings";
import { compare } from "../shared/shared";

export function RelatedPersonsGrid({
  relatedPersons,
  onShowContent,
  filters,
}: {
  relatedPersons: RelatedPerson[];
  onShowContent: (id: string) => void;
  filters: MrFilterSetting;
}) {
  const rowData = relatedPersons
    ?.map(relatedPerson => ({
      id: relatedPerson.id ?? "-",
      name: getRelatedPersonsName(relatedPerson),
      relationships: getRelatedPersonsRelationships(relatedPerson),
      contacts: renderRelatedPersonContacts(relatedPerson),
      addresses: renderRelatedPersonAddresses(relatedPerson) ?? "-",
    }))
    .sort((a, b) => compare(a, b, filters?.stringFilter));

  const columnDefs: ColDef[] = [
    { field: "id", hide: true },
    { field: "name" },
    { field: "relationships" },
    { field: "contacts" },
    { field: "addresses" },
  ];

  const defaultColDef: ColDef = {
    flex: 1,
    onCellClicked: e => {
      onShowContent(e.data.id);
    },
    cellClassRules: {
      "cell-filter": params => {
        if (!params.value || !filters?.stringFilter) {
          return false;
        }

        const filtersArray = filters.stringFilter.split(",");

        const hasFilter = filtersArray.some(filter => {
          return params.value.toLowerCase().includes(filter.toLowerCase());
        });

        return hasFilter;
      },
    },
  };

  return (
    <FhirGrid
      sectionTitle="Related Persons"
      defaultColDef={defaultColDef}
      columnDefs={columnDefs}
      rowData={rowData}
    />
  );
}

function getRelatedPersonsName(relatedPerson: RelatedPerson): string {
  const names: string[] = [];

  if (relatedPerson.name) {
    for (const name of relatedPerson.name) {
      if (name.text) {
        names.push(name.text);
      } else {
        const parts: string[] = [];
        if (name.prefix) {
          parts.push(name.prefix.join(" "));
        }
        if (name.given) {
          parts.push(name.given.join(" "));
        }
        if (name.family) {
          parts.push(name.family);
        }
        names.push(parts.join(" "));
      }
    }
  }

  return names.join(", ");
}

function getRelatedPersonsRelationships(relatedPerson: RelatedPerson): string {
  const relationships: string[] = [];

  if (relatedPerson.relationship) {
    for (const relationship of relatedPerson.relationship) {
      if (relationship.coding) {
        for (const coding of relationship.coding) {
          if (coding.display) {
            relationships.push(coding.display);
          }
        }
      }
    }
  }

  return relationships.join(", ");
}

function renderRelatedPersonContacts(relatedPerson: RelatedPerson) {
  const contacts = relatedPerson.telecom?.map(telecom => {
    return `${telecom.system}${telecom.use ? `- ${telecom.use}` : ""}: ${telecom.value}`;
  });

  return contacts?.join(", ") ?? "-";
}

function renderRelatedPersonAddresses(relatedPerson: RelatedPerson) {
  const addresses = relatedPerson.address?.map(address => {
    return `${address.line?.join(", ")} ${address.city}, ${address.state} ${address.postalCode}`;
  });

  return addresses?.join(", ") ?? "-";
}
