import {
  Box,
  Button,
  ChakraProps,
  Heading,
  HStack,
  Skeleton,
  Text,
  useInterval,
  VStack,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { get } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { z } from "zod";
import { getFeedback, saveFeedback } from "../api/api-feedback";
import { FeedbackStatus } from "../domain/feedback";
import { capture } from "../shared/capture";
import Dialog from "./dialog";
import { defaultButtonSettings } from "./shared/Button";
import Form from "./shared/form/Form";
import { Input } from "./shared/form/Input";
import { TextArea } from "./shared/form/TextArea";
import useMetriportToast from "./shared/toast";

dayjs.extend(duration);

const maxChars = 5_000;
const poolingInterval = dayjs.duration(3, "seconds");
const textAreaId = "feedbackTextArea";

function goToHome() {
  window.location.href = "https://metriport.com";
}

const feedbackSubmissionSchema = z.object({
  name: z.string().nullish(),
  comment: z.string(),
});
type FeedbackSubmission = z.infer<typeof feedbackSubmissionSchema>;

const initialFeedbackSubmissionValues: Omit<FeedbackSubmission, "id"> = {
  comment: "",
};

const Feedback = (props: ChakraProps) => {
  const { id } = useParams();
  const toast = useMetriportToast();
  const [feedbackStatus, setFeedbackStatus] = useState<FeedbackStatus | undefined>();
  const [feedbackSubmission, setFeedbackSubmission] = useState<FeedbackSubmission | undefined>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [remaining, setRemaining] = useState<number>(maxChars);
  const [isConfirm, setIsConfirm] = useState<boolean>(false);

  useEffect(() => {
    async function fetchData() {
      if (!id) return goToHome();
      try {
        const feedback = await getFeedback(id);
        console.log(`feedback: `, feedback);
        if (!feedback) {
          setFeedbackStatus("inactive");
          return;
        }
        setFeedbackSubmission({
          ...initialFeedbackSubmissionValues,
        });
        setFeedbackStatus("active");
      } catch (error) {
        console.log(`Failed to get feedback:`, error);
        capture.error("Failed to get feedback status", {
          extra: { context: `feedback`, error },
        });
        const toastDuration = 3000;
        toast.error({
          title: "Failed to load feedback status. Please reload the page.",
          duration: toastDuration,
        });
        setFeedbackStatus("inactive");
      }
    }
    fetchData();
  }, [id]);

  useInterval(
    async () => goToHome(),
    feedbackStatus === "inactive" || isSubmitted ? poolingInterval.asMilliseconds() : null
  );

  const onSubmit = useCallback(async (data: FeedbackSubmission) => {
    setIsSubmitting(true);
    try {
      await saveFeedback(id!, data); // eslint-disable-line @typescript-eslint/no-non-null-assertion
      setIsSubmitted(true);
    } catch (error) {
      capture.error("Failed to submit feedback", {
        extra: { context: `mfeedback.submit`, error },
      });
      const toastDuration = 3000;
      toast.error({
        title: "Failed to submit feedback. Please try again later.",
        duration: toastDuration,
      });
    }
    setIsSubmitting(false);
  }, []);

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleInput(e: any) {
    const newLength = e.target.value.length;
    setRemaining(maxChars - newLength);
  }

  const redirectingMessage = useMemo(
    () => `Redirecting in ${poolingInterval.asSeconds()} seconds...`,
    []
  );

  if (!feedbackStatus) return <Skeleton height={600}>..</Skeleton>;

  if (isSubmitted) {
    return (
      <Box p={50} {...props}>
        <Heading>Thanks for your feedback!</Heading>
        {redirectingMessage}
      </Box>
    );
  }
  if (feedbackStatus === "inactive") {
    return (
      <Box p={50} {...props}>
        <Heading>Not found.</Heading>
        {redirectingMessage}
      </Box>
    );
  }
  return (
    <Box p={50} {...props}>
      <VStack align="stretch">
        <Heading>What are your thoughts about the AI-generated medical summary?</Heading>
        <Text>
          You can include PHI in the feedback. This page is covered under{" "}
          <a href={"https://www.metriport.com/privacy-policy"} target={"_blank"}>
            Metriport's Privacy Policy
          </a>
          .
        </Text>
        <Form
          defaultValues={feedbackSubmission}
          resolver={zodResolver(feedbackSubmissionSchema)}
          onSubmit={onSubmit}
        >
          {({ register, formState: { errors } }) => {
            return (
              <>
                <Input
                  {...register("name")}
                  placeholder="Your name (optional)"
                  error={get(errors, "name")}
                  maxLength={255}
                  mb={5}
                />
                <TextArea
                  {...register("comment")}
                  id={textAreaId}
                  isRequired
                  placeholder="Your feedback here..."
                  error={get(errors, "comment")}
                  maxLength={maxChars}
                  onInput={handleInput}
                  noOfLines={30}
                  minHeight="30vh"
                  overflow={"auto"}
                />
                <Text>{remaining} remaining characters.</Text>
                <HStack>
                  <Button onClick={() => setIsConfirm(true)}>Close this page</Button>
                  <Button
                    {...defaultButtonSettings}
                    form="hook-form"
                    ml={3}
                    isLoading={isSubmitting}
                    type="submit"
                  >
                    Submit
                  </Button>
                </HStack>
              </>
            );
          }}
        </Form>
      </VStack>
      {isConfirm && (
        <Dialog
          show
          title="Are you sure?"
          message={
            "By providing feedback about the AI-generated medical summary, you help us improve our services. " +
            "Are you sure you want to close this page?"
          }
          actionText="Yes, close"
          onAction={goToHome}
          onClose={() => setIsConfirm(false)}
        />
      )}
    </Box>
  );
};

export default Feedback;
