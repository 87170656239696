import { useEffect, useState } from "react";
import { ListDocumentResult } from "@metriport/api-sdk";
import dayjs from "dayjs";
import { ISO_DATE } from "@metriport/shared/common/date";
import { capture } from "../../../shared/capture";
import { useMetriportApi } from "../../shared/useMetriportApi";
import useMetriportToast from "../../shared/toast";
import { useInterval } from "../../../hooks/use-interval";
import { getDocumentQueryStatus } from "../../../api/medical-record";

export type OnQueryPatientDocuments = {
  dateFrom?: string;
  dateTo?: string;
  content?: string;
};

type UseQueryDocuments = {
  isQueryingDocuments: boolean;
  downloadProgress: { total: number; completed: number };
  lastDocQuery: string | undefined;
  documents: ListDocumentResult;
  onQueryPatientDocuments: ({ override }: { override?: boolean }) => Promise<void>;
  onSearchPatientDocuments: (filters: OnQueryPatientDocuments) => Promise<void>;
};

const POLLING_INTERVAL = dayjs.duration(3, "second").asMilliseconds();

export function useQueryDocuments({ patientId }: { patientId: string }): UseQueryDocuments {
  const metriportApi = useMetriportApi();
  const toast = useMetriportToast();

  const [isQueryingDocuments, setIsQueryingDocuments] = useState(false);
  const [isPolling, setIsPolling] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState({ total: 0, completed: 0 });
  const [lastDocQuery, setLastDocQuery] = useState<string | undefined>(undefined);
  const [documents, setDocuments] = useState<ListDocumentResult>({
    documents: [],
  });

  useEffect(() => {
    onQueryPatientDocuments({});
  }, []);

  async function onQueryPatientDocuments({ override }: { override?: boolean }) {
    try {
      setIsQueryingDocuments(true);
      const documentList = await metriportApi.listDocuments(patientId);

      if (documentList.documents.length === 0 || override) {
        await metriportApi.startDocumentQuery(patientId);
        setIsPolling(true);
      } else {
        const resp = await getDocumentQueryStatus(patientId);
        setLastDocQuery(dayjs(resp.data.startedAt).format(ISO_DATE));
        setIsQueryingDocuments(false);
        searchDocumentsByFilters({ documents: documentList });
      }
    } catch (error) {
      capture.error(error, {
        extra: { patient: patientId, context: `patient.file.list` },
      });
      toast.error();
    }
  }

  useInterval(
    async () => {
      const { download, convert } = await metriportApi.getDocumentQueryStatus(patientId);
      const successful = download?.successful ?? 0;
      const errors = download?.errors ?? 0;
      const isProcessing = download?.status === "processing";

      setIsPolling(isProcessing);
      setDownloadProgress({
        total: download?.total ?? 0,
        completed: successful + errors,
      });

      if (download?.status === "completed") {
        const documentList = await metriportApi.listDocuments(patientId);

        searchDocumentsByFilters({ documents: documentList });
        setIsQueryingDocuments(false);
      }

      if (convert?.status === "completed") {
        // TEMP: This is a temporary fix to get the query start date
        const resp = await getDocumentQueryStatus(patientId);
        setLastDocQuery(dayjs(resp.data.startedAt).format(ISO_DATE));
        setIsPolling(false);
      }
    },
    isPolling ? POLLING_INTERVAL : null
  );

  async function searchDocumentsByFilters({ documents }: { documents: ListDocumentResult }) {
    const filters = localStorage.getItem("doc_filters");
    if (filters) {
      const { dateFrom, dateTo, content } = JSON.parse(filters);
      onSearchPatientDocuments({ dateFrom, dateTo, content });
    }

    setDocuments(documents);
  }

  async function onSearchPatientDocuments({ dateFrom, dateTo, content }: OnQueryPatientDocuments) {
    localStorage.setItem("doc_filters", JSON.stringify({ dateFrom, dateTo, content }));
    setIsQueryingDocuments(true);

    try {
      const dateFromValue = getFilterValue(dateFrom);
      const dateToValue = getFilterValue(dateTo);
      const contentValue = getFilterValue(content);

      const documentList = await metriportApi.listDocuments(patientId, {
        dateFrom: dateFromValue,
        dateTo: dateToValue,
        content: contentValue,
      });

      setDocuments(documentList);
    } catch (error) {
      capture.error(error, {
        extra: { patient: patientId, context: `patient.documents.list` },
      });
      toast.error();
    } finally {
      setIsQueryingDocuments(false);
    }
  }

  return {
    isQueryingDocuments,
    downloadProgress,
    lastDocQuery,
    documents,
    onQueryPatientDocuments,
    onSearchPatientDocuments,
  };
}

function getFilterValue(filterInput: string | undefined): string | undefined {
  if (!filterInput) return;
  const trimmedValue = filterInput.trim();
  return trimmedValue && trimmedValue.length > 0 ? trimmedValue : undefined;
}
