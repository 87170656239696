import { ColDef } from "ag-grid-community";
import { FamilyMemberHistory } from "@medplum/fhirtypes";
import { getFirstCodeSpecified, ICD_10_CODE, SNOMED_CODE, getValidCode } from "../shared/shared";
import { FhirGrid } from "../shared/grid";
import { MrFilterSetting } from "../../../../../api/settings";
import { compare } from "../shared/shared";

export function FamilyMemberHistoryGrid({
  familyMemberHistories,
  onShowContent,
  filters,
}: {
  familyMemberHistories: FamilyMemberHistory[];
  onShowContent: (id: string) => void;
  filters: MrFilterSetting;
}) {
  const rowData = familyMemberHistories
    ?.map(familyMemberHistory => ({
      id: familyMemberHistory.id ?? "-",
      familyMember: getFamilyMemberDisplay(familyMemberHistory),
      sex: renderAdministrativeGender(familyMemberHistory),
      conditions: renderFamilyHistoryConditions(familyMemberHistory),
      deceased: renderFamilyMemberDecased(familyMemberHistory),
      code: getFamilyMemberCode(familyMemberHistory),
    }))
    .sort((a, b) => compare(a, b, filters?.stringFilter));

  const columnDefs: ColDef[] = [
    { field: "id", hide: true },
    { field: "familyMember" },
    { field: "sex" },
    { field: "conditions" },
    { field: "deceased" },
    { field: "code" },
  ];

  const defaultColDef: ColDef = {
    flex: 1,
    onCellClicked: e => {
      onShowContent(e.data.id);
    },
    cellClassRules: {
      "cell-filter": params => {
        if (!params.value || !filters?.stringFilter) {
          return false;
        }

        const filtersArray = filters.stringFilter.split(",");

        const hasFilter = filtersArray.some(filter => {
          return params.value.toLowerCase().includes(filter.toLowerCase());
        });

        return hasFilter;
      },
    },
  };

  return (
    <FhirGrid
      sectionTitle="FamilyMemberHistory"
      defaultColDef={defaultColDef}
      columnDefs={columnDefs}
      rowData={rowData}
    />
  );
}

function getFamilyMemberDisplay(familyMemberHistory: FamilyMemberHistory): string {
  const codings = getValidCode(familyMemberHistory.relationship?.coding);
  const displays = codings.map(coding => coding.display);

  if (displays.length) {
    return displays.join(", ");
  } else if (familyMemberHistory.relationship?.text) {
    return familyMemberHistory.relationship.text;
  }

  return "-";
}

function renderAdministrativeGender(familyMemberHistory: FamilyMemberHistory): string {
  const adminGenCode = getFirstCodeSpecified(familyMemberHistory.sex?.coding, [
    "administrativegender",
  ]);

  if (adminGenCode?.code) {
    return adminGenCode.code;
  }

  return "-";
}

function renderFamilyHistoryConditions(familyMemberHistory: FamilyMemberHistory): string {
  const conditions = familyMemberHistory.condition?.map(condition => {
    return condition.code?.text ?? getValidCode(condition.code?.coding)[0]?.display;
  });

  return conditions?.join(", ") ?? "-";
}

function renderFamilyMemberDecased(familyMemberHistory: FamilyMemberHistory) {
  const deceasedFamilyMember = familyMemberHistory.condition?.find(condition => {
    return condition.contributedToDeath === true;
  });

  if (deceasedFamilyMember) {
    return "Yes";
  }

  return "No";
}

function getFamilyMemberCode(familyMemberHistory: FamilyMemberHistory): string {
  const code = getFirstCodeSpecified(familyMemberHistory.condition?.[0]?.code?.coding ?? [], [
    ICD_10_CODE,
    SNOMED_CODE,
  ]);

  return code ? `${code.system}: ${code.code}` : "-";
}
