import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  Box,
  Stack,
  Radio,
  RadioGroup,
  Flex,
} from "@chakra-ui/react";
import { useState } from "react";
import { MedicalRecordsStatusDTO } from "@metriport/api-sdk";
import { Button } from "../../shared/Button";
import { METRIPORT_PRIMARY } from "../../../shared/style";
import { DateRange } from "../../shared/form/DateRange";
import { MRFormats } from "../shared-logic/useGetMedicalRecord";

export type isDownloading = Record<
  string,
  {
    type?: string;
    downloading: boolean;
  }
>;

export function MrModal({
  isOpen,
  isDownloading,
  existingMrStatus,
  actions,
}: {
  isOpen: boolean;
  isDownloading: isDownloading;
  existingMrStatus: MedicalRecordsStatusDTO | undefined;
  actions: {
    onDownloadMR: (
      downloadExistingMr: boolean,
      conversionType: MRFormats,
      dateTo: string,
      dateFrom: string
    ) => Promise<void>;
    onCloseModal: () => void;
  };
}) {
  const [newDocument, setNewDocument] = useState<string>("new");
  const mrDateString = existingMrStatus?.htmlCreatedAt ?? existingMrStatus?.pdfCreatedAt;
  const previousMrDate = mrDateString ? new Date(mrDateString).toLocaleString("en-US") : undefined;

  return (
    <Modal isOpen={isOpen} onClose={actions.onCloseModal} size={"3xl"}>
      <ModalOverlay />
      <ModalContent px={6} py={4}>
        <ModalCloseButton />
        <Box>
          <Text mb={1} fontSize={"xl"} fontWeight="bold">
            Medical Record Summary
          </Text>
          <Text color={"gray.400"} mb={3}>
            {generateOutput(previousMrDate)}
          </Text>

          <Stack spacing={4}>
            <RadioGroup onChange={setNewDocument} value={newDocument}>
              <Stack direction="column">
                <Radio borderColor={METRIPORT_PRIMARY} value="new">
                  Generate New
                </Radio>
                {(existingMrStatus?.htmlCreatedAt || existingMrStatus?.pdfCreatedAt) && (
                  <Radio borderColor={METRIPORT_PRIMARY} value="existing">
                    Get Summary from {previousMrDate}
                  </Radio>
                )}
              </Stack>
            </RadioGroup>
            <DownloadActions
              existingMrStatus={existingMrStatus}
              isDownloading={isDownloading}
              downloadExistingMr={newDocument === "new"}
              actions={actions}
            />
          </Stack>
        </Box>
      </ModalContent>
    </Modal>
  );
}

function generateOutput(previousMrDate: string | undefined) {
  return `Generate a new Medical Record Summary${
    previousMrDate ? ", or instantly download an existing one." : ""
  }`;
}

function DownloadActions({
  existingMrStatus,
  isDownloading,
  downloadExistingMr,
  actions,
}: {
  existingMrStatus: MedicalRecordsStatusDTO | undefined;
  isDownloading: isDownloading;
  downloadExistingMr: boolean;
  actions: {
    onDownloadMR: (
      downloadExistingMr: boolean,
      conversionType: MRFormats,
      dateTo: string,
      dateFrom: string
    ) => Promise<void>;
    onCloseModal: () => void;
  };
}) {
  const [dateFrom, setDateFrom] = useState<string>("");
  const [dateTo, setDateTo] = useState<string>("");

  const pdfDisabled = !downloadExistingMr && !existingMrStatus?.pdfCreatedAt;
  const htmlDisabled = !downloadExistingMr && !existingMrStatus?.htmlCreatedAt;

  return (
    <Flex alignItems={"end"}>
      {downloadExistingMr && (
        <DateRange
          id={"mr-date-range"}
          label="Filter by date"
          dateFrom={dateFrom}
          dateTo={dateTo}
          onSetToDate={(to: string) => setDateTo(to)}
          onSetFromDate={(from: string) => setDateFrom(from)}
        />
      )}
      <Box pl={3}>
        <Button
          isLoading={isDownloading.pdf?.downloading}
          disabled={pdfDisabled}
          onClick={() => {
            actions.onCloseModal();
            actions.onDownloadMR(downloadExistingMr, "pdf", dateTo, dateFrom);
          }}
          mr={3}
        >
          PDF
        </Button>
        <Button
          isLoading={isDownloading.html?.downloading}
          disabled={htmlDisabled}
          onClick={() => {
            actions.onCloseModal();
            actions.onDownloadMR(downloadExistingMr, "html", dateTo, dateFrom);
          }}
          mr={3}
        >
          HTML
        </Button>
        {/* TODO: DOESNT WORK HERE BUT ALSO IN PROD */}
        {downloadExistingMr && (
          <Button
            style={{ backgroundColor: "transparent", borderColor: "#748df0", color: "#748df0" }}
            variant="outline"
            onClick={() => {
              setDateFrom("");
              setDateTo("");
            }}
          >
            Clear
          </Button>
        )}
      </Box>
    </Flex>
  );
}
