import { ChakraProps, HStack, Text, ThemingProps } from "@chakra-ui/react";
import { useAppContext } from "../contexts/app";
import SandboxToggle from "../settings/sandbox";

export default function SandboxNavbar({ ...rest }: ChakraProps & ThemingProps<"Text">) {
  const { state } = useAppContext();
  const reloadData = () => {
    state.isLoaded = false;
  };
  return (
    <HStack>
      <Text mb={0} {...rest}>
        Sandbox
      </Text>
      <SandboxToggle minimized onToggle={reloadData} />
    </HStack>
  );
}
