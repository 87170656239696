import axios from "axios";

export function getOSSBaseURL(isSandbox?: boolean): string {
  return isSandbox
    ? process.env.REACT_APP_OSS_SANDBOX_API_URL! // eslint-disable-line @typescript-eslint/no-non-null-assertion
    : process.env.REACT_APP_OSS_API_URL!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
}

export const apiOSS = axios.create({ baseURL: getOSSBaseURL() });

export function updateBaseURL(isSandbox: boolean) {
  apiOSS.defaults.baseURL = getOSSBaseURL(isSandbox);
}

export default apiOSS;
