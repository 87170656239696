import { ChakraProvider, theme } from "@chakra-ui/react";
import { useLogoutFunction, withAuthInfo } from "@propelauth/react";
import * as Sentry from "@sentry/react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Billing from "./components/billing";
import { AppStateProvider } from "./components/contexts/app";
import Feedback from "./components/feedback";
import Home from "./components/home/home";
import Medical from "./components/medical";
import Facilities from "./components/medical/facilities";
import PatientsOverview from "./components/medical/patient-overview";
import Navbar from "./components/nav/navbar";
import Settings from "./components/settings/settings";
import SubcriptionSuccess from "./components/settings/subscription-success";
import ProtectedRoute from "./components/shared/ProtectedRoute";
import UserAccount from "./components/users/user-account";
import UserManagement from "./components/users/user-management";
import "./index.css";
import { NotFound } from "./NotFound";
import { Roles } from "./shared/roles";

// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/#usage-with-react-router-64-data-api
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const adminAndOwner = [Roles.owner, Roles.admin];

export const App = withAuthInfo(authInfo => {
  const logoutFn = useLogoutFunction();
  return (
    <main>
      <BrowserRouter>
        <ChakraProvider theme={theme}>
          <AppStateProvider authInfo={authInfo}>
            <Navbar signOut={() => logoutFn(false)}>
              <SentryRoutes>
                <Route path="/" element={<Home></Home>} />
                <Route
                  element={
                    <ProtectedRoute roles={[...adminAndOwner, Roles.developer, Roles.clinical]} />
                  }
                >
                  <Route
                    element={
                      <ProtectedRoute roles={[...adminAndOwner, Roles.developer, Roles.clinical]} />
                    }
                  >
                    <Route path="/patients" element={<Medical></Medical>} />
                    <Route path="/facilities" element={<Facilities></Facilities>} />
                    <Route
                      path="/patient/:patientId"
                      element={<PatientsOverview></PatientsOverview>}
                    />
                  </Route>
                </Route>
                <Route element={<ProtectedRoute roles={[...adminAndOwner, Roles.billing]} />}>
                  <Route path="billing" element={<Billing></Billing>} />
                </Route>
                <Route element={<ProtectedRoute roles={[...adminAndOwner, Roles.developer]} />}>
                  <Route path="developers" element={<Settings></Settings>} />
                </Route>
                <Route element={<ProtectedRoute roles={[...adminAndOwner]} />}>
                  <Route path="users" element={<UserManagement></UserManagement>} />
                </Route>
                <Route path="account" element={<UserAccount></UserAccount>} />
                <Route
                  path="subscription-successful"
                  element={<SubcriptionSuccess></SubcriptionSuccess>}
                />
                <Route path="/feedback/:id" element={<Feedback />} />
                <Route path="/feedback" element={<Feedback />} />
                <Route path="*" element={<NotFound />} />
              </SentryRoutes>
            </Navbar>
          </AppStateProvider>
        </ChakraProvider>
      </BrowserRouter>
    </main>
  );
});
