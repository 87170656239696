import {
  Bundle,
  Resource,
  Encounter as FHIREncounter,
  Practitioner,
  Location,
  Condition,
  DiagnosticReport,
} from "@medplum/fhirtypes";
import { orderBy } from "lodash";
import { ReportContent } from "./report-content";
import { ReportsGrid } from "./grid";
import { MrFilterSetting } from "../../../../../api/settings";
import { FhirSection } from "../../../shared-components/fhir-section";

export type Report = {
  encounter: FHIREncounter;
  participants: Practitioner[];
  locations: Location[];
  diagnoses: Condition[];
  diagnosticReports: DiagnosticReport[];
};

export function Reports({
  bundle,
  onShowContent,
  filters,
}: {
  bundle: Bundle<Resource> | undefined;
  onShowContent: (content: React.ReactNode) => void;
  filters: MrFilterSetting;
}) {
  const reports = getEncounterNotesFromBundle(bundle);
  const sortedEncounterNotes = orderBy(reports, e => e.encounter.period?.start, "desc");

  return (
    <FhirSection
      sectionId="reports"
      sectionName="Reports"
      hasContent={sortedEncounterNotes.length === 0}
    >
      <ReportsGrid
        filters={filters}
        reports={sortedEncounterNotes}
        onShowReport={report => {
          onShowContent(<ReportContent encounterNote={report} />);
        }}
      />
    </FhirSection>
  );
}

export function getSpecialtyFromLocation(locations: Location[]): string[] {
  return locations.map(
    location => location.type?.[0]?.text || location.type?.[0]?.coding?.[0]?.display || ""
  );
}

export function getCleanNoteFromReport(report: DiagnosticReport): string {
  const note = report.presentedForm?.[0]?.data || "";
  const noJunkNote = removeEncodedStrings(note);
  const decodeNote = atob(noJunkNote);
  return cleanUpNote(decodeNote);
}

const REMOVE_FROM_NOTE = [
  "xLabel",
  "5/5",
  "Â°F",
  "â¢",
  "documented in this encounter",
  "xnoIndent",
  "Formatting of this note might be different from the original.",
  "<content>",
  "</content>",
  "<root>",
  "</root>",
];

function cleanUpNote(note: string): string {
  return note
    .trim()
    .replace(new RegExp(REMOVE_FROM_NOTE.join("|"), "g"), "")
    .replace(/<ID>.*?<\/ID>/g, "")
    .replace(/<styleCode>.*?<\/styleCode>/g, "");
}

function removeEncodedStrings(valueString: string): string {
  return valueString.replace(/&#x3D;/g, "").trim();
}

export function getEncounterNotesFromBundle(bundle: Bundle | undefined): Report[] {
  const encounters = bundle?.entry?.filter(e => e.resource?.resourceType === "Encounter");
  const encounterResources = encounters?.map(e => e.resource as FHIREncounter) || [];

  const reports: Report[] = [];

  if (!bundle) {
    return reports;
  }

  for (const encounter of encounterResources) {
    const participants: Practitioner[] = [];
    const locations: Location[] = [];
    const diagnoses: Condition[] = [];
    const diagnosticReports: DiagnosticReport[] = [];

    const entries = bundle.entry || [];

    const participantIds = encounter.participant?.map(p =>
      p.individual?.reference?.split("/").pop()
    );
    const locationIds = encounter.location?.map(l => l.location?.reference?.split("/").pop());
    const diagnosisIds = encounter.diagnosis?.map(d => d.condition?.reference?.split("/").pop());

    for (const entry of entries) {
      if (
        entry.resource?.resourceType === "Practitioner" &&
        participantIds?.includes(entry.resource?.id)
      ) {
        participants.push(entry.resource as Practitioner);
      }

      if (
        entry.resource?.resourceType === "Location" &&
        locationIds?.includes(entry.resource?.id)
      ) {
        locations.push(entry.resource as Location);
      }

      if (
        entry.resource?.resourceType === "Condition" &&
        diagnosisIds?.includes(entry.resource?.id)
      ) {
        diagnoses.push(entry.resource as Condition);
      }

      if (entry.resource?.resourceType === "DiagnosticReport") {
        const diagnosticReport = entry.resource as DiagnosticReport;
        if (diagnosticReport.encounter?.reference?.split("/").pop() === encounter.id) {
          diagnosticReports.push(diagnosticReport);
        }
      }
    }

    reports.push({
      encounter,
      participants,
      locations,
      diagnoses,
      diagnosticReports,
    });
  }

  return reports;
}
