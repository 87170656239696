import { ISO_DATE } from "@metriport/shared/common/date";
import { ColDef } from "ag-grid-community";
import dayjs from "dayjs";
import { Condition } from "@medplum/fhirtypes";
import { getFirstCodeSpecified, ICD_10_CODE, SNOMED_CODE } from "../shared/shared";
import { FhirGrid } from "../shared/grid";
import { MrFilterSetting } from "../../../../../api/settings";
import { compare, filterByDate } from "../shared/shared";

export function ConditionGrid({
  conditions,
  onShowContent,
  filters,
}: {
  conditions: Condition[];
  onShowContent: (id: string) => void;
  filters: MrFilterSetting;
}) {
  const rowData = conditions
    ?.map(condition => ({
      id: condition.id ?? "-",
      condition: getConditionName(condition),
      code: getConditionCode(condition),
      onsetTime: getOnsetTime(condition),
    }))
    .filter(row => filterByDate(row.onsetTime, filters?.dateFilter))
    .sort((a, b) => compare(a, b, filters?.stringFilter));

  const columnDefs: ColDef[] = [
    { field: "id", hide: true },
    { field: "condition" },
    { field: "code" },
    { field: "onsetTime", sort: filters?.stringFilter ? undefined : "desc" },
  ];

  const defaultColDef: ColDef = {
    flex: 1,
    onCellClicked: e => {
      onShowContent(e.data.id);
    },
    cellClassRules: {
      "cell-filter": params => {
        if (!params.value || !filters?.stringFilter) {
          return false;
        }

        const filtersArray = filters.stringFilter.split(",");

        const hasFilter = filtersArray.some(filter => {
          return params.value.toLowerCase().includes(filter.toLowerCase());
        });

        return hasFilter;
      },
    },
  };

  return (
    <FhirGrid
      sectionTitle="Conditions"
      defaultColDef={defaultColDef}
      columnDefs={columnDefs}
      rowData={rowData}
    />
  );
}

function getConditionName(condition: Condition): string {
  const coding = getFirstCodeSpecified(condition.code?.coding ?? [], [ICD_10_CODE]);

  if (coding) {
    return coding.display;
  } else if (condition.code?.text) {
    return condition.code.text;
  }

  return "-";
}

function getConditionCode(condition: Condition): string {
  const coding = getFirstCodeSpecified(condition.code?.coding ?? [], [ICD_10_CODE, SNOMED_CODE]);

  return coding ? `${coding.system}: ${coding.code}` : "-";
}

function getOnsetTime(condition: Condition): string {
  const onsetDateTime = condition.onsetDateTime;
  const onsetPeriodStart = condition.onsetPeriod?.start;
  const onsetPeriodEnd = condition.onsetPeriod?.end;

  const time = onsetDateTime || onsetPeriodStart || onsetPeriodEnd;

  if (time) {
    return dayjs(time).format(ISO_DATE);
  }

  return "-";
}
