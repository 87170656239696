import { Box, Heading, Flex } from "@chakra-ui/react";
import { Bundle, Resource, Observation } from "@medplum/fhirtypes";
import dayjs from "dayjs";
import { ObservationsGrid } from "./grid";
import { useMetriportApi } from "../../../../shared/useMetriportApi";
import { Button } from "../../../../shared/Button";
import { downloadSourceDocument } from "../shared/shared";
import useMetriportToast from "../../../../shared/toast";
import { MrFilterSetting } from "../../../../../api/settings";
import { FhirSection } from "../../../shared-components/fhir-section";
import { JsonTable } from "../../../shared-components/json-table";

const EXPLAIN_TOAST_DURATION = dayjs.duration(5, "second").asMilliseconds();

export function Observations({
  bundle,
  onShowContent,
  filters,
}: {
  bundle: Bundle<Resource> | undefined;
  onShowContent: (content: React.ReactNode) => void;
  filters: MrFilterSetting;
}) {
  const observations = getObservations(bundle);

  return (
    <FhirSection
      sectionId="observations"
      sectionName="Observations"
      hasContent={observations.length === 0}
    >
      <ObservationsGrid
        filters={filters}
        observations={observations}
        onShowContent={(id: string) => {
          const observation = observations.find(c => c.id === id);
          onShowContent(<ObservationContent observation={observation} />);
        }}
      />
    </FhirSection>
  );
}

function ObservationContent({ observation }: { observation: Observation | undefined }) {
  const metriportApi = useMetriportApi();
  const toast = useMetriportToast();

  if (!observation) {
    return null;
  }

  const valueString = observation.extension?.find(ext => ext.valueString)?.valueString ?? "";

  return (
    <Box>
      <Flex justifyContent={"space-between"}>
        <Heading mb="3">Observation</Heading>
        <Button
          onClick={() => {
            downloadSourceDocument(valueString, metriportApi);
            toast.info({
              title: "Hold tight... This might take up to 30 seconds.",
              duration: EXPLAIN_TOAST_DURATION,
            });
          }}
        >
          View Source
        </Button>
      </Flex>

      <JsonTable resource={observation} />
    </Box>
  );
}

function getObservations(bundle: Bundle<Resource> | undefined): Observation[] {
  if (!bundle || !bundle.entry) {
    return [];
  }

  const observations: Observation[] = [];

  for (const entry of bundle.entry) {
    if (entry.resource?.resourceType === "Observation") {
      const isObservations = entry.resource?.category?.find(
        ext =>
          ext.coding?.[0]?.code?.toLowerCase() !== "vital-signs" &&
          ext.coding?.[0]?.code?.toLowerCase() !== "laboratory" &&
          ext.coding?.[0]?.code?.toLowerCase() !== "social-history"
      );

      if (isObservations) {
        observations.push(entry.resource);
      }
    }
  }

  return observations;
}
