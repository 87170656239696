import { Box, Heading, Flex } from "@chakra-ui/react";
import { Bundle, Resource, Observation } from "@medplum/fhirtypes";
import dayjs from "dayjs";
import { LabsGrid } from "./grid";
import { useMetriportApi } from "../../../../shared/useMetriportApi";
import { Button } from "../../../../shared/Button";
import { downloadSourceDocument } from "../shared/shared";
import useMetriportToast from "../../../../shared/toast";
import { MrFilterSetting } from "../../../../../api/settings";
import { FhirSection } from "../../../shared-components/fhir-section";
import { JsonTable } from "../../../shared-components/json-table";

const EXPLAIN_TOAST_DURATION = dayjs.duration(5, "second").asMilliseconds();

export function Labs({
  bundle,
  onShowContent,
  filters,
}: {
  bundle: Bundle<Resource> | undefined;
  onShowContent: (content: React.ReactNode) => void;
  filters: MrFilterSetting;
}) {
  const labs = getLabs(bundle);

  return (
    <FhirSection sectionId="labs" sectionName="Labs" hasContent={labs.length === 0}>
      <LabsGrid
        filters={filters}
        labs={labs}
        onShowContent={(id: string) => {
          const lab = labs.find(c => c.id === id);
          onShowContent(<LabsContent lab={lab} />);
        }}
      />
    </FhirSection>
  );
}

function LabsContent({ lab }: { lab: Observation | undefined }) {
  const metriportApi = useMetriportApi();
  const toast = useMetriportToast();

  if (!lab) {
    return null;
  }

  const valueString = lab.extension?.find(ext => ext.valueString)?.valueString ?? "";

  return (
    <Box>
      <Flex justifyContent={"space-between"}>
        <Heading mb="3">Lab</Heading>
        <Button
          onClick={() => {
            downloadSourceDocument(valueString, metriportApi);
            toast.info({
              title: "Hold tight... This might take up to 30 seconds.",
              duration: EXPLAIN_TOAST_DURATION,
            });
          }}
        >
          View Source
        </Button>
      </Flex>

      <JsonTable resource={lab} />
    </Box>
  );
}

function getLabs(bundle: Bundle<Resource> | undefined): Observation[] {
  if (!bundle || !bundle.entry) {
    return [];
  }

  const labs: Observation[] = [];

  for (const entry of bundle.entry) {
    if (entry.resource?.resourceType === "Observation") {
      const isVitals = entry.resource?.category?.find(
        ext => ext.coding?.[0]?.code?.toLowerCase() === "laboratory"
      );

      if (isVitals) {
        labs.push(entry.resource);
      }
    }
  }

  return labs;
}
