import { useEffect, useState } from "react";
import { capture } from "../../../shared/capture";
import useMetriportToast from "../../shared/toast";
import { getPatientFacilityMatches, PatientFacilityMatches } from "../../../api/patient-matches";

type UseGetPatientMatches = {
  patientMatches: PatientFacilityMatches | null;
};

export function useGetPatientMatches({ patientId }: { patientId: string }): UseGetPatientMatches {
  const toast = useMetriportToast();

  const [patientMatches, setPatientMatches] = useState<PatientFacilityMatches | null>(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const matches = await getPatientFacilityMatches(patientId);
        setPatientMatches(matches);
      } catch (error) {
        const msg = "Error retrieving patient matches";
        toast.error({ title: msg });
        capture.message(msg, {
          extra: { context: `patient.matches`, error, patientId: patientId },
          level: "error",
        });
      }
    }

    fetchData();
  }, []);

  return { patientMatches };
}
