import { Box, Card, CardBody, Heading, Stack, Text } from "@chakra-ui/react";

const NotAdmin = () => {
  return (
    <Box p={10}>
      <Card h={"100%"} boxShadow={"md"} borderRadius={20} bgColor={`orange.200`} maxW={"100%"}>
        <CardBody p={8}>
          <Stack spacing="4">
            <Heading color={`gray.900`} size="md">
              No Access
            </Heading>
            <Text color={`gray.900`} fontSize={18}>
              Only the account's administrator has access to this page.
            </Text>
          </Stack>
        </CardBody>
      </Card>
    </Box>
  );
};

export default NotAdmin;
