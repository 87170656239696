import { Outlet } from "react-router-dom";
import { isRoleIncluded } from "../../shared/roles";
import { useAppContext } from "../contexts/app";
import NotAdmin from "../not-admin";

const ProtectedRoute = ({ roles }: { roles: string[] }) => {
  const { state } = useAppContext();

  if (state.isLoaded && state.userRole) {
    if (!isRoleIncluded(roles, state.userRole)) return <NotAdmin />;
    return <Outlet />;
  }
  return null;
};

export default ProtectedRoute;
