import { Box, Heading, Flex } from "@chakra-ui/react";
import { Bundle, Resource, Procedure } from "@medplum/fhirtypes";
import dayjs from "dayjs";
import { ProceduresGrid } from "./grid";
import { useMetriportApi } from "../../../../shared/useMetriportApi";
import { Button } from "../../../../shared/Button";
import { downloadSourceDocument } from "../shared/shared";
import useMetriportToast from "../../../../shared/toast";
import { MrFilterSetting } from "../../../../../api/settings";
import { FhirSection } from "../../../shared-components/fhir-section";
import { JsonTable } from "../../../shared-components/json-table";

const EXPLAIN_TOAST_DURATION = dayjs.duration(5, "second").asMilliseconds();

export function Procedures({
  bundle,
  onShowContent,
  filters,
}: {
  bundle: Bundle<Resource> | undefined;
  onShowContent: (content: React.ReactNode) => void;
  filters: MrFilterSetting;
}) {
  const procedures = getProcedures(bundle);

  return (
    <FhirSection
      sectionId="procedures"
      sectionName="Procedures"
      hasContent={procedures.length === 0}
    >
      <ProceduresGrid
        filters={filters}
        procedures={procedures}
        onShowContent={(id: string) => {
          const procedure = procedures.find(c => c.id === id);
          onShowContent(<ProceduresContent procedure={procedure} />);
        }}
      />
    </FhirSection>
  );
}

function ProceduresContent({ procedure }: { procedure: Procedure | undefined }) {
  const metriportApi = useMetriportApi();
  const toast = useMetriportToast();

  if (!procedure) {
    return null;
  }

  const valueString = procedure.extension?.find(ext => ext.valueString)?.valueString ?? "";

  return (
    <Box>
      <Flex justifyContent={"space-between"}>
        <Heading mb="3">Procedure</Heading>
        <Button
          onClick={() => {
            downloadSourceDocument(valueString, metriportApi);
            toast.info({
              title: "Hold tight... This might take up to 30 seconds.",
              duration: EXPLAIN_TOAST_DURATION,
            });
          }}
        >
          View Source
        </Button>
      </Flex>

      <JsonTable resource={procedure} />
    </Box>
  );
}

function getProcedures(bundle: Bundle<Resource> | undefined): Procedure[] {
  if (!bundle || !bundle.entry) {
    return [];
  }

  const procedures: Procedure[] = [];

  for (const entry of bundle.entry) {
    if (entry.resource?.resourceType === "Procedure") {
      procedures.push(entry.resource as Procedure);
    }
  }

  return procedures;
}
