import { ISO_DATE } from "@metriport/shared/common/date";
import { ColDef } from "ag-grid-community";
import dayjs from "dayjs";
import { DiagnosticReport } from "@medplum/fhirtypes";
import { getFirstCodeSpecified, LOINC_CODE } from "../shared/shared";
import { FhirGrid } from "../shared/grid";
import { Report } from ".";
import { MrFilterSetting } from "../../../../../api/settings";
import { compare, filterByDate } from "../shared/shared";

export function ReportsGrid({
  reports,
  onShowReport,
  filters,
}: {
  reports: Report[];
  onShowReport: (report: Report) => void;
  filters: MrFilterSetting;
}) {
  const rowData = reports
    ?.filter(report => report.diagnosticReports.length !== 0)
    .map(report => {
      const encounter = report.encounter;

      const reasonForVisit = report.encounter.reasonCode?.map(code => code.text).join(", ") || "-";
      const typeOfReport = getReportTypeByLoinc(report.diagnosticReports).join(", ");

      return {
        report,
        typeOfReport,
        reasonForVisit,
        location: report.locations.map(location => location.name).join(", "),
        date: dayjs(encounter.period?.start).format(ISO_DATE),
      };
    })
    .filter(row => filterByDate(row.date, filters?.dateFilter))
    .sort((a, b) => {
      if (typeof a === "string" && typeof b === "string") {
        return compare(a, b, filters?.stringFilter);
      }

      return 0;
    });

  const columnDefs: ColDef[] = [
    { field: "report", hide: true },
    { field: "typeOfReport" },
    { field: "reasonForVisit" },
    { field: "location" },
    { field: "date", sort: filters?.stringFilter ? undefined : "desc" },
  ];

  const defaultColDef: ColDef = {
    flex: 1,
    onCellClicked: e => {
      onShowReport(e.data.report);
    },
    cellClassRules: {
      "cell-filter": params => {
        if (!params.value || !filters?.stringFilter) {
          return false;
        }

        const filtersArray = filters.stringFilter.split(",");

        const hasFilter = filtersArray.some(filter => {
          return params.value.toLowerCase().includes(filter.toLowerCase());
        });

        return hasFilter;
      },
    },
  };

  return (
    <FhirGrid
      sectionTitle="Reports"
      defaultColDef={defaultColDef}
      columnDefs={columnDefs}
      rowData={rowData}
      getRowClass={params => {
        const encounterId = params.data.report.encounter.id;
        return encounterId;
      }}
    />
  );
}

export function getReportTypeByLoinc(diagnosticReports: DiagnosticReport[]): string[] {
  const reportsTypes = diagnosticReports.map(report => {
    const code = getFirstCodeSpecified(report.code?.coding, [LOINC_CODE]);

    return code?.display || "";
  });

  return reportsTypes;
}
