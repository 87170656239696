import { useParams } from "react-router-dom";
import PatientsOverview from "./overview";
import { useAppContext } from "../../contexts/app";
import { isApiKeyGenerated } from "../../../domain/api-key";
import NoApiKey from "../no-api-key";

export default function PatientsPage() {
  const { patientId } = useParams();
  const { state } = useAppContext();

  if (!patientId || !state.isLoaded) {
    return null;
  }

  const apiKeyGenerated = isApiKeyGenerated(state.keyIds);

  if (!apiKeyGenerated) {
    return <NoApiKey />;
  }

  return <PatientsOverview patientId={patientId} />;
}
