import { ISO_DATE } from "@metriport/shared/common/date";
import { ColDef } from "ag-grid-community";
import dayjs from "dayjs";
import { AllergyIntolerance } from "@medplum/fhirtypes";
import { getFirstCodeSpecified, RX_NORM_CODE, SNOMED_CODE, ICD_10_CODE } from "../shared/shared";
import { FhirGrid } from "../shared/grid";
import { MrFilterSetting } from "../../../../../api/settings";
import { compare, filterByDate } from "../shared/shared";

export function AllergiesGrid({
  allergies,
  onShowContent,
  filters,
}: {
  allergies: AllergyIntolerance[];
  onShowContent: (id: string) => void;
  filters: MrFilterSetting;
}) {
  const rowData = allergies
    ?.map(allergy => ({
      id: allergy.id ?? "-",
      allergy: getAllergyName(allergy),
      manifestation: getAllergyManifestation(allergy),
      code: getAllergyCode(allergy),
      firstSeen: getAllergyOnset(allergy),
      status: getAllergyClinicalStatus(allergy),
    }))
    .filter(row => filterByDate(row.firstSeen, filters?.dateFilter))
    .sort((a, b) => compare(a, b, filters?.stringFilter));

  const columnDefs: ColDef[] = [
    { field: "id", hide: true },
    { field: "allergy" },
    { field: "manifestation" },
    { field: "code" },
    { field: "firstSeen", sort: filters?.stringFilter ? undefined : "desc" },
    { field: "status" },
  ];

  const defaultColDef: ColDef = {
    flex: 1,
    onCellClicked: e => {
      onShowContent(e.data.id);
    },
    cellClassRules: {
      "cell-filter": params => {
        if (!params.value || !filters?.stringFilter) {
          return false;
        }

        const filtersArray = filters.stringFilter.split(",");

        const hasFilter = filtersArray.some(filter => {
          return params.value.toLowerCase().includes(filter.toLowerCase());
        });

        return hasFilter;
      },
    },
  };

  return (
    <FhirGrid
      sectionTitle="Allergies"
      defaultColDef={defaultColDef}
      columnDefs={columnDefs}
      rowData={rowData}
    />
  );
}

function getAllergyName(allergy: AllergyIntolerance): string {
  const names: string[] = [];

  for (const reaction of allergy.reaction ?? []) {
    if (reaction.substance?.text) {
      names.push(reaction.substance.text);
    }
  }

  if (names && names.length > 0) {
    return names.join(", ");
  }

  return "-";
}

function getAllergyManifestation(allergy: AllergyIntolerance): string {
  const manifestations: string[] = [];

  for (const reaction of allergy.reaction ?? []) {
    for (const manifestation of reaction.manifestation ?? []) {
      if (manifestation.text) {
        manifestations.push(manifestation.text);
      }
    }
  }

  if (manifestations && manifestations.length > 0) {
    return manifestations.join(", ");
  }

  return "-";
}

function getAllergyCode(allergy: AllergyIntolerance): string {
  const coding = getFirstCodeSpecified(allergy.code?.coding ?? [], [
    SNOMED_CODE,
    ICD_10_CODE,
    RX_NORM_CODE,
  ]);

  if (coding) {
    return coding.display;
  } else if (allergy.code?.text) {
    return allergy.code.text;
  }

  return "-";
}

function getAllergyOnset(allergy: AllergyIntolerance): string {
  if (allergy.onsetDateTime) {
    return dayjs(allergy.onsetDateTime).format(ISO_DATE);
  } else if (allergy.recordedDate) {
    return dayjs(allergy.recordedDate).format(ISO_DATE);
  }

  return "-";
}

function getAllergyClinicalStatus(allergy: AllergyIntolerance): string {
  return allergy.clinicalStatus?.coding?.[0]?.code ?? "-";
}
