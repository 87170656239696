import { Box, Card, CardBody, Heading, Link, Stack, Text } from "@chakra-ui/react";

const NoApiKey = () => {
  return (
    <Box p={10}>
      <Card h={"100%"} boxShadow={"md"} borderRadius={20} bgColor={"red.200"} maxW={"100%"}>
        <CardBody p={8}>
          <Stack spacing="4">
            <Heading color={"red.700"} size="md">
              No API Key
            </Heading>
            <Text color={"red.700"} fontSize={18}>
              To get started with the Medical API, first go to the{" "}
              <Link href="/developers" textDecoration={"underline"}>
                Developers
              </Link>{" "}
              page to generate an API key.
            </Text>
          </Stack>
        </CardBody>
      </Card>
    </Box>
  );
};

export default NoApiKey;
