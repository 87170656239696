import { ISO_DATE } from "@metriport/shared/common/date";
import { ColDef } from "ag-grid-community";
import dayjs from "dayjs";
import { getFirstCodeSpecified, RX_NORM_CODE, NDC_CODE } from "../shared/shared";
import { FhirGrid } from "../shared/grid";
import { MedicationWithRefs } from ".";
import { MrFilterSetting } from "../../../../../api/settings";
import { compare, filterByDate } from "../shared/shared";

export function MedicationGrid({
  medicationsWithRefs,
  onShowContent,
  filters,
}: {
  medicationsWithRefs: MedicationWithRefs[];
  onShowContent: (id: string) => void;
  filters: MrFilterSetting;
}) {
  const rowData = medicationsWithRefs
    ?.map(medicationWithRefs => ({
      id: medicationWithRefs.medication.id ?? "-",
      medication: medicationWithRefs.medication.code?.text ?? "-",
      dose: getMedicationDose(medicationWithRefs),
      route: medicationWithRefs.administration?.dosage?.route?.text ?? "-",
      status: medicationWithRefs.medication.status ?? "-",
      code: getMedicationCode(medicationWithRefs),
      date: getStartDate(medicationWithRefs),
    }))
    .filter(row => filterByDate(row.date, filters?.dateFilter))
    .sort((a, b) => compare(a, b, filters?.stringFilter));

  const columnDefs: ColDef[] = [
    { field: "id", hide: true },
    { field: "medication" },
    { field: "dose" },
    { field: "route" },
    { field: "status" },
    { field: "code" },
    { field: "date", sort: filters?.stringFilter ? undefined : "desc" },
  ];

  const defaultColDef: ColDef = {
    flex: 1,
    onCellClicked: e => {
      onShowContent(e.data.id);
    },
    cellClassRules: {
      "cell-filter": params => {
        if (!params.value || !filters?.stringFilter) {
          return false;
        }

        const filtersArray = filters.stringFilter.split(",");

        const hasFilter = filtersArray.some(filter => {
          return params.value.toLowerCase().includes(filter.toLowerCase());
        });

        return hasFilter;
      },
    },
  };

  return (
    <FhirGrid
      sectionTitle="Conditions"
      defaultColDef={defaultColDef}
      columnDefs={columnDefs}
      rowData={rowData}
    />
  );
}

function getMedicationDose(medicationWithRefs: MedicationWithRefs): string {
  const adminDosage = medicationWithRefs.administration?.dosage;
  const hasValidDosage = adminDosage?.dose?.value && adminDosage?.dose?.unit;
  const adminInstructions = hasValidDosage
    ? `${adminDosage?.dose?.value} ${adminDosage?.dose?.unit}`
    : undefined;

  return adminInstructions ?? "-";
}

function getMedicationCode(medicationWithRefs: MedicationWithRefs): string {
  const coding = getFirstCodeSpecified(medicationWithRefs.medication?.code?.coding ?? [], [
    RX_NORM_CODE,
    NDC_CODE,
  ]);

  if (coding) {
    return `${coding.system}: ${coding.code}`;
  }

  return "-";
}

function getStartDate(medicationWithRefs: MedicationWithRefs): string {
  const onsetDateTime = medicationWithRefs.administration?.effectiveDateTime;
  const onsetPeriodStart = medicationWithRefs.administration?.effectivePeriod?.start;
  const onsetPeriodEnd = medicationWithRefs.administration?.effectivePeriod?.end;

  const time = onsetDateTime || onsetPeriodStart || onsetPeriodEnd;

  if (time) {
    return dayjs(time).format(ISO_DATE);
  }

  return "-";
}
