import { ISO_DATE } from "@metriport/shared/common/date";
import { ColDef } from "ag-grid-community";
import dayjs from "dayjs";
import { Encounter, Location } from "@medplum/fhirtypes";
import { getValidCode } from "../shared/shared";
import { FhirGrid } from "../shared/grid";
import { MrFilterSetting } from "../../../../../api/settings";
import { compare, filterByDate } from "../shared/shared";

export function EncountersGrid({
  encounters,
  locations,
  onShowContent,
  filters,
}: {
  encounters: Encounter[];
  locations: Location[];
  onShowContent: (id: string) => void;
  filters: MrFilterSetting;
}) {
  const rowData = encounters
    ?.map(encounter => ({
      id: encounter.id ?? "-",
      encounter: getEncountersDisplay(encounter),
      location: getEncounterLocation(encounter, locations),
      class: renderClassDisplay(encounter),
      startDate: encounter.period?.start ? dayjs(encounter.period.start).format(ISO_DATE) : "-",
      endDate: encounter.period?.end ? dayjs(encounter.period.end).format(ISO_DATE) : "-",
    }))
    .filter(row => filterByDate(row.startDate, filters?.dateFilter))
    .sort((a, b) => compare(a, b, filters?.stringFilter));

  const columnDefs: ColDef[] = [
    { field: "id", hide: true },
    { field: "encounter" },
    { field: "location" },
    { field: "class" },
    { field: "startDate", sort: filters?.stringFilter ? undefined : "desc" },
    { field: "endDate" },
  ];

  const defaultColDef: ColDef = {
    flex: 1,
    onCellClicked: e => {
      onShowContent(e.data.id);
    },
    cellClassRules: {
      "cell-filter": params => {
        if (!params.value || !filters?.stringFilter) {
          return false;
        }

        const filtersArray = filters.stringFilter.split(",");

        const hasFilter = filtersArray.some(filter => {
          return params.value.toLowerCase().includes(filter.toLowerCase());
        });

        return hasFilter;
      },
    },
  };

  return (
    <FhirGrid
      sectionTitle="Encounters"
      defaultColDef={defaultColDef}
      columnDefs={columnDefs}
      rowData={rowData}
    />
  );
}

function getEncountersDisplay(encounter: Encounter): string {
  const reasons: string[] = [];

  for (const reason of encounter.reasonCode ?? []) {
    const text = reason.text;

    if (text) {
      reasons.push(text);
    }
  }

  if (reasons.length) {
    return reasons.join(", ");
  } else {
    const codings = getValidCode(encounter.reasonCode?.[0]?.coding);

    if (codings.length && codings[0]?.display) {
      return codings[0].display;
    }
  }

  return "-";
}

function getEncounterLocation(encounter: Encounter, locations: Location[]): string {
  const locationId = encounter.location?.[0]?.location?.reference?.split("/")?.[1];
  const location = locations.find(l => l.id === locationId);
  return location?.name ?? "-";
}

function renderClassDisplay(encounter: Encounter): string {
  const isDisplayIsNotValid =
    encounter.class?.display === undefined || encounter.class?.display === "unknown";

  if (encounter.class?.display && !isDisplayIsNotValid) {
    return encounter.class?.display;
  } else if (encounter.class?.code && !isDisplayIsNotValid) {
    const extension = encounter.class?.extension?.find(coding => {
      return coding.valueCoding?.code === encounter.class?.code;
    });

    return extension?.valueCoding?.display ?? "-";
  } else {
    return "-";
  }
}
