import { CreateFeedbackEntry } from "@metriport/shared";
import axios from "axios";
import { Feedback } from "../domain/feedback";

const apiKey = process.env.REACT_APP_FEEDBACK_API_KEY;

const ossFeedbackBasePath = process.env.REACT_APP_FEEDBACK_API_URL!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
const feedbackPath = "/feedback";
const feedbackEntryPath = feedbackPath + "/entry";
const authHeaders = apiKey ? { headers: { "x-api-key": apiKey } } : undefined;

const apiFeedback = axios.create({ baseURL: ossFeedbackBasePath });

export async function getFeedback(id: string): Promise<Feedback | undefined> {
  if (!authHeaders) throw new Error("Feedback API key is not set");
  const { status, data: feedback } = await apiFeedback.get(`${feedbackPath}/${id}`, {
    ...authHeaders,
    timeout: 5_000,
    validateStatus: null, // dont't throw on error
  });
  if (!feedback || status === 404) {
    return undefined;
  }
  if (status > 299) {
    throw new Error(`Failed to get feedback: ${status}`);
  }
  return feedback;
}

export async function saveFeedback(
  feedbackId: string,
  data: { comment: string; name?: string | null }
): Promise<void> {
  if (!authHeaders) throw new Error("Feedback API key is not set");
  const payload: CreateFeedbackEntry = {
    feedbackId,
    ...data,
  };
  await apiFeedback.post(feedbackEntryPath, payload, { ...authHeaders });
}

export default apiFeedback;
