import { Box, Heading, Flex } from "@chakra-ui/react";
import dayjs from "dayjs";
import { Bundle, Condition, Resource } from "@medplum/fhirtypes";
import { ConditionGrid } from "./grid";
import { useMetriportApi } from "../../../../shared/useMetriportApi";
import { Button } from "../../../../shared/Button";
import { downloadSourceDocument } from "../shared/shared";
import useMetriportToast from "../../../../shared/toast";
import { MrFilterSetting } from "../../../../../api/settings";
import { FhirSection } from "../../../shared-components/fhir-section";
import { JsonTable } from "../../../shared-components/json-table";

const EXPLAIN_TOAST_DURATION = dayjs.duration(5, "second").asMilliseconds();

export function Conditions({
  bundle,
  onShowContent,
  filters,
}: {
  bundle: Bundle<Resource> | undefined;
  onShowContent: (content: React.ReactNode) => void;
  filters: MrFilterSetting;
}) {
  const conditions = getConditionsFromBundle(bundle);
  return (
    <FhirSection
      sectionId="conditions"
      sectionName="Conditions"
      hasContent={conditions.length === 0}
    >
      <ConditionGrid
        filters={filters}
        conditions={conditions}
        onShowContent={(id: string) => {
          const condition = conditions.find(c => c.id === id);

          onShowContent(<ConditionContent condition={condition} />);
        }}
      />
    </FhirSection>
  );
}

function ConditionContent({ condition }: { condition: Condition | undefined }) {
  const metriportApi = useMetriportApi();
  const toast = useMetriportToast();

  if (!condition) {
    return null;
  }

  const valueString = condition.extension?.find(ext => ext.valueString)?.valueString ?? "";

  return (
    <Box>
      <Flex justifyContent={"space-between"}>
        <Heading mb="3">Condition</Heading>
        <Button
          onClick={() => {
            downloadSourceDocument(valueString, metriportApi);
            toast.info({
              title: "Hold tight... This might take up to 30 seconds.",
              duration: EXPLAIN_TOAST_DURATION,
            });
          }}
        >
          View Source
        </Button>
      </Flex>

      <JsonTable resource={condition} />
    </Box>
  );
}

export function getConditionsFromBundle(bundle: Bundle | undefined): Condition[] {
  if (!bundle || !bundle.entry) {
    return [];
  }

  return (
    bundle.entry
      ?.filter(entry => entry.resource?.resourceType === "Condition")
      .map(entry => entry.resource as Condition) ?? []
  );
}
