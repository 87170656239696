import { Box, VStack } from "@chakra-ui/react";
import Chart from "react-apexcharts";

export function VitalsChart({
  vitalsId,
  vitals,
  dates,
}: {
  vitalsId: string;
  vitals: number[];
  dates: string[];
}) {
  return (
    <Box w={"100%"}>
      <VStack align="start">
        <Chart
          options={{
            chart: {
              id: `${vitalsId}-chart`,
            },
            xaxis: {
              categories: dates,
            },
            yaxis: {
              min: Math.min(...vitals) - Math.floor(Math.min(...vitals) / 2),
            },
            dataLabels: {
              enabled: false,
            },
            tooltip: {
              enabled: true,
            },
          }}
          series={[
            {
              name: "series-1",
              data: vitals,
            },
          ]}
          type="line"
          width={500}
          height={320}
          toolbar={{
            show: true,
          }}
        />
      </VStack>
    </Box>
  );
}
