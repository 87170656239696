import { Box, Card, CardBody, Heading, Link, Stack, Text } from "@chakra-ui/react";

const NoMapiAccess = () => {
  return (
    <Box p={10}>
      <Card h={"100%"} boxShadow={"md"} borderRadius={20} maxW={"100%"}>
        <CardBody p={8}>
          <Stack spacing="4">
            <Heading size="md">Request Access</Heading>
            <Text fontSize={18}>
              To access the Medical API, you must first go to the{" "}
              <Link href="/billing" textDecoration={"underline"}>
                Products & Billing
              </Link>{" "}
              page and select 'Get Started' on the Medical API.
            </Text>
          </Stack>
        </CardBody>
      </Card>
    </Box>
  );
};

export default NoMapiAccess;
