import { Box, useDisclosure, Flex, Heading, Skeleton } from "@chakra-ui/react";
import { DocumentReference as FHIRDocumentReference } from "@medplum/fhirtypes";
import { SearchModal } from "../patient-overview/fhir-sections/documents/search-modal";
import { Button } from "../../shared/Button";
import { OnQueryPatientDocuments } from "../shared-logic/useQueryDocuments";
import { DocumentGrid } from "../patient-overview/fhir-sections/documents/grid";

export function RawDocuments({
  documents,
  isSearchingDocuments,
  isQueryingDocuments,
  isDownloading,
  actions,
}: {
  documents: FHIRDocumentReference[];
  isSearchingDocuments: boolean;
  isQueryingDocuments: boolean;
  isDownloading: Record<
    string,
    {
      type?: string;
      downloading: boolean;
    }
  >;
  actions: {
    onSearchDocuments: (filters: OnQueryPatientDocuments) => Promise<void>;
    onDownloadFile: (
      docId: string,
      docFilename: string,
      conversionType?: "html" | "pdf"
    ) => Promise<void>;
  };
}) {
  const searchDocumentsDisclosure = useDisclosure();

  return (
    <>
      <Box pt={5}>
        <Flex mb={4} flexDirection={"row"} justifyContent={"space-between"}>
          <Heading>Raw Documents</Heading>
          <Button onClick={searchDocumentsDisclosure.onOpen}>Search Documents</Button>
        </Flex>
        <Box position={"relative"}>
          <Skeleton height={"full"} isLoaded={!isQueryingDocuments}>
            <DocumentGrid
              documentReference={documents}
              onDownloadDocument={actions.onDownloadFile}
              isDownloading={isDownloading}
            />
          </Skeleton>
        </Box>
      </Box>
      <SearchModal
        isOpen={searchDocumentsDisclosure.isOpen}
        onClose={searchDocumentsDisclosure.onClose}
        onSearchDocuments={actions.onSearchDocuments}
        isFetchingDocuments={isSearchingDocuments}
      />
    </>
  );
}
