import { Box, Heading, Flex } from "@chakra-ui/react";
import { Bundle, Resource, Task } from "@medplum/fhirtypes";
import dayjs from "dayjs";
import { TasksGrid } from "./grid";
import { useMetriportApi } from "../../../../shared/useMetriportApi";
import { Button } from "../../../../shared/Button";
import { downloadSourceDocument } from "../shared/shared";
import useMetriportToast from "../../../../shared/toast";
import { MrFilterSetting } from "../../../../../api/settings";
import { FhirSection } from "../../../shared-components/fhir-section";
import { JsonTable } from "../../../shared-components/json-table";

const EXPLAIN_TOAST_DURATION = dayjs.duration(5, "second").asMilliseconds();

export function Tasks({
  bundle,
  onShowContent,
  filters,
}: {
  bundle: Bundle<Resource> | undefined;
  onShowContent: (content: React.ReactNode) => void;
  filters: MrFilterSetting;
}) {
  const tasks = getTasks(bundle);

  return (
    <FhirSection sectionId="tasks" sectionName="Tasks" hasContent={tasks.length === 0}>
      <TasksGrid
        filters={filters}
        tasks={tasks}
        onShowContent={(id: string) => {
          const task = tasks.find(c => c.id === id);
          onShowContent(<TasksContent task={task} />);
        }}
      />
    </FhirSection>
  );
}

function TasksContent({ task }: { task: Task | undefined }) {
  const metriportApi = useMetriportApi();
  const toast = useMetriportToast();

  if (!task) {
    return null;
  }

  const valueString = task.extension?.find(ext => ext.valueString)?.valueString ?? "";

  return (
    <Box>
      <Flex justifyContent={"space-between"}>
        <Heading mb="3">Task</Heading>
        <Button
          onClick={() => {
            downloadSourceDocument(valueString, metriportApi);
            toast.info({
              title: "Hold tight... This might take up to 30 seconds.",
              duration: EXPLAIN_TOAST_DURATION,
            });
          }}
        >
          View Source
        </Button>
      </Flex>

      <JsonTable resource={task} />
    </Box>
  );
}

function getTasks(bundle: Bundle<Resource> | undefined): Task[] {
  if (!bundle || !bundle.entry) {
    return [];
  }

  const procedures: Task[] = [];

  for (const entry of bundle.entry) {
    if (entry.resource?.resourceType === "Task") {
      procedures.push(entry.resource);
    }
  }

  return procedures;
}
