import { z } from "zod";
import { apiOSS } from "./apiOSS";

const PATIENT_URL = `/medical/v1/patient`;

const medicalRecordStatusSchema = z.object({
  htmlCreatedAt: z.string().optional(),
  pdfCreatedAt: z.string().optional(),
});
type MedicalRecordStatus = z.infer<typeof medicalRecordStatusSchema>;

const medicalRecordUrlSchema = z.object({
  url: z.string(),
});

export async function getMedicalRecordStatus(patientId: string): Promise<MedicalRecordStatus> {
  const resp = await apiOSS.get(`${PATIENT_URL}/${patientId}/medical-record-status`);
  const mrStatus = medicalRecordStatusSchema.parse(resp.data);
  return mrStatus;
}

export async function getMedicalRecordUrl(
  patientId: string,
  conversionType: "html" | "pdf"
): Promise<string> {
  const resp = await apiOSS.get(`${PATIENT_URL}/${patientId}/medical-record`, {
    params: {
      conversionType,
    },
  });
  return medicalRecordUrlSchema.parse(resp.data).url;
}

// TEMP: This is a temporary fix to get the query start date
export async function getDocumentQueryStatus(patientId: string) {
  const resp = apiOSS.get(`/medical/v1/document/query`, {
    params: {
      patientId,
    },
  });

  return resp;
}
