import { Card, Heading, Divider, Button, Link, HStack, Spacer } from "@chakra-ui/react";
import { ReactNode } from "react";

type ProductCardProps = {
  name: string;
  pricingDetailsUrl: string;
  isActive: boolean;
  flatRate: string | null;
  nOfUsers: number | null;
  pricePerUser: string | null;
  footer: ReactNode;
};

export const ProductCard = ({
  name,
  pricingDetailsUrl,
  isActive,
  flatRate, //eslint-disable-line @typescript-eslint/no-unused-vars
  nOfUsers, //eslint-disable-line @typescript-eslint/no-unused-vars
  pricePerUser, //eslint-disable-line @typescript-eslint/no-unused-vars
  footer,
}: ProductCardProps) => {
  return (
    <Card
      p={8}
      border={"solid #d3d3d3 1px"}
      mb={0}
      borderRadius={20}
      w={{ base: "100%", xl: "40%" }}
    >
      <HStack>
        {" "}
        <Heading fontSize={24}>{name}</Heading> <Spacer></Spacer>
        {isActive && (
          <Link href={pricingDetailsUrl} target={"_blank"} _hover={{ textDecoration: "none" }}>
            <Button type="button" variant="outline">
              View Pricing Details
            </Button>
          </Link>
        )}
      </HStack>

      {/* <Flex
        flexDirection={{ base: "column", sm: "row" }}
        alignItems={{ base: "flex-start", sm: "center" }}
      >
        <Text pr={4} as={"b"} fontSize="6xl">
          {flatRate}
        </Text>
        <Box>
          <Text>flat rate</Text>
          <Text>per month</Text>
        </Box>
      </Flex>

      <Box flexGrow={1}>
        {nOfUsers && <Text mb={1}>- Includes {nOfUsers} users</Text>}
        <Text>
          - {nOfUsers && "After it's"} {pricePerUser} per user per month
        </Text>
      </Box> */}

      <Divider my={5} />
      {footer}
    </Card>
  );
};

export default ProductCard;
