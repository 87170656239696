import { capture } from "../../shared/capture";
import Constants from "./constants";

export function isSandbox(userId: string): boolean {
  const isSandbox = readLocalStorageValue<{ [key: string]: boolean }>(Constants.isSandboxKey, {
    [userId]: false,
  });

  return Boolean(isSandbox[userId]);
}

// A wrapper for "JSON.parse()"" to support "undefined" value
function parseJSON<T>(value: string | null): T | undefined {
  try {
    return value === "undefined" ? undefined : JSON.parse(value ?? "");
  } catch {
    return undefined;
  }
}

const defaultFormatterParams = {
  currency: "USD",
};

export const formatter = new Intl.NumberFormat("en-US", defaultFormatterParams);

export const formatterNoDecimal = new Intl.NumberFormat("en-US", {
  ...defaultFormatterParams,
  minimumFractionDigits: 0,
  style: "currency",
});

export function readLocalStorageValue<T>(key: string, initialValue: T) {
  // Get from local storage then parse stored json or return initialValue

  if (typeof window === "undefined") {
    return initialValue;
  }

  try {
    const item = window.localStorage.getItem(key);

    return item ? (parseJSON(item) as T) : initialValue;
  } catch (error) {
    capture.error(error, { extra: { context: `localStorage.value.read` } });
    return initialValue;
  }
}

export const sleep = (timeInMs: number) => new Promise(resolve => setTimeout(resolve, timeInMs));

export const emptyStringToUndefined = (v: unknown): string | undefined => {
  const toStr = String(v);
  if (!toStr || toStr.trim().length <= 0) return undefined;
  return toStr;
};

export function mapToOptions(map: { [k: string]: string }): { label: string; value: string }[] {
  return Object.keys(map).flatMap(k => {
    const label = map[k];
    if (!label) return [];
    return [{ label, value: k }];
  });
}
