import { Box, Skeleton, Flex } from "@chakra-ui/react";
import { useState } from "react";
import { Bundle, Resource } from "@medplum/fhirtypes";
import { MrFilterSetting } from "../../../api/settings";
import { NoPatientData } from "../patient-overview/no-data";
import { SidePanel } from "../patient-overview/fhir-sections/side-panel";
import { defaultTabOrder, TabOption } from "../patient-overview/fhir-sections/shared/tab-order";

export type SectionCounts = {
  sectionCounts: {
    reports: number;
    documents: number;
  };
};

export function PatientFHIRData({
  filters,
  consolidated,
  isConsolidatedLoading,
}: {
  filters: MrFilterSetting[] | undefined;
  consolidated: Bundle<Resource> | undefined;
  isConsolidatedLoading: boolean;
}) {
  const [sidePanelContent, setSidePanelContent] = useState<React.ReactNode | undefined>(undefined);

  const hasData = consolidated?.entry && consolidated.entry.length > 0;

  if (!hasData && !isConsolidatedLoading) {
    return (
      <Box>
        <NoPatientData />
      </Box>
    );
  }

  return (
    <Box>
      <Flex>
        <Skeleton flex={1} borderRadius={10} isLoaded={!isConsolidatedLoading}>
          <Sections
            filters={filters}
            consolidated={consolidated}
            onShowContent={setSidePanelContent}
          />
        </Skeleton>
        <SidePanel setSidePanelContent={setSidePanelContent} showPanel={!!sidePanelContent}>
          {sidePanelContent}
        </SidePanel>
      </Flex>
    </Box>
  );
}

function Sections({
  filters,
  consolidated,
  onShowContent,
}: {
  filters: MrFilterSetting[] | undefined;
  consolidated: Bundle<Resource> | undefined;
  onShowContent: (content: React.ReactNode) => void;
}) {
  if (!filters) {
    return (
      <>
        {defaultTabOrder.map(tab => {
          const Component = tab.component;
          return <Component key={tab.key} bundle={consolidated} onShowContent={onShowContent} />;
        })}
      </>
    );
  }

  const mappedDefaultOrder = new Map<string, TabOption>();

  for (const tab of defaultTabOrder) {
    mappedDefaultOrder.set(tab.key, tab);
  }

  return (
    <>
      {filters.map(filter => {
        const { key } = filter;
        const Component = mappedDefaultOrder.get(key)?.component;

        if (!Component) {
          return null;
        }

        return (
          <Component
            key={key}
            bundle={consolidated}
            onShowContent={onShowContent}
            filters={filter}
          />
        );
      })}
    </>
  );
}

export function sectionsHaveData({ sectionCounts }: SectionCounts): boolean {
  return Object.values(sectionCounts).some(count => count > 0);
}
