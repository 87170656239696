import { ISO_DATE } from "@metriport/shared/common/date";
import { ColDef } from "ag-grid-community";
import dayjs from "dayjs";
import { Procedure } from "@medplum/fhirtypes";
import { getFirstCodeSpecified, MEDICARE_CODE, SNOMED_CODE, CPT_CODE } from "../shared/shared";
import { FhirGrid } from "../shared/grid";
import { MrFilterSetting } from "../../../../../api/settings";
import { compare, filterByDate } from "../shared/shared";

export function ProceduresGrid({
  procedures,
  onShowContent,
  filters,
}: {
  procedures: Procedure[];
  onShowContent: (id: string) => void;
  filters: MrFilterSetting;
}) {
  const rowData = procedures
    ?.map(procedure => ({
      id: procedure.id ?? "-",
      procedure: getProcedureName(procedure),
      code: getProcedureCode(procedure),
      datePerformed: getProcedureDatePerformed(procedure),
      status: procedure.status ?? "-",
    }))
    .filter(row => filterByDate(row.datePerformed, filters?.dateFilter))
    .sort((a, b) => compare(a, b, filters?.stringFilter));

  const columnDefs: ColDef[] = [
    { field: "id", hide: true },
    { field: "procedure" },
    { field: "code" },
    { field: "datePerformed", sort: filters?.stringFilter ? undefined : "desc" },
    { field: "status" },
  ];

  const defaultColDef: ColDef = {
    flex: 1,
    onCellClicked: e => {
      onShowContent(e.data.id);
    },
    cellClassRules: {
      "cell-filter": params => {
        if (!params.value || !filters?.stringFilter) {
          return false;
        }

        const filtersArray = filters.stringFilter.split(",");

        const hasFilter = filtersArray.some(filter => {
          return params.value.toLowerCase().includes(filter.toLowerCase());
        });

        return hasFilter;
      },
    },
  };

  return (
    <FhirGrid
      sectionTitle="Allergies"
      defaultColDef={defaultColDef}
      columnDefs={columnDefs}
      rowData={rowData}
    />
  );
}

function getProcedureName(procedure: Procedure): string {
  return procedure.code?.text ?? "-";
}

function getProcedureCode(procedure: Procedure): string {
  const coding = getFirstCodeSpecified(procedure.code?.coding ?? [], [
    SNOMED_CODE,
    MEDICARE_CODE,
    CPT_CODE,
  ]);

  if (coding) {
    return coding.display;
  } else if (procedure.code?.text) {
    return procedure.code.text;
  }

  return "-";
}

function getProcedureDatePerformed(procedure: Procedure): string {
  const validDate = procedure.performedDateTime ?? procedure.performedPeriod?.start;

  if (!validDate) {
    return "-";
  }

  return dayjs(validDate).format(ISO_DATE);
}
