import { executeWithNetworkRetries } from "@metriport/shared";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { z } from "zod";
import { capture } from "../shared/capture";
import { api } from "./api";

dayjs.extend(duration);

export const subscriptionStatusSchema = z.enum(["disabled", "active", "overdue"]);
export type SubscriptionStatusSchema = z.infer<typeof subscriptionStatusSchema>;

export const customerSchema = z.object({
  id: z.string(),
  subscriptionStatus: subscriptionStatusSchema,
  subscriptionCancelsAt: z.string().nullable(),
  stripeCxId: z.string().nullable(),
  stripeSubscriptionId: z.string().nullable(),
  devicesStripeSubscriptionItemId: z.string().nullable(),
  medicalStripeSubscriptionItemId: z.string().nullable(),
});
export type Customer = z.infer<typeof customerSchema>;

const CUSTOMER_URL = `/dash/customer`;
const statusToIgnore = [304];
const getCustomerTimeout = dayjs.duration({ seconds: 10 });

function getEmptyCustomer(cxId: string): Customer {
  return {
    id: cxId,
    subscriptionStatus: "disabled",
    subscriptionCancelsAt: null,
    stripeCxId: null,
    stripeSubscriptionId: null,
    devicesStripeSubscriptionItemId: null,
    medicalStripeSubscriptionItemId: null,
  };
}

export async function getCustomer(
  { userId }: { userId: string },
  cxId: string | undefined
): Promise<Customer | undefined> {
  try {
    const resp = await executeWithNetworkRetries(
      () =>
        api.get(CUSTOMER_URL, {
          timeout: getCustomerTimeout.asMilliseconds(),
        }),
      { maxAttempts: 5, initialDelay: 100 }
    );
    if (resp.data) return customerSchema.parse(resp.data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    // Network errors don't set the status, and we don't want to report on those
    // See https://metriport-inc.sentry.io/issues/5615373246/events/4114e1218c08425f9bf8b87416fc0710/
    if (error?.response?.status && !statusToIgnore.includes(error.response.status)) {
      capture.message("Failed to get the customer, using default", {
        extra: { context: `getCustomer`, userId, cxId, error },
        level: "warning",
      });
    }
  }
  return cxId ? customerSchema.parse(getEmptyCustomer(cxId)) : undefined;
}

export default api;
