import { Conditions } from "../conditions";
import { Reports } from "../reports";
import { Documents } from "../documents";
import { Medications } from "../medications";
import { Allergies } from "../allergies";
import { Procedures } from "../procedures";
import { SocialHistory } from "../social-history";
import { Vitals } from "../vitals";
import { Labs } from "../labs";
import { Observations } from "../observations-other";
import { Immunizations } from "../immunizations";
import { FamilyMemberHistory } from "../family-member-history";
import { RelatedPersons } from "../related-persons";
import { Tasks } from "../tasks";
import { Coverages } from "../coverage";
import { Encounters } from "../encounters";

export type TabOption = {
  index: number;
  display: string;
  key: string;
  component: React.ElementType;
};

export const defaultTabOrder: TabOption[] = [
  {
    key: "reports",
    display: "Encounter/Notes",
    index: 0,
    component: Reports,
  },
  {
    key: "conditions",
    display: "Conditions",
    index: 1,
    component: Conditions,
  },
  {
    key: "medications",
    display: "Medications",
    index: 2,
    component: Medications,
  },
  {
    key: "allergies",
    display: "Allergies",
    index: 3,
    component: Allergies,
  },
  {
    key: "procedures",
    display: "Procedures",
    index: 4,
    component: Procedures,
  },
  {
    key: "social-history",
    display: "Social History",
    index: 5,
    component: SocialHistory,
  },
  {
    key: "vitals",
    display: "Vitals",
    index: 6,
    component: Vitals,
  },
  {
    key: "labs",
    display: "Labs",
    index: 7,
    component: Labs,
  },
  {
    key: "observations",
    display: "Observations",
    index: 8,
    component: Observations,
  },
  {
    key: "immunizations",
    display: "Immunizations",
    index: 9,
    component: Immunizations,
  },
  {
    key: "family-member-history",
    display: "Family Member History",
    index: 10,
    component: FamilyMemberHistory,
  },
  {
    key: "related-persons",
    display: "Related Persons",
    index: 11,
    component: RelatedPersons,
  },
  {
    key: "tasks",
    display: "Tasks",
    index: 12,
    component: Tasks,
  },
  {
    key: "coverages",
    display: "Coverage",
    index: 13,
    component: Coverages,
  },
  {
    key: "encounters",
    display: "Encounters",
    index: 14,
    component: Encounters,
  },
  {
    key: "documents",
    display: "Documents",
    index: 15,
    component: Documents,
  },
];
