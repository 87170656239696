import { ISO_DATE } from "@metriport/shared/common/date";
import { ColDef } from "ag-grid-community";
import dayjs from "dayjs";
import { Observation } from "@medplum/fhirtypes";
import { getFirstCodeSpecified, LOINC_CODE, getValidCode } from "../shared/shared";
import { FhirGrid } from "../shared/grid";
import { MrFilterSetting } from "../../../../../api/settings";
import { compare, filterByDate } from "../shared/shared";

export function LabsGrid({
  labs,
  onShowContent,
  filters,
}: {
  labs: Observation[];
  onShowContent: (id: string) => void;
  filters: MrFilterSetting;
}) {
  const rowData = labs
    ?.map(procedure => ({
      id: procedure.id ?? "-",
      observation: getLabsDisplay(procedure),
      value: renderLabsValue(procedure),
      interpretation: renderLabInterpretation(procedure),
      referenceRange: renderLabRefernceRange(procedure),
      code: getLabsCode(procedure),
      date: getSocialHistoryDate(procedure),
    }))
    .filter(row => filterByDate(row.date, filters?.dateFilter))
    .sort((a, b) => compare(a, b, filters?.stringFilter));

  const columnDefs: ColDef[] = [
    { field: "id", hide: true },
    { field: "observation" },
    { field: "value" },
    { field: "interpretation" },
    { field: "referenceRange" },
    { field: "code" },
    { field: "date", sort: filters?.stringFilter ? undefined : "desc" },
  ];

  const defaultColDef: ColDef = {
    flex: 1,
    onCellClicked: e => {
      onShowContent(e.data.id);
    },
    cellClassRules: {
      "cell-filter": params => {
        if (!params.value || !filters?.stringFilter) {
          return false;
        }

        const filtersArray = filters.stringFilter.split(",");

        const hasFilter = filtersArray.some(filter => {
          return params.value.toLowerCase().includes(filter.toLowerCase());
        });

        return hasFilter;
      },
    },
  };

  return (
    <FhirGrid
      sectionTitle="Labs"
      defaultColDef={defaultColDef}
      columnDefs={columnDefs}
      rowData={rowData}
    />
  );
}

function getLabsDisplay(labs: Observation): string {
  const codings = getValidCode(labs.code?.coding);
  const displays = codings.map(coding => coding.display);

  if (displays.length) {
    return displays.join(", ");
  } else if (labs.code?.text) {
    return labs.code.text;
  }

  return "-";
}

function renderLabsValue(labs: Observation): string {
  if (labs.valueQuantity) {
    const value = labs.valueQuantity?.value;
    const unit = labs.valueQuantity?.unit?.replace(/[{()}]/g, "") ?? "";

    return `${value} ${unit}`;
  } else if (labs.valueCodeableConcept) {
    return (
      labs.valueCodeableConcept?.text ??
      getValidCode(labs.valueCodeableConcept.coding)[0]?.display ??
      "-"
    );
  } else if (labs.valueString) {
    return labs.valueString;
  } else {
    return "-";
  }
}

function renderLabInterpretation(labs: Observation): string {
  const interpretationCode = getFirstCodeSpecified(labs.interpretation, ["interpretation"]);

  if (interpretationCode) {
    return interpretationCode.display;
  } else if (labs.interpretation?.[0]?.text) {
    return labs.interpretation[0].text;
  }

  return "-";
}

function renderLabRefernceRange(labs: Observation): string {
  const referenceRanges: string[] = [];

  for (const range of labs.referenceRange ?? []) {
    if (range.low && range.high) {
      referenceRanges.push(`${range.low.value} - ${range.high.value}`);
    } else if (range.low) {
      referenceRanges.push(`>= ${range.low.value}`);
    } else if (range.high) {
      referenceRanges.push(`<= ${range.high.value}`);
    } else if (range.text && range.text !== "unknown") {
      referenceRanges.push(range.text);
    } else {
      referenceRanges.push("-");
    }
  }

  return referenceRanges.join(", ");
}

function getLabsCode(labs: Observation): string {
  const coding = getFirstCodeSpecified(labs.code?.coding, [LOINC_CODE]);

  return coding ? `${coding.system}: ${coding.code}` : "-";
}

function getSocialHistoryDate(labs: Observation): string {
  return dayjs(labs.effectiveDateTime).format(ISO_DATE);
}
