import { Box, Heading, Text, Skeleton } from "@chakra-ui/react";
import { PatientDTO, Address, Contact } from "@metriport/api-sdk";
import PatientMatchesTable from "./patient-matches-table";
import { PatientFacilityMatches } from "../../../../api/patient-matches";

type AddressStateAsString = Omit<Address, "state" | "country"> & {
  state: string;
  country: string;
};

export default function PatientMatchesTab({
  patient,
  matches,
}: {
  patient: PatientDTO | undefined;
  matches: PatientFacilityMatches | null;
}) {
  return (
    <Skeleton isLoaded={!!matches}>
      <Heading size="md" mb={3}>
        Current Patient Demo
      </Heading>
      {patient && (
        <Box display={"flex"} flexDirection={"row"} mb={3}>
          <Box
            width={"max-content"}
            borderRadius={"lg"}
            py={3}
            px={6}
            border={"1px"}
            borderColor="gray.600"
            mr={5}
          >
            <PatientDemoValue label="Id" value={patient.id} />
            <PatientDemoValue label="Name" value={`${patient.firstName} ${patient.lastName}`} />
            <PatientDemoValue label="DOB" value={patient.dob} />
            <PatientDemoValue label="Gender" value={patient.genderAtBirth} />
            <PatientContactValue label="Email" contactType="email" value={patient.contact} />
            <PatientContactValue label="Phone" contactType="phone" value={patient.contact} />
          </Box>
          <PatientAddress address={patient.address} />
        </Box>
      )}
      <PatientMatchesTable patientMatches={matches} />
    </Skeleton>
  );
}

type KeysOfContact = keyof Contact;

function PatientContactValue({
  label,
  contactType,
  value,
}: {
  label: string;
  contactType: KeysOfContact;
  value: Contact | Contact[] | undefined;
}) {
  if (!value) {
    return null;
  }

  return (
    <Box display="flex" alignItems={"center"}>
      <Text fontWeight={"bold"} color={"gray.400"} m={0} mr={2}>
        {label.toUpperCase()}:{" "}
      </Text>
      <Box>
        {Array.isArray(value) ? (
          value.map((v, i) => (
            <Box key={i}>
              <Text m={0}>{v[contactType]}</Text>
            </Box>
          ))
        ) : (
          <Text m={0}>{value[contactType]}</Text>
        )}
      </Box>
    </Box>
  );
}

function PatientAddress({ address }: { address: AddressStateAsString | AddressStateAsString[] }) {
  if (Array.isArray(address)) {
    return (
      <Box display={"flex"} flexDirection="row">
        {address.map((a, i) => {
          return <PatientAddress key={i} address={a} />;
        })}
      </Box>
    );
  }

  return (
    <Box mr={4} borderRadius={"lg"} py={3} px={6} border={"1px"} borderColor="gray.600">
      <PatientDemoValue label="Address line 1" value={address.addressLine1} />
      <PatientDemoValue label="Address line 2" value={address.addressLine2} />
      <PatientDemoValue label="City" value={address.city} />
      <PatientDemoValue label="State" value={address.state} />
      <PatientDemoValue label="Zip" value={address.zip} />
      <PatientDemoValue label="Country" value={address.country} />
    </Box>
  );
}

function PatientDemoValue({ label, value }: { label: string; value: string | undefined }) {
  if (!value) {
    return null;
  }

  return (
    <Box mb={1} display="flex" alignItems={"center"}>
      <Text fontWeight={"bold"} color={"gray.400"} m={0} mr={2}>
        {label.toUpperCase()}:{" "}
      </Text>
      <Text m={0}>{value}</Text>
    </Box>
  );
}
