import * as React from "react";
import { useColorMode, useColorModeValue, IconButtonProps, Button } from "@chakra-ui/react";
import { MoonIcon, SunIcon } from "@chakra-ui/icons";

type ColorModeSwitcherProps = Omit<IconButtonProps, "aria-label">;

export const ColorModeSwitcher: React.FC<ColorModeSwitcherProps> = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const linkColor = useColorModeValue("gray.800", "gray.200");

  return (
    <Button p={0} variant="ghost" color={linkColor} onClick={toggleColorMode}>
      {colorMode === "light" ? <MoonIcon /> : <SunIcon />}
    </Button>
  );
};
