import { ISO_DATE } from "@metriport/shared/common/date";
import { ColDef } from "ag-grid-community";
import dayjs from "dayjs";
import { Coverage, Organization } from "@medplum/fhirtypes";
import { FhirGrid } from "../shared/grid";
import { MrFilterSetting } from "../../../../../api/settings";
import { compare, filterByDate } from "../shared/shared";

export function CoveragesGrid({
  coverages,
  organizations,
  onShowContent,
  filters,
}: {
  coverages: Coverage[];
  organizations: Organization[];
  onShowContent: (id: string) => void;
  filters: MrFilterSetting;
}) {
  const rowData = coverages
    ?.map(coverage => ({
      id: coverage.id ?? "-",
      provider: getCoverageOrganization(coverage, organizations),
      policyId: getPolicyId(coverage),
      status: coverage.status ?? "-",
      startDate: coverage.period?.start ? dayjs(coverage.period.start).format(ISO_DATE) : "-",
      endDate: coverage.period?.end ? dayjs(coverage.period.end).format(ISO_DATE) : "-",
    }))
    .filter(row => filterByDate(row.startDate, filters?.dateFilter))
    .sort((a, b) => compare(a, b, filters?.stringFilter));

  const columnDefs: ColDef[] = [
    { field: "id", hide: true },
    { field: "provider" },
    { field: "policyId" },
    { field: "status" },
    { field: "startDate", sort: filters?.stringFilter ? undefined : "desc" },
    { field: "endDate" },
  ];

  const defaultColDef: ColDef = {
    flex: 1,
    onCellClicked: e => {
      onShowContent(e.data.id);
    },
    cellClassRules: {
      "cell-filter": params => {
        if (!params.value || !filters?.stringFilter) {
          return false;
        }

        const filtersArray = filters.stringFilter.split(",");

        const hasFilter = filtersArray.some(filter => {
          return params.value.toLowerCase().includes(filter.toLowerCase());
        });

        return hasFilter;
      },
    },
  };

  return (
    <FhirGrid
      sectionTitle="Coverages"
      defaultColDef={defaultColDef}
      columnDefs={columnDefs}
      rowData={rowData}
    />
  );
}

function getCoverageOrganization(coverage: Coverage, organizations: Organization[]): string {
  const payorRef = coverage.payor?.[0]?.reference?.split("/")?.[1];

  if (payorRef) {
    const organization = organizations.find(org => org.id === payorRef);
    if (organization) {
      return organization.name ?? "-";
    }
  }

  return "-";
}

function getPolicyId(coverage: Coverage): string {
  const policyIds: string[] = [];

  if (coverage.identifier) {
    for (const identifier of coverage.identifier) {
      if (identifier.value) {
        policyIds.push(identifier.value);
      }
    }
  }

  return policyIds.join(", ");
}
