import { Box, Heading, Text, Flex } from "@chakra-ui/react";
import { Bundle, Resource, Observation } from "@medplum/fhirtypes";
import { ISO_DATE } from "@metriport/shared/common/date";
import dayjs from "dayjs";
import { VitalsGrid } from "./grid";
import { useMetriportApi } from "../../../../shared/useMetriportApi";
import { Button } from "../../../../shared/Button";
import { downloadSourceDocument, getValidCode } from "../shared/shared";
import { VitalsChart } from "./chart";
import useMetriportToast from "../../../../shared/toast";
import { MrFilterSetting } from "../../../../../api/settings";
import { FhirSection } from "../../../shared-components/fhir-section";
import { JsonTable } from "../../../shared-components/json-table";

const EXPLAIN_TOAST_DURATION = dayjs.duration(5, "second").asMilliseconds();
const CHART_DATAPOINTS = 5;

export function Vitals({
  bundle,
  onShowContent,
  filters,
}: {
  bundle: Bundle<Resource> | undefined;
  onShowContent: (content: React.ReactNode) => void;
  filters: MrFilterSetting;
}) {
  const vitals = getVitals(bundle);
  const groupedVitals = groupVitals(vitals);

  return (
    <FhirSection sectionId="vitals" sectionName="Vitals" hasContent={groupedVitals.length === 0}>
      <VitalsGrid
        vitals={groupedVitals}
        filters={filters}
        onShowContent={(id: string) => {
          const vital = groupedVitals.find(v => v.mostRecentObservation.id === id);
          onShowContent(<VitalsContent vital={vital} />);
        }}
      />
    </FhirSection>
  );
}

function VitalsContent({ vital }: { vital: GroupedVitals | undefined }) {
  const metriportApi = useMetriportApi();
  const toast = useMetriportToast();

  if (!vital) {
    return null;
  }

  const mostRecentVital = vital.mostRecentObservation;
  const valueString = mostRecentVital.extension?.find(ext => ext.valueString)?.valueString ?? "";

  let observations: number[] | undefined = undefined;
  let dates: string[] | undefined = undefined;
  let reversePoints: string[] | undefined = undefined;
  if (vital.sortedPoints) {
    observations = vital.sortedPoints.observations;
    dates = vital.sortedPoints.dates;
    reversePoints = observations
      .slice()
      .reverse()
      .map((o, index) => {
        const date = formatDate(((dates as string[]).slice().reverse() ?? [])[index]);
        return `${date} - ${o}`;
      });
  }

  return (
    <Box>
      <Flex justifyContent={"space-between"}>
        <Heading mb="3">{`${vital.title}`}</Heading>
        <Button
          onClick={() => {
            downloadSourceDocument(valueString, metriportApi);
            toast.info({
              title: "Hold tight... This might take up to 30 seconds.",
              duration: EXPLAIN_TOAST_DURATION,
            });
          }}
        >
          View Source
        </Button>
      </Flex>
      {observations && dates && (
        <>
          <VitalsChart
            vitalsId={mostRecentVital.id ?? ""}
            vitals={observations.slice(
              Math.max(0, observations.length - CHART_DATAPOINTS),
              observations.length
            )}
            dates={dates
              .slice(Math.max(0, dates.length - CHART_DATAPOINTS), dates.length)
              .map(formatDate)}
          />
          <Heading size={"md"} mt={5}>
            Most recent observation
          </Heading>
          {reversePoints && (
            <Box mt={5}>
              <Heading size={"md"}>Data points</Heading>
              {reversePoints.map(p => (
                <Text>{p}</Text>
              ))}
            </Box>
          )}
        </>
      )}
      <Box mt={4}>
        <JsonTable resource={mostRecentVital} />
      </Box>
    </Box>
  );
}

function getVitals(bundle: Bundle<Resource> | undefined): Observation[] {
  if (!bundle || !bundle.entry) {
    return [];
  }

  const vitals: Observation[] = [];

  for (const entry of bundle.entry) {
    if (entry.resource?.resourceType === "Observation") {
      const isVitals = entry.resource?.category?.find(
        ext => ext.coding?.[0]?.code?.toLowerCase() === "vital-signs"
      );

      if (isVitals) {
        vitals.push(entry.resource);
      }
    }
  }

  return vitals;
}

export type GroupedVitals = {
  title: string;
  mostRecentObservation: Observation;
  sortedPoints?: {
    observations: number[];
    dates: string[];
  };
};

function groupVitals(vitals: Observation[]): GroupedVitals[] {
  const reuslts: GroupedVitals[] = [];
  const observationMap: {
    [k: string]: { rawVital: Observation; observation: number; date: string }[];
  } = {};
  vitals.map(v => {
    let title: string;
    const codings = getValidCode(v.code?.coding);
    const displays = codings.map(coding => coding.display);
    if (displays.length) {
      title = displays.join(", ");
    } else if (v.code?.text) {
      title = v.code.text;
    } else {
      reuslts.push({ title: "-", mostRecentObservation: v });
      return;
    }
    if (!v.effectiveDateTime || !v.valueQuantity?.value) {
      reuslts.push({ title, mostRecentObservation: v });
      return;
    }
    const observationPoint = {
      rawVital: v,
      date: v.effectiveDateTime,
      observation: v.valueQuantity.value,
    };
    const groupedObservation = observationMap[title];
    if (groupedObservation) {
      groupedObservation.push(observationPoint);
    } else {
      observationMap[title] = [observationPoint];
    }
  });

  Object.entries(observationMap).map(([title, values]) => {
    const sortedPoints = values.sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    );
    const mostRecent = sortedPoints[sortedPoints.length - 1];
    if (!mostRecent) return;
    reuslts.push({
      title,
      mostRecentObservation: mostRecent.rawVital,
      sortedPoints: {
        observations: sortedPoints.map(p => p.observation),
        dates: sortedPoints.map(p => p.date),
      },
    });
  });
  return reuslts;
}

export function formatDate(date: string | undefined) {
  return dayjs(date).format(ISO_DATE);
}
