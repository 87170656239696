import {
  Box,
  Flex,
  InputGroup,
  Input,
  InputLeftElement,
  Heading,
  Divider,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SearchIcon } from "@chakra-ui/icons";
import { MetriportMedicalApi, PatientDTO, Facility } from "@metriport/api-sdk";
import { Button as MetriportButton } from "../../shared/Button";
import PatientForm from "./form";
import PatientGrid from "./grid";
import { METRIPORT_PRIMARY } from "../../../shared/style";
import useMetriportToast from "../../shared/toast";
import { capture } from "../../../shared/capture";
import { Analytics, Features, Actions } from "../../shared/analytics";

export default function Patients({ api }: { api: MetriportMedicalApi }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useMetriportToast();
  const navigate = useNavigate();
  const [patients, setPatients] = useState<PatientDTO[] | undefined>(undefined);
  const [patientToEdit, setPatientToEdit] = useState<PatientDTO | undefined>(undefined);
  const [facilities, setFacilities] = useState<Facility[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [tableFromTop, setTableFromTopHeight] = useState(0);

  useEffect(() => {
    async function getPatients() {
      try {
        const patients = await api.listPatients("");
        const facilities = await api.listFacilities();
        setPatients(patients);
        setFacilities(facilities);
      } catch (err) {
        const msg = "Error retrieving patients";
        toast.error({ title: msg });
        capture.error(msg, {
          extra: { context: `patients.get`, err },
        });
      } finally {
        setIsLoading(false);
      }
    }
    getPatients();

    const element = document.getElementById("table-container");

    if (element) {
      setTableFromTopHeight(element.getBoundingClientRect().top);
    }
  }, []);

  function onPatientFormClosed(patient: PatientDTO) {
    if (!patients) return;

    if (patientToEdit) {
      const index = patients.findIndex(p => p.id === patient.id);
      patients[index] = patient;
      setPatients([...patients]);
    } else {
      setPatients([patient, ...patients]);
    }

    Analytics.emit(Actions.submit, Features.form, { type: Features.patient });
    setPatientToEdit(undefined);
  }

  function onPatientCreate() {
    Analytics.emit(Actions.create, Features.patient);
    onOpen();
  }

  function onPatientEdit(patient: PatientDTO): void {
    Analytics.emit(Actions.update, Features.patient);
    setPatientToEdit(patient);
    onOpen();
  }

  function onPatientClose() {
    onClose();
    setPatientToEdit(undefined);
  }

  return (
    <>
      <Box px={5}>
        <Flex alignItems={"center"} justifyContent={"space-between"}>
          <Heading size="xl">Patients</Heading>
          <Box>
            <MetriportButton mr={4} onClick={onPatientCreate}>
              Create Patient
            </MetriportButton>
          </Box>
        </Flex>
        <Divider my={6} />
        <Flex justifyContent={"space-between"} mb={5}>
          <InputGroup>
            <InputLeftElement pointerEvents="none" color="gray.300" fontSize="1.2em">
              <SearchIcon />
            </InputLeftElement>
            <Input
              minW={"300px"}
              w={"25%"}
              value={search}
              placeholder="Search for patients"
              onClick={() => Analytics.emit(Actions.search, Features.patient)}
              onChange={e => setSearch(e.target.value)}
              _hover={{ borderColor: METRIPORT_PRIMARY }}
              _active={{ borderColor: METRIPORT_PRIMARY }}
              _focus={{ borderColor: METRIPORT_PRIMARY }}
            />
          </InputGroup>
          {/* V 1.5  ADD FILTERS */}
          {/* <Button
            style={{ backgroundColor: "transparent", borderColor: "#748df0", color: "#748df0" }}
            leftIcon={<BsFilter />}
            variant="outline"
          >
            Filter by facility
          </Button> */}
        </Flex>
        <Box
          id="table-container"
          style={{ width: "100%", height: `calc(95vh - ${tableFromTop}px)` }}
        >
          <PatientGrid
            isLoading={isLoading}
            patients={patients}
            facilities={facilities}
            search={search}
            onPatientClick={patient => navigate(`/patient/${patient}`)}
            onPatientEdit={onPatientEdit}
          />
        </Box>
      </Box>

      <PatientForm
        selectedPatient={patientToEdit}
        onPatientAction={onPatientFormClosed}
        facilities={facilities}
        isOpen={isOpen}
        onClose={onPatientClose}
      />
    </>
  );
}
