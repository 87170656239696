import { Box, Heading, Flex } from "@chakra-ui/react";
import {
  Bundle,
  Resource,
  Medication,
  MedicationAdministration,
  MedicationDispense,
  MedicationStatement,
} from "@medplum/fhirtypes";
import dayjs from "dayjs";
import { MedicationGrid } from "./grid";
import { useMetriportApi } from "../../../../shared/useMetriportApi";
import { Button } from "../../../../shared/Button";
import { downloadSourceDocument } from "../shared/shared";
import useMetriportToast from "../../../../shared/toast";
import { MrFilterSetting } from "../../../../../api/settings";
import { FhirSection } from "../../../shared-components/fhir-section";
import { JsonTable } from "../../../shared-components/json-table";

const EXPLAIN_TOAST_DURATION = dayjs.duration(5, "second").asMilliseconds();

export type MedicationWithRefs = {
  medication: Medication;
  administration?: MedicationAdministration;
  dispense?: MedicationDispense;
  statement?: MedicationStatement;
};

export function Medications({
  bundle,
  onShowContent,
  filters,
}: {
  bundle: Bundle<Resource> | undefined;
  onShowContent: (content: React.ReactNode) => void;
  filters: MrFilterSetting;
}) {
  const medications = getMedications(bundle);

  return (
    <FhirSection
      sectionId="medications"
      sectionName="Medications"
      hasContent={medications.length === 0}
    >
      <MedicationGrid
        filters={filters}
        medicationsWithRefs={medications}
        onShowContent={(id: string) => {
          const medication = medications.find(m => m.medication.id === id);
          onShowContent(<MedicationContent medicationWithRefs={medication} />);
        }}
      />
    </FhirSection>
  );
}

function getMedications(bundle: Bundle<Resource> | undefined): MedicationWithRefs[] {
  const medicationsWithRefs: MedicationWithRefs[] = [];

  const medications: Medication[] = [];
  const medicationAdministration: Record<string, MedicationAdministration> = {};
  const medicationDispense: Record<string, MedicationDispense> = {};
  const medicationStatement: Record<string, MedicationStatement> = {};

  if (bundle) {
    for (const entry of bundle.entry || []) {
      const resource = entry.resource as Resource;
      if (resource.resourceType === "Medication") {
        medications.push(resource);
      }

      if (resource.resourceType === "MedicationAdministration") {
        const medRefId = getMedicationReferenceId(resource);

        if (medRefId) {
          medicationAdministration[medRefId] = resource;
        }
      } else if (resource.resourceType === "MedicationDispense") {
        const medRefId = getMedicationReferenceId(resource);

        if (medRefId) {
          medicationDispense[medRefId] = resource;
        }
      } else if (resource.resourceType === "MedicationStatement") {
        const medRefId = getMedicationReferenceId(resource);

        if (medRefId) {
          medicationStatement[medRefId] = resource;
        }
      }
    }
  }

  for (const medication of medications) {
    const medicationId = medication.id || "";

    medicationsWithRefs.push({
      medication,
      administration: medicationAdministration[medicationId],
      dispense: medicationDispense[medicationId],
      statement: medicationStatement[medicationId],
    });
  }

  return medicationsWithRefs;
}

function getMedicationReferenceId(
  medication: MedicationAdministration | MedicationDispense | MedicationStatement
): string | undefined {
  if (medication.medicationReference?.reference) {
    return medication.medicationReference.reference.split("/")[1];
  }

  return undefined;
}

function MedicationContent({
  medicationWithRefs,
}: {
  medicationWithRefs: MedicationWithRefs | undefined;
}) {
  const metriportApi = useMetriportApi();
  const toast = useMetriportToast();

  if (!medicationWithRefs) {
    return null;
  }

  const valueString =
    medicationWithRefs.medication.extension?.find(ext => ext.valueString)?.valueString ?? "";

  return (
    <Box>
      <Flex justifyContent={"space-between"}>
        <Heading mb="3">Medication</Heading>
        <Button
          onClick={() => {
            downloadSourceDocument(valueString, metriportApi);
            toast.info({
              title: "Hold tight... This might take up to 30 seconds.",
              duration: EXPLAIN_TOAST_DURATION,
            });
          }}
        >
          View Source
        </Button>
      </Flex>

      <JsonTable resource={medicationWithRefs.medication} />
    </Box>
  );
}
