import { z } from "zod";
import { nonStrictAddressSchema } from "./medical/address";
import { apiOSS } from "./apiOSS";

const PATIENT_URL = `/medical/v1/patient`;

const patientFacilityMatchSchema = z.object({
  name: z.string(),
  oid: z.string(),
  address: nonStrictAddressSchema,
  patient: z
    .object({
      dob: z.string().nullable(),
      gender: z.string().nullable(),
      names: z.array(z.string()),
      addresses: z.array(z.string()),
      telephoneNumbers: z.array(z.string()),
      emails: z.array(z.string()),
      driversLicenses: z.array(z.string()),
      ssns: z.array(z.string()),
    })
    .optional(),
});

export type PatientFacilityMatch = z.infer<typeof patientFacilityMatchSchema>;

const patientFacilityMatchesSchema = z.array(patientFacilityMatchSchema);

export type PatientFacilityMatches = z.infer<typeof patientFacilityMatchesSchema>;

export async function getPatientFacilityMatches(
  patientId: string
): Promise<PatientFacilityMatches> {
  const resp = await apiOSS.get(`${PATIENT_URL}/${patientId}/facility-matches`);

  return patientFacilityMatchesSchema.parse(resp.data);
}
