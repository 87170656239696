import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { Flex, IconButton, Input, InputGroup, InputRightAddon, useToast } from "@chakra-ui/react";
import { ReactNode, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { BsClipboard, BsTrash } from "react-icons/bs";
import Dialog from "./dialog";
import { Label } from "./shared/Label";

const DEFAULT_VALUE = "************";

const Key = (props: {
  value?: string;
  show?: boolean;
  revokeKey?: () => Promise<void>;
  children?: ReactNode;
}) => {
  const { value, show = false, revokeKey, children } = props;

  const toast = useToast();
  const [showInternal, setShowInternal] = useState(show);
  const [isWarning, setIsWarning] = useState(false);
  const [isRevoking, setIsRevoking] = useState(false);

  const handleClick = () => !!value && setShowInternal(!showInternal);

  const copied = () => {
    toast({
      title: "Copied to the clipboard",
      status: "success",
      duration: 3_000,
      isClosable: true,
    });
  };

  const revoke = async () => {
    if (!revokeKey) return;
    setIsRevoking(true);
    await revokeKey();
    setIsRevoking(false);
    setIsWarning(false);
  };

  return (
    <>
      <Flex alignItems="left" direction={"row"} mt={3}>
        <Label mt={2}>{children ? children : "Key"}:</Label>
        <InputGroup mr={3}>
          <Input
            type={showInternal ? "text" : "password"}
            value={value ?? DEFAULT_VALUE}
            onClick={handleClick}
            readOnly
          />
          {!!value && (
            <InputRightAddon
              children={
                showInternal ? <ViewIcon color="grey.500" /> : <ViewOffIcon color="grey.500" />
              }
              onClick={handleClick}
            />
          )}
        </InputGroup>
        {!!value && (
          <CopyToClipboard text={value} onCopy={copied}>
            <IconButton aria-label="Copy to clipboard" icon={<BsClipboard />} />
          </CopyToClipboard>
        )}
        {revokeKey ? (
          <IconButton
            isLoading={isRevoking}
            aria-label="Revoke key"
            onClick={() => setIsWarning(true)}
            icon={<BsTrash />}
            ml={3}
          ></IconButton>
        ) : undefined}
      </Flex>
      {isWarning && (
        <Dialog
          show
          title="Are you sure you want to do this?"
          message={
            "This operation can not be reversed. Deleting this key will immediately prevent it from being used for API access, and you will have to generate a new one to continue using the API."
          }
          actionText="Delete Key"
          onAction={revoke}
          actionIsLoading={isRevoking}
          onClose={() => setIsWarning(false)}
        />
      )}
    </>
  );
};

export default Key;
