import {
  HStack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  Flex,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Button } from "../../../../shared/Button";
import { DateRange } from "../../../../shared/form/DateRange";
import { Input } from "../../../../shared/form/Input";
import { GetRecordsParams } from "./index";

const docRefDateRangeId = "doc-ref-date-range";
const docRefContentId = "doc-ref-content";
const MIN_CONTENT_LENGTH = 3;

export function SearchModal({
  onSearchDocuments,
  isOpen,
  onClose,
  isFetchingDocuments,
}: {
  onSearchDocuments: ({ dateFrom, dateTo, content }: GetRecordsParams) => Promise<void>;
  isOpen: boolean;
  onClose: () => void;
  isFetchingDocuments: boolean;
}) {
  const [dateFrom, setDateFrom] = useState<string>("");
  const [dateTo, setDateTo] = useState<string>("");
  const [content, setContent] = useState<string>("");
  const [isSearchDisabled, setIsSearchDisabled] = useState<boolean>(true);

  useEffect(() => {
    const filters = localStorage.getItem("doc_filters");
    if (filters) {
      const { dateFrom, dateTo, content } = JSON.parse(filters);
      setDateFrom(dateFrom);
      setDateTo(dateTo);
      setContent(content);
    }
  }, []);

  useEffect(() => {
    const isDisabled = Boolean(
      content && content.length > 0 && content.length < MIN_CONTENT_LENGTH
    );
    setIsSearchDisabled(isDisabled);
  }, [content]);

  const clearFilters = () => {
    setDateFrom("");
    setDateTo("");
    setContent("");
    onSearchDocuments({});
    onClose();
  };

  const doSearch = () => !isSearchDisabled && onSearchDocuments({ dateFrom, dateTo, content });

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"2xl"}>
      <ModalOverlay />
      <ModalContent px={6} py={4}>
        <Text mb={1} fontSize={"xl"} fontWeight="bold">
          Search Documents
        </Text>
        <ModalCloseButton />
        <Text color={"gray.400"} mb={0}>
          This is to search through existing documents that have been queried across the networks.
        </Text>

        <HStack mb={3} alignItems={"end"}>
          <DateRange
            id={docRefDateRangeId}
            isRequired={false}
            label="Filter by date"
            dateFrom={dateFrom}
            dateTo={dateTo}
            onSetToDate={(to: string) => setDateTo(to)}
            onSetFromDate={(from: string) => setDateFrom(from)}
          />
          <Input
            id={docRefContentId}
            value={content}
            isRequired={false}
            label={`Content (min ${MIN_CONTENT_LENGTH} char)`}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setContent(e.target.value)}
            onEnterKeyPress={doSearch}
          />
        </HStack>
        <Flex flexDirection={"row"} justifyContent={"flex-end"}>
          <Button
            isLoading={isFetchingDocuments}
            disabled={isSearchDisabled}
            onClick={() => {
              doSearch();
              onClose();
            }}
          >
            Search
          </Button>
          <Button
            ml={3}
            style={{ backgroundColor: "transparent", borderColor: "#748df0", color: "#748df0" }}
            variant="outline"
            onClick={clearFilters}
          >
            Clear
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  );
}
