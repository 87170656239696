import { ISO_DATE } from "@metriport/shared/common/date";
import { ColDef } from "ag-grid-community";
import dayjs from "dayjs";
import { Task } from "@medplum/fhirtypes";
import { getFirstCodeSpecified, SNOMED_CODE, getValidCode } from "../shared/shared";
import { FhirGrid } from "../shared/grid";
import { MrFilterSetting } from "../../../../../api/settings";
import { compare, filterByDate } from "../shared/shared";

export function TasksGrid({
  tasks,
  onShowContent,
  filters,
}: {
  tasks: Task[];
  onShowContent: (id: string) => void;
  filters: MrFilterSetting;
}) {
  const rowData = tasks
    ?.map(task => ({
      id: task.id ?? "-",
      task: task.description ?? "-",
      reason: getTaskReason(task),
      code: renderTaskCode(task),
      note: getTaskNote(task),
      date: getTaskDate(task),
    }))
    .filter(row => filterByDate(row.date, filters?.dateFilter))
    .sort((a, b) => compare(a, b, filters?.stringFilter));

  const columnDefs: ColDef[] = [
    { field: "id", hide: true },
    { field: "task" },
    { field: "reason" },
    { field: "code" },
    { field: "note" },
    { field: "date", sort: filters?.stringFilter ? undefined : "desc" },
  ];

  const defaultColDef: ColDef = {
    flex: 1,
    onCellClicked: e => {
      onShowContent(e.data.id);
    },
    cellClassRules: {
      "cell-filter": params => {
        if (!params.value || !filters?.stringFilter) {
          return false;
        }

        const filtersArray = filters.stringFilter.split(",");

        const hasFilter = filtersArray.some(filter => {
          return params.value.toLowerCase().includes(filter.toLowerCase());
        });

        return hasFilter;
      },
    },
  };

  return (
    <FhirGrid
      sectionTitle="Tasks"
      defaultColDef={defaultColDef}
      columnDefs={columnDefs}
      rowData={rowData}
    />
  );
}

function getTaskReason(task: Task): string {
  const codings = getValidCode(task.reasonCode?.coding);
  const displays = codings.map(coding => coding.display);

  if (displays.length) {
    return displays.join(", ");
  } else if (task.reasonCode?.text) {
    return task.reasonCode.text;
  }

  return "-";
}

function renderTaskCode(task: Task) {
  const code = getFirstCodeSpecified(task.code?.coding, [SNOMED_CODE]);

  if (code) {
    return code.display;
  }

  return "-";
}

function getTaskNote(task: Task): string {
  const notes: string[] = [];

  if (task.note) {
    for (const note of task.note) {
      if (note.text) {
        notes.push(note.text);
      }
    }
  }

  return notes.join(", ");
}

function getTaskDate(task: Task): string {
  if (task.authoredOn) {
    return dayjs(task.authoredOn).format(ISO_DATE);
  }

  return "-";
}
