import { z } from "zod";
import { apiOSS as api } from "./apiOSS";

const WEBHOOK_STATUS_URL = `/settings/webhook`;
const WEBHOOK_RETRY_URL = `/settings/webhook/retry`;

export const webhookStatusSchema = z.object({
  webhookEnabled: z.boolean(),
  webhookStatusDetail: z.string().nullable(),
  webhookRequestsProcessing: z.number(),
  webhookRequestsFailed: z.number(),
});
export type WebhookStatus = z.infer<typeof webhookStatusSchema>;

export async function getWebhookStatus(): Promise<WebhookStatus | null> {
  try {
    const resp = await api.get(WEBHOOK_STATUS_URL);
    return webhookStatusSchema.parse(resp.data);
  } catch (err) {
    // Fail silently b/c it might not exist after the account has been recently created.
    // Also, we load settings anyways, so we deal w/ error there.
    return null;
  }
}

export async function retryWebhookRequests(): Promise<void> {
  await api.post(WEBHOOK_RETRY_URL);
}

export default api;
