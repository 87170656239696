import { Box, Heading, useColorModeValue } from "@chakra-ui/react";
import { NoPatientData } from "../patient-overview/no-data";

export function FhirSection({
  sectionId,
  sectionName,
  hasContent,
  children,
}: {
  sectionId: string;
  sectionName: string;
  hasContent: boolean;
  children: React.ReactNode;
}) {
  return (
    <Box
      id={sectionId}
      w={"100%"}
      mb={5}
      borderRadius={10}
      bg={useColorModeValue("white", "gray.800")}
      px={7}
      py={5}
      border="solid"
      borderColor={useColorModeValue("gray.200", "gray.700")}
    >
      <Heading mb={5}>{sectionName}</Heading>
      {hasContent ? <NoPatientData /> : children}
    </Box>
  );
}
