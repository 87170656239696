import { ISO_DATE } from "@metriport/shared/common/date";
import { ColDef } from "ag-grid-community";
import dayjs from "dayjs";
import { Immunization } from "@medplum/fhirtypes";
import { getFirstCodeSpecified, RX_NORM_CODE, getValidCode } from "../shared/shared";
import { FhirGrid } from "../shared/grid";
import { MrFilterSetting } from "../../../../../api/settings";
import { compare, filterByDate } from "../shared/shared";

export function ImmunizationsGrid({
  immunizations,
  onShowContent,
  filters,
}: {
  immunizations: Immunization[];
  onShowContent: (id: string) => void;
  filters: MrFilterSetting;
}) {
  const rowData = immunizations
    ?.map(immunization => ({
      id: immunization.id ?? "-",
      immunization: getImmunizationsDisplay(immunization),
      code: getImmunizationsCode(immunization),
      manufacturer: immunization.manufacturer?.display ?? "-",
      date: getImmunizationDate(immunization),
      status: immunization.status ?? "-",
    }))
    .filter(row => filterByDate(row.date, filters?.dateFilter))
    .sort((a, b) => compare(a, b, filters?.stringFilter));

  const columnDefs: ColDef[] = [
    { field: "id", hide: true },
    {
      field: "immunization",
    },
    { field: "code" },
    { field: "manufacturer" },
    { field: "date", sort: filters?.stringFilter ? undefined : "desc" },
    { field: "status" },
  ];

  const defaultColDef: ColDef = {
    flex: 1,
    onCellClicked: e => {
      onShowContent(e.data.id);
    },
    cellClassRules: {
      "cell-filter": params => {
        if (!params.value || !filters?.stringFilter) {
          return false;
        }

        const filtersArray = filters.stringFilter.split(",");

        const hasFilter = filtersArray.some(filter => {
          return params.value.toLowerCase().includes(filter.toLowerCase());
        });

        return hasFilter;
      },
    },
  };

  return (
    <FhirGrid
      sectionTitle="Immunizations"
      defaultColDef={defaultColDef}
      columnDefs={columnDefs}
      rowData={rowData}
    />
  );
}

function getImmunizationsDisplay(immunization: Immunization): string {
  const codings = getValidCode(immunization.vaccineCode?.coding);
  const displays = codings.map(coding => coding.display);

  if (displays.length) {
    return displays.join(", ");
  } else if (immunization.vaccineCode?.text) {
    return immunization.vaccineCode.text;
  }

  return "-";
}

function getImmunizationsCode(immunization: Immunization): string {
  const coding = getFirstCodeSpecified(immunization.vaccineCode?.coding, ["cvx", RX_NORM_CODE]);

  return coding ? `${coding.system}: ${coding.code}` : "-";
}

function getImmunizationDate(immunizations: Immunization): string {
  return dayjs(immunizations.occurrenceDateTime).format(ISO_DATE);
}
