import { Resource } from "@medplum/fhirtypes";
import { JsonToTable } from "react-json-to-table";
import { omit } from "lodash";

export function JsonTable({ resource }: { resource: Resource | undefined }) {
  const inheritedValues = ["meta", "contained", "extension", "modifierExtension"];
  const cleanJson = omit(resource, inheritedValues);

  return <JsonToTable json={cleanJson} />;
}
