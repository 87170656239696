import { z } from "zod";

import { api } from "./api";

export const productCodeSchema = z.enum(["medical", "devices"]);

export type ProductCode = z.infer<typeof productCodeSchema>;

export const productSchema = z.object({
  productCode: productCodeSchema,
  name: z.string(),
  flatRate: z.number().nullable(),
  upToAmount: z.number().nullable(),
  pricePerUser: z.number().nullable(),
});

export type Product = z.infer<typeof productSchema>;

export const productsSchema = z.object({
  products: z.array(productSchema),
});

export type Products = z.infer<typeof productsSchema>;

const PRODUCTS_URL = `/dash/billing/products`;

// TODO: 1411 - Remove when DAPI is fully discontinued
export async function getProducts(cxId: string): Promise<Products> {
  const resp = await api.get(PRODUCTS_URL, {
    // TODO update this, shouldn't send the cxId as param, but use JWT
    // https://github.com/metriport/metriport-internal/issues/1028
    params: {
      cxId,
    },
  });

  if (!resp.data) throw new Error(`Didnt return products`);

  return productsSchema.parse(resp.data);
}
