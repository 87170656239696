import { ColDef } from "ag-grid-community";
import { Observation } from "@medplum/fhirtypes";
import { getFirstCodeSpecified, LOINC_CODE, getValidCode } from "../shared/shared";
import { FhirGrid } from "../shared/grid";
import { GroupedVitals, formatDate } from "./index";
import { MrFilterSetting } from "../../../../../api/settings";
import { compare, filterByDate } from "../shared/shared";

export function VitalsGrid({
  vitals,
  onShowContent,
  filters,
}: {
  vitals: GroupedVitals[];
  onShowContent: (id: string) => void;
  filters: MrFilterSetting;
}) {
  const rowData = vitals
    ?.map(vitals => ({
      id: vitals.mostRecentObservation.id ?? "-",
      observation: getVitalsDisplay(vitals.mostRecentObservation),
      mostRecentValue: renderVitalsValue(vitals.mostRecentObservation),
      code: getVitalsCode(vitals.mostRecentObservation),
      mostRecentDate: getVitalsDate(vitals.mostRecentObservation),
    }))
    .filter(row => filterByDate(row.mostRecentDate, filters?.dateFilter))
    .sort((a, b) => compare(a, b, filters?.stringFilter));

  const columnDefs: ColDef[] = [
    { field: "id", hide: true },
    { field: "observation" },
    { field: "mostRecentValue" },
    { field: "code" },
    { field: "mostRecentDate", sort: filters?.stringFilter ? undefined : "desc" },
  ];

  const defaultColDef: ColDef = {
    flex: 1,
    onCellClicked: e => {
      onShowContent(e.data.id);
    },
    cellClassRules: {
      "cell-filter": params => {
        if (!params.value || !filters?.stringFilter) {
          return false;
        }

        const filtersArray = filters.stringFilter.split(",");

        const hasFilter = filtersArray.some(filter => {
          return params.value.toLowerCase().includes(filter.toLowerCase());
        });

        return hasFilter;
      },
    },
  };

  return (
    <FhirGrid
      sectionTitle="Vitals"
      defaultColDef={defaultColDef}
      columnDefs={columnDefs}
      rowData={rowData}
    />
  );
}

function getVitalsDisplay(vitals: Observation): string {
  const codings = getValidCode(vitals.code?.coding);
  const displays = codings.map(coding => coding.display);

  if (displays.length) {
    return displays.join(", ");
  } else if (vitals.code?.text) {
    return vitals.code.text;
  }

  return "-";
}

function renderVitalsValue(vitals: Observation) {
  if (vitals.valueQuantity) {
    const value = vitals.valueQuantity?.value;
    const unit = vitals.valueQuantity?.unit?.replace(/[{()}]/g, "");

    return `${value} ${unit}`;
  } else if (vitals.valueCodeableConcept) {
    return (
      vitals.valueCodeableConcept?.text ??
      getValidCode(vitals.valueCodeableConcept.coding)[0]?.display ??
      "-"
    );
  } else {
    return "-";
  }
}

function getVitalsCode(vitals: Observation): string {
  const coding = getFirstCodeSpecified(vitals.code?.coding, [LOINC_CODE]);

  return coding ? `${coding.system}: ${coding.code}` : "-";
}

function getVitalsDate(vitals: Observation): string {
  return formatDate(vitals.effectiveDateTime);
}
