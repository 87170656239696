import { z } from "zod";
import { api } from "./api";

const USAGE_URL = `/dash/billing/usage`;

export const discountsSchema = z.array(z.object({ amount: z.number(), discount: z.string() }));

export const quantitiesSchema = z.object({
  amount: z.number().nullable(),
  quantity: z.number().nullable(),
  description: z.string().nullable(),
  unitAmount: z.number().nullable(),
});

export type Quantities = z.infer<typeof quantitiesSchema>;

export const usageSchema = z.object({
  periodStart: z.number(),
  periodEnd: z.number(),
  quantities: z.array(quantitiesSchema).nullable(),
  discountAmount: z.number().nullable(),
  amountDue: z.number(),
  subtotal: z.number(),
  total: z.number(),
});

export type Usage = z.infer<typeof usageSchema>;

export async function getUsage(cxId: string): Promise<Usage | undefined> {
  const resp = await api.get(USAGE_URL, {
    params: {
      cxId,
    },
  });
  if (!resp.data) return undefined;

  return usageSchema.parse(resp.data);
}

export default api;
