import { useState } from "react";
import {
  PatientCreate,
  BaseUpdate,
  DriverLicenseIdentifier,
  usStateSchema,
  PatientDTO,
} from "@metriport/api-sdk";
import { cloneDeep } from "lodash";
import { useMetriportApi } from "../../shared/useMetriportApi";
import { PatientFormSchema, DRIVERS_LICENSE } from "../shared-components/patient-form";
import { filterTruthy } from "./filter-map-utils";
import { Actions, Analytics, Features } from "./analytics";

type UseGetSubmitPatient = {
  createOrUpdatePatient: (patientForm: PatientFormSchema) => Promise<PatientDTO>;
  isSubmitting: boolean;
};

export function useSubmitPatient({ patientId }: { patientId?: string }): UseGetSubmitPatient {
  const metriportApi = useMetriportApi();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const createOrUpdatePatient = async (patientForm: PatientFormSchema): Promise<PatientDTO> => {
    const patient = formToApi(patientForm);

    setIsSubmitting(true);

    let resp: PatientDTO;
    if (patientId) {
      Analytics.emit(Actions.update, Features.patient);
      resp = await metriportApi.updatePatient(
        {
          ...patient,
          id: patientId,
        },
        patientForm.facilityId
      );
    } else {
      Analytics.emit(Actions.create, Features.patient);
      resp = await metriportApi.createPatient(patient, patientForm.facilityId);
    }

    setIsSubmitting(false);

    return resp;
  };

  return { createOrUpdatePatient, isSubmitting };
}

const formToApi = (form: PatientFormSchema): PatientCreate & Partial<BaseUpdate> => {
  const formCopy = cloneDeep(form);
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  delete (formCopy as any).driversLicense; // safe on this context, don't use formCopy for anything else
  const personalIdentifiers: DriverLicenseIdentifier[] = [
    form.driversLicense.value && form.driversLicense.state
      ? {
          type: DRIVERS_LICENSE,
          value: form.driversLicense.value,
          state: usStateSchema.parse(form.driversLicense.state),
        }
      : undefined,
  ]
    .flatMap(filterTruthy)
    .filter(pid => pid.type.trim().length > 0 && pid.value.trim().length > 0);
  return {
    ...formCopy,
    contact: form.contact.filter(c => c.phone !== undefined || c.email !== undefined),
    address: [
      {
        ...form.address[0],
        state: usStateSchema.parse(form.address[0].state),
      },
      ...form.address.slice(1).map(address => {
        return {
          ...address,
          state: usStateSchema.parse(address.state),
        };
      }),
    ],
    personalIdentifiers,
  };
};
