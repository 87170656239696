import { Box, useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import { DocumentProgressContainer } from "../shared-components/progress-bar";
import { PatientsHeader } from "../shared-components/header";
import { PatientForm } from "../shared-components/patient-form";
import { MrModal } from "../shared-components/mr-modal";
import { PatientMatches } from "../shared-components/patient-matches";
import { PatientTabs } from "../shared-components/tabs";
import { RawDocuments } from "../shared-components/raw-documents";
import { PatientFHIRData } from "../shared-components/fhir-sections";
import { useGetPatientInfo } from "../shared-logic/useGetPatientInfo";
import { useGetFacilities } from "../shared-logic/useGetFacilties";
import { useQueryDocuments } from "../shared-logic/useQueryDocuments";
import { useSubmitPatient } from "../shared-logic/useSubmitPatient";
import { useGetMedicalRecord } from "../shared-logic/useGetMedicalRecord";
import { useGetPatientMatches } from "../shared-logic/useGetPatientMatches";
import { useGetFilters } from "../shared-logic/useGetFilters";
import { useGetConsolidated } from "../shared-logic/useGetConsolidated";
import { useDownloadFile } from "../shared-logic/useDownloadFile";

export type SectionCounts = {
  sectionCounts: {
    reports: number;
    documents: number;
  };
};

const tabs = ["fhir", "documents"] as const;

export default function PatientsOverview({ patientId }: { patientId: string }) {
  const patientFormDisclosure = useDisclosure();
  const patientMRDisclosure = useDisclosure();
  const patientMatchesDisclosure = useDisclosure();

  const { createOrUpdatePatient, isSubmitting } = useSubmitPatient({ patientId });
  const { patient, isFetchingPatient } = useGetPatientInfo({ patientId });
  const { filters } = useGetFilters();
  const { facilities } = useGetFacilities();
  const { existingMrStatus, isMrDownloading, onDownloadMR } = useGetMedicalRecord({ patientId });
  const { patientMatches } = useGetPatientMatches({ patientId });
  const { onDownloadFile, isDownloading } = useDownloadFile();
  const {
    isQueryingDocuments,
    onQueryPatientDocuments,
    onSearchPatientDocuments,
    downloadProgress,
    lastDocQuery,
    documents,
  } = useQueryDocuments({
    patientId,
  });
  const { consolidated, isConsolidatedLoading } = useGetConsolidated({
    patientId,
    canQueryConsolidated: documents.documents.length > 0 && !isQueryingDocuments,
  });

  const [patientTab, setPatientTab] = useState<(typeof tabs)[number]>("fhir");

  return (
    <>
      {/* V1 Going to add Breadcrumbs */}
      <Box p={5}>
        <PatientsHeader
          patient={patient}
          isQueryingDocuments={isQueryingDocuments}
          isFetchingPatient={isFetchingPatient}
          isDownloadingMr={isMrDownloading}
          selectedTab={patientTab}
          lastDocQuery={lastDocQuery}
          actions={{
            onQueryPatientDocuments,
            onEditPatient: patientFormDisclosure.onOpen,
            onGenerateMedicalRecord: patientMRDisclosure.onOpen,
            onViewPatientMatches: patientMatchesDisclosure.onOpen,
            onSetPatientTab: setPatientTab,
          }}
        />
        {patientTab === "fhir" && (
          <>
            <PatientTabs filters={filters} />
            <PatientFHIRData
              filters={filters}
              consolidated={consolidated}
              isConsolidatedLoading={isQueryingDocuments || isConsolidatedLoading}
            />
          </>
        )}
        {patientTab === "documents" && (
          <RawDocuments
            documents={documents.documents}
            isSearchingDocuments={isQueryingDocuments}
            isQueryingDocuments={isQueryingDocuments}
            isDownloading={isDownloading}
            actions={{
              onSearchDocuments: onSearchPatientDocuments,
              onDownloadFile,
            }}
          />
        )}
      </Box>
      <DocumentProgressContainer
        isQueryingDocuments={isQueryingDocuments}
        total={downloadProgress.total}
        completed={downloadProgress.completed}
      />
      <PatientMatches
        patient={patient}
        patientMatches={patientMatches}
        isOpen={patientMatchesDisclosure.isOpen}
        actions={{
          onClose: patientMatchesDisclosure.onClose,
        }}
      />
      <PatientForm
        isOpen={patientFormDisclosure.isOpen}
        selectedPatient={patient}
        facilities={facilities}
        isSubmitting={isSubmitting}
        actions={{
          onCreateOrUpdatePatient: createOrUpdatePatient,
          onCloseForm: patientFormDisclosure.onClose,
        }}
      />
      <MrModal
        isOpen={patientMRDisclosure.isOpen}
        isDownloading={isMrDownloading}
        existingMrStatus={existingMrStatus}
        actions={{
          onCloseModal: patientMRDisclosure.onClose,
          onDownloadMR,
        }}
      />
    </>
  );
}
