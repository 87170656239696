import { ISO_DATE } from "@metriport/shared/common/date";
import { ColDef } from "ag-grid-community";
import dayjs from "dayjs";
import { Observation } from "@medplum/fhirtypes";
import { getFirstCodeSpecified, LOINC_CODE, getValidCode } from "../shared/shared";
import { FhirGrid } from "../shared/grid";
import { MrFilterSetting } from "../../../../../api/settings";
import { compare, filterByDate } from "../shared/shared";

export function ObservationsGrid({
  observations,
  onShowContent,
  filters,
}: {
  observations: Observation[];
  onShowContent: (id: string) => void;
  filters: MrFilterSetting;
}) {
  const rowData = observations
    ?.map(procedure => ({
      id: procedure.id ?? "-",
      observation: getObservationsDisplay(procedure),
      value: renderObservationsValue(procedure),
      code: getObservationsCode(procedure),
      date: getSocialHistoryDate(procedure),
    }))
    .filter(row => filterByDate(row.date, filters?.dateFilter))
    .sort((a, b) => compare(a, b, filters?.stringFilter));

  const columnDefs: ColDef[] = [
    { field: "id", hide: true },
    { field: "observation" },
    { field: "value" },
    { field: "code" },
    { field: "date", sort: filters?.stringFilter ? undefined : "desc" },
  ];

  const defaultColDef: ColDef = {
    flex: 1,
    onCellClicked: e => {
      onShowContent(e.data.id);
    },
    cellClassRules: {
      "cell-filter": params => {
        if (!params.value || !filters?.stringFilter) {
          return false;
        }

        const filtersArray = filters.stringFilter.split(",");

        const hasFilter = filtersArray.some(filter => {
          return params.value.toLowerCase().includes(filter.toLowerCase());
        });

        return hasFilter;
      },
    },
  };

  return (
    <FhirGrid
      sectionTitle="Observations"
      defaultColDef={defaultColDef}
      columnDefs={columnDefs}
      rowData={rowData}
    />
  );
}

function getObservationsDisplay(observations: Observation): string {
  const codings = getValidCode(observations.code?.coding);
  const displays = codings.map(coding => coding.display);

  if (displays.length) {
    return displays.join(", ");
  } else if (observations.code?.text) {
    return observations.code.text;
  }

  return "-";
}

function renderObservationsValue(observations: Observation): string {
  if (observations.valueQuantity) {
    const value = observations.valueQuantity?.value;
    const unit = observations.valueQuantity?.unit?.replace(/[{()}]/g, "");

    return `${value} ${unit}`;
  } else if (observations.valueCodeableConcept) {
    return (
      observations.valueCodeableConcept?.text ??
      getValidCode(observations.valueCodeableConcept.coding)[0]?.display ??
      "-"
    );
  } else {
    return "-";
  }
}

function getObservationsCode(observations: Observation): string {
  const coding = getFirstCodeSpecified(observations.code?.coding, [LOINC_CODE]);

  return coding ? `${coding.system}: ${coding.code}` : "-";
}

function getSocialHistoryDate(observations: Observation): string {
  return dayjs(observations.effectiveDateTime).format(ISO_DATE);
}
