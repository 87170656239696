import { useLocalStorage } from "usehooks-ts";
import { AppState } from "../contexts/app/reducer";
import Constants from "./constants";

export const useIsUserInSandbox = (
  state: AppState
): {
  sandboxMap: { [key: string]: boolean };
  isUserInSandbox: boolean;
  setIsUserInSandbox: (newVal: boolean) => void;
} => {
  const username = state.user?.userId || "";

  const [sandboxMap, _setIsSandbox] = useLocalStorage<{ [key: string]: boolean }>(
    Constants.isSandboxKey,
    {
      [username]: false,
    }
  );

  function setIsUserInSandbox(newVal: boolean) {
    _setIsSandbox({
      ...sandboxMap,
      [username]: newVal,
    });
  }

  const isUserInSandbox = sandboxMap[username] ?? false;

  return {
    sandboxMap,
    isUserInSandbox,
    setIsUserInSandbox,
  };
};
