import { z } from "zod";
import { apiOSS as api } from "./apiOSS";

export const mrFilterSchema = z.object({
  key: z.string(),
  dateFilter: z
    .object({
      from: z.string().optional(),
      to: z.string().optional(),
    })
    .optional(),
  stringFilter: z.string().optional(),
});

export type MrFilterSetting = z.infer<typeof mrFilterSchema>;

export const settingsSchema = z.object({
  id: z.string(),
  webhookUrl: z.string().nullable(),
  webhookKey: z.string().nullable(),
  mrFilters: z.array(mrFilterSchema).nullish(),
});

export type Settings = z.infer<typeof settingsSchema>;

const SETTINGS_URL = `/settings`;

export async function getSettings(): Promise<Settings | null> {
  const resp = await api.get(SETTINGS_URL);
  return resp.data ? settingsSchema.parse(resp.data) : null;
}

export const settingsUpdateSchema = z.object({
  webhookUrl: z.string().url().optional().or(z.literal("")),
});
export type SettingsUpdate = z.infer<typeof settingsUpdateSchema>;

export async function updateSettings(payload: SettingsUpdate): Promise<Settings> {
  const resp = await api.post(SETTINGS_URL, payload);
  if (!resp.data) throw new Error(`Update didn't return Settings`);
  return settingsSchema.parse(resp.data);
}
