import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import { useState } from "react";
import { Bundle, Resource, DocumentReference } from "@medplum/fhirtypes";
import useMetriportToast from "../../../../shared/toast";
import { useMetriportApi } from "../../../../shared/useMetriportApi";
import { capture } from "../../../../../shared/capture";
import { DocumentGrid } from "./grid";
import { formatDate } from "../../../../shared/date";
import { DocumentMenu } from "./document-menu";
import { getMetriportContent, getOrganizationName, getDocumentSize } from "./grid";
import { MrFilterSetting } from "../../../../../api/settings";
import { FhirSection } from "../../../shared-components/fhir-section";

export type GetRecordsParams = {
  dateFrom?: string;
  dateTo?: string;
  content?: string;
};

export type isDownloading = Record<
  string,
  {
    type?: string;
    downloading: boolean;
  }
>;

export function Documents({
  bundle,
  onShowContent,
  filters,
}: {
  bundle: Bundle<Resource> | undefined;
  onShowContent: (content: React.ReactNode) => void;
  filters: MrFilterSetting;
}) {
  const documents = getDocumentReferencesFromBundle(bundle);

  const metriportApi = useMetriportApi();
  const toast = useMetriportToast();

  const [isDownloading, setIsDownloading] = useState<isDownloading>({});

  async function onDownloadDocument(
    docId: string,
    docFilename: string,
    conversionType?: "html" | "pdf"
  ): Promise<void> {
    setIsDownloading({
      ...isDownloading,
      [docId]: {
        downloading: true,
        type: conversionType ?? "xml",
      },
    });
    try {
      if (conversionType) {
        toast.info({
          title: "Hold tight... This might take up to 30 seconds.",
          duration: 5000,
        });
      }
      const resp = await metriportApi.getDocumentUrl(docFilename, conversionType);

      const a = document.createElement("a");
      a.href = resp.url;
      a.download = docFilename;
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (err) {
      capture.error(err, { extra: { docId: docId, context: `patient.file.download` } });
      toast.error();
    }
    setIsDownloading({
      ...isDownloading,
      [docId]: {
        downloading: false,
      },
    });
  }

  return (
    <FhirSection sectionId="documents" sectionName="Documents" hasContent={documents.length === 0}>
      <DocumentGrid
        filters={filters}
        noActions
        documentReference={documents}
        onDownloadDocument={onDownloadDocument}
        isDownloading={isDownloading}
        onShowContent={(id: string) => {
          const document = documents.find(d => d.id === id);
          onShowContent(
            <DocumentContent
              onDownloadDocument={onDownloadDocument}
              isDownloading={isDownloading}
              document={document}
            />
          );
        }}
      />
    </FhirSection>
  );
}

function DocumentContent({
  document,
  onDownloadDocument,
  isDownloading,
}: {
  document: DocumentReference | undefined;
  onDownloadDocument: (
    docId: string,
    docFilename: string,
    conversionType?: "html" | "pdf"
  ) => Promise<void>;
  isDownloading: isDownloading;
}) {
  if (!document) {
    return null;
  }

  const metriportContent = getMetriportContent(document);
  const contentType = metriportContent?.attachment?.contentType;
  const fileName = metriportContent?.attachment?.title;
  const organization = getOrganizationName(document);
  const size = getDocumentSize(document);
  const date = document.date ? formatDate(document.date) : "-";

  return (
    <Box>
      <Heading mb="3">Document</Heading>

      <Flex mb={2} justifyContent={"space-between"}>
        <Box w={"49%"}>
          <Box mb={2}>
            <Text mb={1}>Description</Text>
            <Text>{document.description ?? `N/A`}</Text>
          </Box>
          <Box mb={2}>
            <Text mb={1}>Date</Text>
            <Text>{date}</Text>
          </Box>
          <Box mb={2}>
            <Text mb={1}>Content Type</Text>
            <Text>{contentType}</Text>
          </Box>
        </Box>
        <Box w={"49%"}>
          <Box mb={2}>
            <Text mb={1}>Size</Text>
            <Text>{size}</Text>
          </Box>
          <Box mb={2}>
            <Text mb={1}>Organization</Text>
            <Text>{organization}</Text>
          </Box>
        </Box>
      </Flex>

      <DocumentMenu
        contentType={contentType}
        docId={document.id}
        fileName={fileName}
        onDownloadDocument={onDownloadDocument}
        isDownloading={isDownloading}
      />
    </Box>
  );
}

export function getDocumentReferencesFromBundle(bundle: Bundle | undefined): DocumentReference[] {
  if (!bundle || !bundle.entry) {
    return [];
  }

  return (
    bundle.entry
      ?.filter(entry => entry.resource?.resourceType === "DocumentReference")
      .map(entry => entry.resource as DocumentReference) ?? []
  );
}
