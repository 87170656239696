import { OrgMemberInfo, UserClass, WithLoggedInAuthInfoProps } from "@propelauth/react";
import { isSandbox } from "../components/shared/util";
import { UserKeys } from "../domain/api-key";

// TODO concentrate this on a shared package, like we have for OSS
// This also exists on lambdas and api
const apiKeyPropName = "apiKeyId";
const clientApiKeyPropName = "clientApiKeyId";
const sandboxApiKeyPropName = "sandboxApiKeyId";
const sandboxClientApiKeyPropName = "sandboxClientApiKeyId";

export function getCxId({
  userClass,
}: Pick<WithLoggedInAuthInfoProps, "userClass">): string | undefined {
  const userAtOrg = getOrgMemberInfo(userClass);
  if (!userAtOrg) return undefined;
  return userAtOrg.orgMetadata?.cxId;
}

export function getCustomerName(userClass: UserClass): string | undefined {
  const userAtOrg = getOrgMemberInfo(userClass);
  if (!userAtOrg) return undefined;
  return userAtOrg.orgName;
}

function getOrgMemberInfo(userClass: UserClass): OrgMemberInfo | undefined {
  const userAtOrgs = userClass.orgIdToUserOrgInfo;
  const orgId = userAtOrgs ? Object.keys(userAtOrgs)[0] : undefined;
  if (!orgId) return undefined;
  const userAtOrg = userAtOrgs?.[orgId];
  return userAtOrg;
}

export function getUserKeyIds(userClass: UserClass): UserKeys {
  const userAtOrg = getOrgMemberInfo(userClass);
  if (!userAtOrg) return {};
  const orgMeta = userAtOrg.orgMetadata;
  const cxId = getCxId({ userClass });
  if (!cxId) return {};
  const proNames = getApiKeyPropNames(isSandbox(userClass.userId));
  return {
    apiKeyId: orgMeta[proNames.apiKeyPropName] as string | undefined,
    clientApiKeyId: orgMeta[proNames.envClientApiKey] as string | undefined,
  };
}

function getApiKeyPropNames(isSandbox: boolean) {
  return {
    apiKeyPropName: isSandbox ? sandboxApiKeyPropName : apiKeyPropName,
    envClientApiKey: isSandbox ? sandboxClientApiKeyPropName : clientApiKeyPropName,
  };
}
