import { ColDef } from "ag-grid-community";
import { DocumentReference, DocumentReferenceContent } from "@medplum/fhirtypes";
import { FhirGrid } from "../shared/grid";
import type { CustomCellRendererProps } from "@ag-grid-community/react";
import { formatDate } from "../../../../shared/date";
import { bytesToSize } from "../../../../../shared/util";
import { isDownloading } from ".";
import { DocumentMenu } from "./document-menu";
import { MrFilterSetting } from "../../../../../api/settings";
import { compare, filterByDate } from "../shared/shared";

const METRIPORT_CODE = "METRIPORT";

export function DocumentGrid({
  documentReference,
  onShowContent,
  onDownloadDocument,
  isDownloading,
  noActions,
  filters,
}: {
  documentReference: DocumentReference[];
  onDownloadDocument: (
    docId: string,
    docFilename: string,
    conversionType?: "html" | "pdf"
  ) => Promise<void>;
  isDownloading: isDownloading;
  onShowContent?: (id: string) => void;
  noActions?: boolean;
  filters?: MrFilterSetting;
}) {
  const rowData = documentReference
    ?.map(documentReference => {
      const metriportContent = getMetriportContent(documentReference);
      const contentType = metriportContent?.attachment?.contentType;
      const fileName = metriportContent?.attachment?.title;

      return {
        id: documentReference.id ?? "-",
        fileName: fileName ?? "-",
        description: documentReference.description ?? "-",
        size: getDocumentSize(documentReference),
        fileType: contentType ?? "-",
        organization: getOrganizationName(documentReference),
        date: documentReference.date ? formatDate(documentReference.date) : "-",
      };
    })
    .filter(row => filterByDate(row.date, filters?.dateFilter))
    .sort((a, b) => compare(a, b, filters?.stringFilter));

  const columnDefs: ColDef[] = [
    { field: "id", hide: true },
    { field: "fileName", hide: true },
    { field: "description" },
    { field: "size" },
    { field: "fileType" },
    { field: "organization" },
    { field: "date", sort: filters?.stringFilter ? undefined : "desc" },
    ...(noActions
      ? []
      : [
          {
            field: "actions",
            minWidth: 350,
            onCellClicked: () => null,
            cellRenderer: (props: CustomCellRendererProps) => {
              return (
                <DocumentMenu
                  contentType={props.data.fileType}
                  docId={props.data.id}
                  fileName={props.data.fileName}
                  onDownloadDocument={onDownloadDocument}
                  isDownloading={isDownloading}
                />
              );
            },
          },
        ]),
  ];

  const defaultColDef: ColDef = {
    flex: 1,
    onCellClicked: e => {
      if (onShowContent) {
        onShowContent(e.data.id);
      }
    },
    cellClassRules: {
      "cell-filter": params => {
        if (!params.value || !filters?.stringFilter) {
          return false;
        }

        const filtersArray = filters.stringFilter.split(",");

        const hasFilter = filtersArray.some(filter => {
          return params.value.toLowerCase().includes(filter.toLowerCase());
        });

        return hasFilter;
      },
    },
  };

  return (
    <FhirGrid
      defaultColDef={defaultColDef}
      debounceVerticalScrollbar={true}
      sectionTitle={"Documents"}
      columnDefs={columnDefs}
      rowData={rowData}
    />
  );
}

export const getDocumentSize = (doc: DocumentReference): string => {
  if (doc.content) {
    const metriportContent = doc.content.find(isMetriportContent);

    if (metriportContent?.attachment?.size) {
      return bytesToSize(metriportContent.attachment.size);
    }

    return "-";
  }

  return "-";
};

export function getMetriportContent(doc: DocumentReference): DocumentReferenceContent | undefined {
  if (!doc || !doc.content) return undefined;
  const contents = doc.content.filter(isMetriportContent);

  return contents[0];
}

function isMetriportContent(content: DocumentReferenceContent): boolean {
  return !!content.extension?.some(ext => ext.valueCoding?.code === METRIPORT_CODE);
}

export const getOrganizationName = (doc: DocumentReference): string => {
  if (doc.contained) {
    const org = doc.contained.flatMap(c => (c.resourceType === "Organization" ? c : []))[0];
    if (org?.name) return org.name;

    return "-";
  }
  return "-";
};
