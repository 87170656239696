import { Box, useColorModeValue, useBreakpointValue } from "@chakra-ui/react";
import { Modal, ModalOverlay, ModalContent } from "@chakra-ui/react";

export function SidePanel({
  showPanel,
  setSidePanelContent,
  children,
}: {
  showPanel: boolean;
  setSidePanelContent: (content: React.ReactNode) => void;
  children: React.ReactNode;
}) {
  const bg = useColorModeValue("white", "gray.800");

  const useModal = useBreakpointValue({
    base: true,
    "2xl": false,
  });

  return (
    <>
      {useModal ? (
        <Modal isOpen={showPanel} onClose={() => setSidePanelContent(undefined)}>
          <ModalOverlay />
          <ModalContent
            overflow={"auto"}
            height={"calc(100vh - 100px)"}
            mx={8}
            bg={bg}
            borderRadius={10}
            border={"solid"}
            borderColor={useColorModeValue("gray.200", "gray.700")}
            py={4}
            px={4}
            w={1500}
          >
            {children}
          </ModalContent>
        </Modal>
      ) : (
        <Box
          overflow={"auto"}
          hidden={!showPanel}
          height={"calc(100vh - 100px)"}
          top={50 + 25}
          position="sticky"
          ml={6}
          bg={bg}
          width={"30vw"}
          borderRadius={10}
          border={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          py={4}
          px={8}
        >
          {children}
        </Box>
      )}
    </>
  );
}
