import {
  Box,
  Flex,
  Heading,
  Input,
  Spinner,
  Stack,
  Text,
  Textarea,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useZorm } from "react-zorm";

import {
  getSettings,
  Settings,
  SettingsUpdate,
  settingsUpdateSchema,
  updateSettings,
} from "../../api/settings";
import { getWebhookStatus, WebhookStatus } from "../../api/webhook-status";
import { capture } from "../../shared/capture";
import { isAxiosNetworkError, retryWithExponentialBackoff } from "../../shared/retry";
import { useAppContext } from "../contexts/app";
import Dialog from "../dialog";
import Key from "../key";
import { Actions, Analytics, Features } from "../shared/analytics";
import { Button } from "../shared/Button";
import Constants from "../shared/constants";
import { Label } from "../shared/Label";
import { Link } from "../shared/Link";
import useMetriportToast from "../shared/toast";
import { sleep } from "../shared/util";
import WebhookRequests from "./webhook-requests";

const DEFAULT_WEBHOOK_URL = "";
const DEFAULT_WEBHOOK_KEY = "";
const DEFAULT_WEBHOOK_STATUS = "--";

const defaultSettings = {
  id: "",
  webhookUrl: DEFAULT_WEBHOOK_URL,
  webhookKey: DEFAULT_WEBHOOK_KEY,
};

const defaultWebhookStatus = {
  webhookEnabled: false,
  webhookStatusDetail: DEFAULT_WEBHOOK_STATUS,
  webhookRequestsProcessing: -1,
  webhookRequestsFailed: -1,
};

export default function Webhook() {
  const { state } = useAppContext();
  const [settings, setSettings] = useState<Settings | null>(defaultSettings);
  const [webhookStatus, setWebhookStatus] = useState<WebhookStatus>(defaultWebhookStatus);
  const [errorTitle, setErrorTitle] = useState<string | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const toast = useMetriportToast();
  const [isLargerThan1000] = useMediaQuery("(min-width: 1000px)");
  const [showKeys, setShowKeys] = useState(false);

  const zorm = useZorm("webhook", settingsUpdateSchema, {
    onValidSubmit(e) {
      e.preventDefault();
      update(e.data);
    },
  });

  const update = async ({ webhookUrl }: SettingsUpdate) => {
    Analytics.emit(Actions.test, Features.webhookUrl);
    try {
      setIsSubmitting(true);
      setShowKeys(false);
      setWebhookStatus({
        ...webhookStatus,
        webhookStatusDetail: DEFAULT_WEBHOOK_STATUS,
      });
      const updatedSettings = await updateSettings({ webhookUrl });
      setSettings({
        ...updatedSettings,
      });
      if (!updatedSettings.webhookUrl) {
        toast.success({ title: "Saved" });
      } else {
        toast.success({ title: "Saved, checking status..." });
        await sleep(3_000);
        const updatedStatus = await getWebhookStatus();
        if (updatedStatus) {
          setWebhookStatus(updatedStatus);
        }
        toast.success({ title: "Status updated" });
      }
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (err.response?.status >= 400 && err.response?.status <= 499) {
        setErrorTitle("Validation message");
        setErrorMessage(err.response?.detail ?? "Invalid URL");
      } else {
        capture.error(err, {
          extra: { webhookUrl, context: `webhook.update`, captureEnv: capture.getEnvironment() },
        });
        setErrorMessage(Constants.errorDefaultMessage);
      }
    }
    setIsSubmitting(false);
  };

  const loadSettings = useCallback(async () => {
    const apiKeyId = state.keyIds?.apiKeyId;
    if (!apiKeyId) {
      return setSettings(defaultSettings);
    }

    try {
      const localSettingsPromise = () => getSettings();
      const localWebhookStatusPromise = () => getWebhookStatus();

      const [localSettings, localWebhookStatus] = await Promise.all([
        // TODO update to use executeWithNetworkRetries
        retryWithExponentialBackoff(
          localSettingsPromise,
          "webhook.loadSettings",
          isAxiosNetworkError,
          6
        ),
        // TODO update to use executeWithNetworkRetries
        retryWithExponentialBackoff(
          localWebhookStatusPromise,
          "webhook.loadSettings",
          isAxiosNetworkError,
          6
        ),
      ]);

      setSettings(localSettings);
      setWebhookStatus(localWebhookStatus ?? defaultWebhookStatus);
    } catch (err) {
      toast.error({
        title: "Failed to load webhook settings. Please reload the page.",
        duration: 5000,
      });
    }
  }, [setSettings, setWebhookStatus, state.keyIds]);

  useEffect(() => {
    setSettings(null);
    loadSettings();
  }, [loadSettings]);

  const clearErrMessage = () => {
    setErrorMessage(undefined);
    setErrorTitle(undefined);
  };

  return (
    <Box pb={10}>
      <VStack align="stretch">
        <Heading>Webhook</Heading>
        {!settings ? (
          <Spinner size="xl" />
        ) : (
          <VStack align="left">
            {state.keyIds?.apiKeyId ? (
              <Box>
                <Text>
                  This is your application's URL to receive updates from Metriport - see details{" "}
                  <Link href="https://docs.metriport.com/home/api-info/webhooks" isExternal>
                    here
                  </Link>
                  .
                </Text>
                <form ref={zorm.ref}>
                  <Flex direction={"column"}>
                    <Flex alignItems="left" direction={isLargerThan1000 ? "row" : "column"}>
                      <Stack direction="column" flexGrow={3}>
                        <Flex alignItems="left" direction={isLargerThan1000 ? "row" : "column"}>
                          <Label>URL:</Label>
                          <Input
                            type="text"
                            name={zorm.fields.webhookUrl()}
                            className={zorm.errors.webhookUrl("errored")}
                            value={settings.webhookUrl ?? DEFAULT_WEBHOOK_URL}
                            onChange={(e: { target: { value: string } }) => {
                              setSettings({
                                ...settings,
                                webhookUrl: e.target.value,
                              });
                            }}
                            mt={3}
                          />
                        </Flex>
                        {zorm.errors.webhookUrl(() => (
                          <Text>Webhook must be a valid URL</Text>
                        ))}
                      </Stack>
                      <Button type="submit" isLoading={isSubmitting} ml={3} mt={3}>
                        Save and Test
                      </Button>
                    </Flex>

                    <Key value={settings.webhookKey ?? DEFAULT_WEBHOOK_KEY} show={showKeys} />

                    <WebhookRequests
                      webhookRequestsProcessing={
                        webhookStatus ? webhookStatus.webhookRequestsProcessing : -1
                      }
                      webhookRequestsFailed={
                        webhookStatus ? webhookStatus.webhookRequestsFailed : -1
                      }
                      isSubmitting={isSubmitting}
                      onRetryStart={() => {
                        setIsSubmitting(true);
                        setShowKeys(false);
                      }}
                      onRetryEnd={async () => {
                        try {
                          await loadSettings();
                        } catch (err) {
                          capture.error(err, { extra: { context: `webhook.retry.onEnd` } });
                          setErrorMessage(Constants.errorDefaultMessage);
                        }
                        setIsSubmitting(false);
                      }}
                      onError={msg => {
                        setErrorMessage(msg);
                      }}
                    />

                    <Flex
                      alignItems="left"
                      direction={isLargerThan1000 ? "row" : "column"}
                      flexGrow={3}
                    >
                      <Label>Last status:</Label>
                      {isLargerThan1000 ? (
                        <Input
                          variant="filled"
                          value={webhookStatus?.webhookStatusDetail ?? DEFAULT_WEBHOOK_STATUS}
                          readOnly
                          mt={3}
                          fontFamily="courier"
                        />
                      ) : (
                        <Textarea
                          variant="filled"
                          value={webhookStatus?.webhookStatusDetail ?? DEFAULT_WEBHOOK_STATUS}
                          readOnly
                          mt={3}
                          fontFamily="courier"
                          noOfLines={3}
                        />
                      )}
                    </Flex>
                  </Flex>
                </form>
                {!!errorMessage && (
                  <Dialog
                    show
                    message={errorMessage}
                    title={errorTitle}
                    onClose={clearErrMessage}
                  />
                )}
              </Box>
            ) : (
              <Text>Need to generate an API key before configuring your webhook.</Text>
            )}
          </VStack>
        )}
      </VStack>
    </Box>
  );
}
