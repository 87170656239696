import { Alert, AlertIcon, Box, Flex, Heading, Text, VStack } from "@chakra-ui/react";
import { withRequiredAuthInfo } from "@propelauth/react";
import { useCallback, useState } from "react";
import { apiCognito } from "../../api/api";
import { isDevicesSubscriptionActive, isMedicalSubscriptionActive } from "../../domain/customer";
import { capture } from "../../shared/capture";
import { useAppContext } from "../contexts/app";
import { AppStateActionType } from "../contexts/app/reducer";
import Key from "../key";
import { Actions, Analytics, Features } from "../shared/analytics";
import { Button } from "../shared/Button";
import useMetriportToast from "../shared/toast";
import { useIsUserInSandbox } from "../shared/useIsUserInSandbox";

const msgApiKeyGenerated =
  "Key created. It will only be displayed once. Please make sure to copy it now.";

const ApiKey = withRequiredAuthInfo(({ refreshAuthInfo }) => {
  const { dispatch, state } = useAppContext();
  const toast = useMetriportToast();
  const { isUserInSandbox } = useIsUserInSandbox(state);

  const [isGeneratingSecretKey, setIsGeneratingSecretKey] = useState(false);
  const [isGeneratingClientKey, setIsGeneratingClientKey] = useState(false);

  const [secretKeyValue, setSecretKeyValue] = useState<string | undefined>(undefined);
  const [clientKeyValue, setClientKeyValue] = useState<string | undefined>(undefined);

  const updateKey = useCallback(
    (keyName: "clientApiKeyId" | "apiKeyId", apiKeyId: string) => {
      dispatch({
        type: AppStateActionType.update,
        newState: {
          keyIds: {
            ...state.keyIds,
            [keyName]: apiKeyId,
          },
        },
      });
    },
    [state, dispatch]
  );

  async function generateKey(
    setIsGenerating: (bool: boolean) => void,
    params?: { clientKey: boolean }
  ) {
    setIsGenerating(true);
    const type = getTypeFromParams(params);
    try {
      const resp = await apiCognito.post("/generateKey", null, {
        headers: { "Content-Type": "application/json" },
        params: { ...params, isSandbox: isUserInSandbox },
      });
      refreshAuthInfo();
      Analytics.emit(Actions.generate, Features[type]);
      updateKey(type, resp.data.id);
      type === "apiKeyId" ? setSecretKeyValue(resp.data.key) : setClientKeyValue(resp.data.key);
      toast.warning({ title: msgApiKeyGenerated, duration: 5_000 });
    } catch (error) {
      const msg = `Error generating API Key`;
      toast.error({ title: msg });
      capture.error(msg, {
        extra: { context: `apiKey.generate`, params, isUserInSandbox, error },
      });
    }
    setIsGenerating(false);
  }

  async function revokeKey(
    setIsGenerating: (bool: boolean) => void,
    params?: { clientKey: boolean }
  ) {
    setIsGenerating(true);
    const type = getTypeFromParams(params);
    try {
      const resp = await apiCognito.post("/revokeKey", null, {
        headers: { "Content-Type": "application/json" },
        params: { ...params, isSandbox: isUserInSandbox },
      });
      if (!resp.data.success) {
        throw new Error("Failed to revoke API key");
      }
      refreshAuthInfo();
      Analytics.emit(Actions.revoke, Features[type]);
      updateKey(type, "");
    } catch (error) {
      const msg = `Error revoking API Key`;
      toast.error({ title: msg });
      capture.error(msg, {
        extra: { context: `apiKey.revoke`, params, isUserInSandbox, error },
      });
    }
    setIsGenerating(false);
  }

  const isSubscriptionActive = isMedicalSubscriptionActive(state, isUserInSandbox);
  const isDeviceSubscriptionActive = isDevicesSubscriptionActive(state);

  return (
    <Box pb={10}>
      <VStack align="stretch">
        <Heading>API Keys</Heading>
        <KeysContainer
          title="API Key"
          apiKeyId={state.keyIds?.apiKeyId}
          apiKeyValue={secretKeyValue}
          isGenerating={isGeneratingSecretKey}
          generateKey={() => generateKey(setIsGeneratingSecretKey)}
          revokeKey={() => revokeKey(setIsGeneratingSecretKey)}
          isActive={isSubscriptionActive}
        />
        {isDeviceSubscriptionActive && (
          <KeysContainer
            title="Client Key"
            apiKeyId={state.keyIds?.clientApiKeyId}
            apiKeyValue={clientKeyValue}
            isGenerating={isGeneratingClientKey}
            generateKey={() => generateKey(setIsGeneratingClientKey, { clientKey: true })}
            revokeKey={() => revokeKey(setIsGeneratingClientKey, { clientKey: true })}
            isActive={isSubscriptionActive}
          />
        )}
      </VStack>
    </Box>
  );
});

function getTypeFromParams(params?: { clientKey: boolean }) {
  return params?.clientKey ? "clientApiKeyId" : "apiKeyId";
}

type KeysProps = {
  title: string;
  apiKeyId: string | undefined;
  apiKeyValue: string | undefined;
  isGenerating: boolean;
  generateKey: () => Promise<void>;
  revokeKey: () => Promise<void>;
  isActive: boolean;
};

const KeysContainer = ({
  title,
  apiKeyId,
  apiKeyValue,
  isGenerating,
  generateKey,
  revokeKey,
  isActive,
}: KeysProps) => {
  const hasKey = apiKeyId && apiKeyId.length;

  if (hasKey) {
    return (
      <>
        {apiKeyValue && (
          <Alert status="warning">
            <AlertIcon />
            {msgApiKeyGenerated}
          </Alert>
        )}
        <Flex direction={"column"}>
          <Key value={apiKeyValue} revokeKey={revokeKey} children={title} />
        </Flex>
      </>
    );
  }

  if (isActive) {
    return (
      <Button
        isLoading={isGenerating}
        loadingText={`Generating ${title}`}
        size="lg"
        maxWidth={400}
        onClick={generateKey}
      >
        Generate {title}
      </Button>
    );
  }

  return <Text>Active subscription is required to generate your {title}.</Text>;
};

export default ApiKey;
