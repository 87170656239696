import { Box, Heading, Text, Divider, VStack, Flex } from "@chakra-ui/react";
import { Practitioner, Location, Condition, DiagnosticReport } from "@medplum/fhirtypes";
import { useState } from "react";
import dayjs from "dayjs";
import { Button } from "../../../../shared/Button";
import { getCleanNoteFromReport } from ".";
import { Report } from ".";
import { useMetriportApi } from "../../../../shared/useMetriportApi";
import { downloadSourceDocument } from "../shared/shared";
import useMetriportToast from "../../../../shared/toast";

const EXPLAIN_TOAST_DURATION = dayjs.duration(5, "second").asMilliseconds();

export function ReportContent({ encounterNote }: { encounterNote: Report }) {
  const metriportApi = useMetriportApi();
  const toast = useMetriportToast();
  const encounter = encounterNote.encounter;

  const valueString =
    encounterNote.encounter.extension?.find(ext => ext.valueString)?.valueString ?? "";

  return (
    <Box w={"100%"}>
      <VStack align="start">
        <Flex justifyContent={"space-between"} w={"100%"}>
          <Heading mb={3} size={"lg"}>
            {dayjs(encounter.period?.start).format("MMM DD YYYY")} - {encounter.type?.[0]?.text}
          </Heading>
          <Button
            onClick={() => {
              downloadSourceDocument(valueString, metriportApi);
              toast.info({
                title: "Hold tight... This might take up to 30 seconds.",
                duration: EXPLAIN_TOAST_DURATION,
              });
            }}
          >
            View Source
          </Button>
        </Flex>
        <Box w={"100%"}>
          <Participants participants={encounterNote.participants} />
          <Locations locations={encounterNote.locations} />
          <Diagnosis diagnoses={encounterNote.diagnoses} />
          <Divider my={4} color={"gray.500"} />
          <ReportNotes reports={encounterNote.diagnosticReports} />
        </Box>
      </VStack>
    </Box>
  );
}

function Participants({ participants }: { participants: Practitioner[] }) {
  return (
    <>
      <Box display={"flex"}>
        <Text w={100} m={0} mr={2} fontSize={18} fontWeight="bold">
          Provider:
        </Text>
        <Box>
          {participants.map((participant, i) => (
            <Text m={0} fontSize={18}>
              {participant.name?.[0]?.given?.[0]} {participant.name?.[0]?.family}
              {i < participants.length - 1 ? ", " : ""}
            </Text>
          ))}
        </Box>
      </Box>
    </>
  );
}

function Locations({ locations }: { locations: Location[] }) {
  return (
    <>
      <Box display={"flex"}>
        <Text w={100} m={0} mr={2} fontSize={18} fontWeight="bold">
          Location:
        </Text>
        <Box>
          {locations?.map((location, i) => (
            <Text m={0} fontSize={18}>
              {location.name}
              {i < locations.length - 1 ? ", " : ""}
            </Text>
          ))}
        </Box>
      </Box>
    </>
  );
}

function Diagnosis({ diagnoses }: { diagnoses: Condition[] }) {
  return (
    <>
      <Box display={"flex"}>
        <Text w={100} m={0} mr={2} fontSize={18} fontWeight="bold">
          Diagnosis:
        </Text>
        <Box>
          {diagnoses?.map((diagnosis, i) => (
            <Text m={0} fontSize={18}>
              {diagnosis.code?.text}
              {i < diagnoses.length - 1 ? ", " : ""}
            </Text>
          ))}
        </Box>
      </Box>
    </>
  );
}

function ReportNotes({ reports }: { reports: DiagnosticReport[] }) {
  return (
    <Box>
      <Box>
        {reports?.map((report, i) => (
          <ReportNote report={report} showDivider={i < reports.length - 1} />
        ))}
      </Box>
    </Box>
  );
}

function ReportNote({ report, showDivider }: { report: DiagnosticReport; showDivider?: boolean }) {
  const [showMore, setShowMore] = useState(false);

  const cleanNote = getCleanNoteFromReport(report);

  if (!cleanNote) {
    return null;
  }

  const smallNote = cleanNote.length < 200;

  return (
    <>
      <Box display={"flex"} flexDirection="column">
        <Box mb={5} whiteSpace={"pre-line"}>
          {showMore || smallNote ? cleanNote : `${cleanNote.slice(0, 200)}...`}
        </Box>
        {!smallNote && (
          <Button display={"flex"} alignSelf={"center"} onClick={() => setShowMore(!showMore)}>
            {showMore ? "Show Less" : "Show More"}
          </Button>
        )}
      </Box>
      {showDivider && <Divider mt={4} color={"gray.500"} />}
    </>
  );
}
