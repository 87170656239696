import { z } from "zod";
import { apiOSS } from "./apiOSS";

const PATIENT_URL = `/medical/v1/patient`;

const consolidatedWebhookSchema = z.object({
  requestId: z.string(),
  fileUrl: z.string().optional(),
  status: z.string().optional(),
  conversionType: z.string().optional(),
  message: z.string().optional(),
});

type ConsolidatedWebhook = z.infer<typeof consolidatedWebhookSchema>;

export async function getConsolidatedWebhook(
  patientId: string,
  requestId: string
): Promise<ConsolidatedWebhook> {
  const resp = await apiOSS.get(`${PATIENT_URL}/${patientId}/consolidated/webhook`, {
    params: { requestId },
  });

  const webhook = consolidatedWebhookSchema.parse(resp.data);
  return webhook;
}
