import { Coding } from "@medplum/fhirtypes";
import { ISO_DATE } from "@metriport/shared/common/date";
import { MetriportMedicalApi } from "@metriport/api-sdk";
import dayjs from "dayjs";

export const RX_NORM_CODE = "rxnorm";
export const NDC_CODE = "ndc";
export const SNOMED_CODE = "snomed";
export const ICD_10_CODE = "icd-10";
export const LOINC_CODE = "loinc";
export const MEDICARE_CODE = "medicare";
export const CPT_CODE = "cpt";
export const UNK_CODE = "UNK";
export const UNKNOWN_DISPLAY = "unknown";

export function mapCodeToSystem(coding: Coding[]): { [system: string]: string } {
  const codeMap: { [system: string]: string } = {};

  for (const code of coding) {
    codeMap[code.system ?? UNK_CODE] = code.code ?? UNKNOWN_DISPLAY;
  }

  return codeMap;
}

type CodeMap = {
  system: string;
  code: string;
  display: string;
};

export function getFirstCodeSpecified(
  coding: Coding[] | undefined,
  systemsList: string[]
): CodeMap | undefined {
  let specifiedCode: CodeMap | undefined = undefined;

  if (systemsList.length && coding) {
    for (const system of systemsList) {
      const obj = coding.find(coding => {
        return coding.system?.toLowerCase().includes(system);
      });

      if (obj) {
        specifiedCode = {
          system: system ? system.toUpperCase() : UNK_CODE,
          code: obj.code ?? UNKNOWN_DISPLAY,
          display: obj.display ?? UNKNOWN_DISPLAY,
        };
        break;
      }
    }
  }

  return specifiedCode;
}

export function getValidCode(coding: Coding[] | undefined): Coding[] {
  if (!coding) return [];

  return coding.filter(coding => {
    return (
      coding.code &&
      coding.code !== UNK_CODE &&
      coding.display &&
      coding.display.toLowerCase() !== UNKNOWN_DISPLAY
    );
  });
}

export async function downloadSourceDocument(
  docFilename: string,
  metriportApi: MetriportMedicalApi
): Promise<void> {
  const resp = await metriportApi.getDocumentUrl(docFilename, "html");

  const a = document.createElement("a");
  a.href = resp.url;
  a.download = docFilename;
  a.target = "_blank";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export function filterByDate(
  date: string,
  dateFilter?: {
    from?: string;
    to?: string;
  }
) {
  if (dateFilter) {
    const isoDate = dayjs(date, ISO_DATE);
    if (dateFilter.from && isoDate.isBefore(dayjs(dateFilter.from))) {
      return false;
    }
    if (dateFilter.to && isoDate.isAfter(dayjs(dateFilter.to))) {
      return false;
    }
  }

  return true;
}

export function compare(
  a: { [key: string]: string },
  b: { [key: string]: string },
  stringFilter?: string
): number {
  const keys = Object.keys(a);

  for (const key of keys) {
    if (stringFilter) {
      const filtersArray = stringFilter.split(",");
      const aHasFilter = filtersArray.some(filter => {
        return a[key]?.toLowerCase().includes(filter.toLowerCase());
      });
      const bHasFilter = filtersArray.some(filter => {
        return b[key]?.toLowerCase().includes(filter.toLowerCase());
      });

      if (aHasFilter && !bHasFilter) {
        return -1;
      } else if (!aHasFilter && bHasFilter) {
        return 1;
      }
    }
  }

  return 0;
}
