import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { useState } from "react";
import { PatientFacilityMatch, PatientFacilityMatches } from "../../../../api/patient-matches";
import { Button } from "../../../shared/Button";

export default function PatientMatchesTable({
  patientMatches,
}: {
  patientMatches: PatientFacilityMatches | null;
}) {
  const headerBackgroundColor = useColorModeValue("gray.300", "#2d3748");
  const headerTextColor = useColorModeValue("black", "white");
  return (
    <TableContainer borderRadius={"lg"} border={"1px"} borderColor="gray.600">
      <Table variant="simple">
        <Thead bg={headerBackgroundColor}>
          <Tr>
            <Th color={headerTextColor}>Organization</Th>
            <Th color={headerTextColor}>Patient Name</Th>
            <Th color={headerTextColor}>DOB</Th>
            <Th color={headerTextColor}>Gender</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {patientMatches?.map((link: PatientFacilityMatch, i: number) => {
            return <ExpandableTableRow key={i} link={link} />;
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

function ExpandableTableRow({ link }: { link: PatientFacilityMatch }) {
  const [showMore, setShowMore] = useState(false);
  return (
    <>
      <Tr>
        <Td>{link.name}</Td>
        <Td>
          {link.patient?.names.map((name, i) => {
            const parsedName = JSON.parse(name);
            return `${parsedName.firstName} ${parsedName.lastName}${
              i < (link.patient?.names.length || 0) - 1 ? ", " : ""
            }`;
          })}
        </Td>
        <Td>{link.patient?.dob}</Td>
        <Td>{link.patient?.gender}</Td>
        <Td>
          <Button onClick={() => setShowMore(!showMore)}>
            {showMore ? "Show Less" : "Show More"}
          </Button>
        </Td>
      </Tr>
      {showMore ? (
        <Tr>
          <Td colSpan={5}>
            <pre>{JSON.stringify(link, null, 2)}</pre>
          </Td>
        </Tr>
      ) : null}
    </>
  );
}
