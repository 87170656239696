import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  VStack,
} from "@chakra-ui/react";
import { useRef } from "react";
import Dialog from "../dialog";
import { Button } from "../shared/Button";

type StripeRedirectAlertProps = {
  isOpen: boolean;
  onCancel: () => void;
  errorMessage: string | null;
  onCloseError: () => void;
};

const StripeRedirectAlert = ({
  isOpen,
  onCancel,
  errorMessage,
  onCloseError,
}: StripeRedirectAlertProps) => {
  const unsubscribeRef = useRef(null);

  return (
    <VStack align="stretch">
      <AlertDialog isOpen={isOpen} leastDestructiveRef={unsubscribeRef} onClose={onCancel}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Heads up!
            </AlertDialogHeader>
            <AlertDialogBody>
              You'll be automatically redirected to Stripe to manage your subscription...
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button colorScheme="red" onClick={onCancel} ml={3}>
                Cancel
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      {errorMessage && <Dialog show message={errorMessage} onClose={onCloseError} />}
    </VStack>
  );
};

export default StripeRedirectAlert;
