import { Box, Button, ChakraProps, Heading, Text, VStack } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const SubcriptionSuccess = (props: ChakraProps) => {
  const navigate = useNavigate();

  const [timer, setTimer] = useState<ReturnType<typeof setTimeout> | null>(null);

  const goToSettings = useCallback(() => {
    timer && clearTimeout(timer);
    navigate("/billing");
  }, [timer, navigate]);

  useEffect(() => {
    setTimer(
      setTimeout(() => {
        goToSettings();
      }, 3000)
    );
  }, [setTimer, goToSettings]);

  return (
    <Box {...props}>
      <VStack align="stretch">
        <Heading>Subscription Successful!</Heading>
        <Text>Redirecting to settings in 3 seconds...</Text>
        <Button onClick={goToSettings}>Go Now</Button>
      </VStack>
    </Box>
  );
};

export default SubcriptionSuccess;
