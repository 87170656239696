import { Box, Heading, Flex } from "@chakra-ui/react";
import { Bundle, Resource, RelatedPerson } from "@medplum/fhirtypes";
import dayjs from "dayjs";
import { RelatedPersonsGrid } from "./grid";
import { useMetriportApi } from "../../../../shared/useMetriportApi";
import { Button } from "../../../../shared/Button";
import { downloadSourceDocument } from "../shared/shared";
import useMetriportToast from "../../../../shared/toast";
import { MrFilterSetting } from "../../../../../api/settings";
import { FhirSection } from "../../../shared-components/fhir-section";
import { JsonTable } from "../../../shared-components/json-table";

const EXPLAIN_TOAST_DURATION = dayjs.duration(5, "second").asMilliseconds();

export function RelatedPersons({
  bundle,
  onShowContent,
  filters,
}: {
  bundle: Bundle<Resource> | undefined;
  onShowContent: (content: React.ReactNode) => void;
  filters: MrFilterSetting;
}) {
  const relatedPersons = getRelatedPersons(bundle);

  return (
    <FhirSection
      sectionId="related-persons"
      sectionName="Related Persons"
      hasContent={relatedPersons.length === 0}
    >
      <RelatedPersonsGrid
        filters={filters}
        relatedPersons={relatedPersons}
        onShowContent={(id: string) => {
          const relatedPerson = relatedPersons.find(c => c.id === id);
          onShowContent(<RelatedPersonsContent relatedPerson={relatedPerson} />);
        }}
      />
    </FhirSection>
  );
}

function RelatedPersonsContent({ relatedPerson }: { relatedPerson: RelatedPerson | undefined }) {
  const metriportApi = useMetriportApi();
  const toast = useMetriportToast();

  if (!relatedPerson) {
    return null;
  }

  const valueString = relatedPerson.extension?.find(ext => ext.valueString)?.valueString ?? "";

  return (
    <Box>
      <Flex justifyContent={"space-between"}>
        <Heading mb="3">Related Person</Heading>
        <Button
          onClick={() => {
            downloadSourceDocument(valueString, metriportApi);
            toast.info({
              title: "Hold tight... This might take up to 30 seconds.",
              duration: EXPLAIN_TOAST_DURATION,
            });
          }}
        >
          View Source
        </Button>
      </Flex>
      <JsonTable resource={relatedPerson} />
    </Box>
  );
}

function getRelatedPersons(bundle: Bundle<Resource> | undefined): RelatedPerson[] {
  if (!bundle || !bundle.entry) {
    return [];
  }

  const procedures: RelatedPerson[] = [];

  for (const entry of bundle.entry) {
    if (entry.resource?.resourceType === "RelatedPerson") {
      procedures.push(entry.resource);
    }
  }

  return procedures;
}
