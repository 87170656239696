import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import Constants from "../shared/constants";

const defaultActionText = "Ok";
const defaultCloseText = "Cancel";

export interface DialogProps {
  show: boolean;
  title?: string;
  message?: string;
  actionText?: string;
  onAction?: () => void;
  actionIsLoading?: boolean;
  closeText?: string;
  onClose?: () => void;
}

const Dialog = ({
  show,
  title,
  message,
  actionText = defaultActionText,
  onAction,
  actionIsLoading = false,
  closeText: closeTextParam,
  onClose: onCloseParam,
}: DialogProps) => {
  const { isOpen, onClose: onCloseDisclosure } = useDisclosure({ isOpen: show });
  const closeButtonRef = useRef(null);
  const [closeText, setCloseText] = useState(closeTextParam);
  const [{ actualTitle, actualMsg }, setActual] = useState({
    actualTitle: title,
    actualMsg: message,
  });

  useEffect(() => {
    setActual({
      actualTitle: title ?? "Ooops...",
      actualMsg: message ?? Constants.errorDefaultMessage,
    });
  }, [title, message]);

  useEffect(() => {
    setCloseText(closeTextParam ?? !!onAction ? defaultCloseText : defaultActionText);
  }, [onAction, onCloseParam]);

  const action = () => {
    onAction && onAction();
  };

  const close = () => {
    onCloseDisclosure();
    onCloseParam && onCloseParam();
  };

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={closeButtonRef} onClose={close}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {actualTitle}
          </AlertDialogHeader>
          <AlertDialogBody>{actualMsg}</AlertDialogBody>
          <AlertDialogFooter>
            {!!onAction && (
              <Button isLoading={actionIsLoading} ref={closeButtonRef} onClick={action} ml={3}>
                {actionText}
              </Button>
            )}
            <Button ref={closeButtonRef} onClick={close} ml={3}>
              {closeText}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default Dialog;
