import axios from "axios";

export function getBaseURL(): string {
  return process.env.REACT_APP_API_URL!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
}
export function getCognitoBaseURL(): string {
  return process.env.REACT_APP_COGNITO_API_URL!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
}

export const api = axios.create({ baseURL: getBaseURL() });
export const apiCognito = axios.create({ baseURL: getCognitoBaseURL() });

const GET_STRIPE_REDIRECT_URL = `/dash/subscription/setup`;
export async function getStripeRedirectUrl(props?: {
  subscribeDevices?: boolean;
}): Promise<string> {
  const resp = await api.post(GET_STRIPE_REDIRECT_URL, {
    subscribeDevices: props?.subscribeDevices,
  });

  if (!resp.data) throw new Error(`No redirect url was returned`);
  return resp.data.subscriptionUrl;
}

// To be used only in development
export async function developmentInitAccount(props: {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  website: string;
}): Promise<void> {
  const resp = await api.post("/internal/customer", props);
  if (resp.status > 299) console.log(`Failed to initialize account for development`);
}

export default api;
