import { Box, VStack } from "@chakra-ui/react";
import ApiKey from "./apiKey";
import SandboxToggle from "./sandbox";
import Webhook from "./webhook";

export default function Settings() {
  return (
    <Box>
      <VStack p={10} align="stretch">
        <ApiKey />
        <Webhook />
        <SandboxToggle />
      </VStack>
    </Box>
  );
}
