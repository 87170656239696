import { Table, Thead, Tbody, Tfoot, Tr, Th, Td, Text, TableContainer } from "@chakra-ui/react";
import { lowerCase } from "lodash";

import { formatDate } from "../shared/date";
import { Usage, Quantities } from "../../api/usage";
import { formatCurrency } from "../shared/currency";

enum ApiTypes {
  medical,
}

const apiTypes = {
  [ApiTypes.medical]: "medical",
};

export default function UpcomingInvoice({ usage }: { usage: Usage }) {
  // TODO: 1411 - Remove when DAPI is fully discontinued
  const quantities = separateAPIs(usage.quantities);

  if (quantities) {
    return (
      <TableContainer
        px={5}
        pt={2}
        border={"solid #d3d3d3 1px"}
        mb={4}
        h={"100%"}
        borderRadius={20}
        w={"100%"}
      >
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>DESCRIPTION</Th>
              <Th isNumeric>QTY</Th>
              <Th isNumeric>UNIT PRICE</Th>
              <Th isNumeric>AMOUNT</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>
                {formatDate(unixToMillis(usage.periodStart), "MMM DD")} to{" "}
                {formatDate(unixToMillis(usage.periodEnd), "MMM DD, YYYY")}
              </Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
            </Tr>
            <APIRows title="Metriport Medical API" quantities={quantities[ApiTypes.medical]} />
          </Tbody>
          <Tfoot>
            <Tr>
              <Td></Td>
              <Td></Td>
              <Td isNumeric>Subtotal</Td>
              <Td isNumeric>{formatCurrency(usage.subtotal)}</Td>
            </Tr>
            {usage.discountAmount && (
              <Tr>
                <Td></Td>
                <Td></Td>
                <Td isNumeric>Discount</Td>
                <Td isNumeric>- {formatCurrency(usage.discountAmount)}</Td>
              </Tr>
            )}
            <Tr>
              <Td></Td>
              <Td></Td>
              <Td isNumeric>Total</Td>
              <Td isNumeric>{formatCurrency(usage.total)}</Td>
            </Tr>
            <Tr>
              <Td></Td>
              <Td></Td>
              <Td isNumeric>Amount due</Td>
              <Td isNumeric>{formatCurrency(usage.amountDue)}</Td>
            </Tr>
          </Tfoot>
        </Table>
      </TableContainer>
    );
  }

  return null;
}

const APIRows = ({
  title,
  quantities,
}: {
  title: string;
  quantities: Array<Quantities> | null;
}) => {
  if (quantities && quantities.length) {
    return (
      <>
        <Tr>
          <Td>
            <Text as={"b"}>{title}</Text>
          </Td>
          <Td></Td>
          <Td></Td>
          <Td></Td>
        </Tr>
        {quantities.map(item => {
          return (
            <Tr key={item.description}>
              <Td>{item.description}</Td>
              <Td isNumeric>{item.quantity}</Td>
              <Td isNumeric>{item.unitAmount != null ? formatCurrency(item.unitAmount) : ""}</Td>
              <Td isNumeric>{item.amount && formatCurrency(item.amount)}</Td>
            </Tr>
          );
        })}
      </>
    );
  }

  return null;
};

type SeparateApiQuantities = {
  [ApiTypes.medical]: Array<Quantities>;
};

// TODO: 1411 - Remove when DAPI is fully discontinued
const separateAPIs = (quantities: Array<Quantities> | null): SeparateApiQuantities | null => {
  if (quantities?.length) {
    return quantities.reduce(
      (acc, curr) => {
        const lowerCaseDesc = curr.description ? lowerCase(curr.description) : "";
        const isMedical = lowerCaseDesc.includes(apiTypes[ApiTypes.medical]);

        if (isMedical) {
          acc[ApiTypes.medical] = [...acc[ApiTypes.medical], curr];
        }

        return acc;
      },
      {
        [ApiTypes.medical]: [],
      } as SeparateApiQuantities
    );
  }

  return null;
};

const unixToMillis = (unixDate: number): number => {
  return unixDate * 1000;
};
